import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  TextField,
  Avatar,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { addComment } from "../../redux/actions/comments";
import { api } from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    "& .MuiInput-underline:before": {
      borderBottom: "1px solid dimgrey",
    },
  },
  commentBtn: {
    backgroundColor: `#FBB900`,
    fontWeight: 600,
    fontFamily: "Basier Square",
    "&:disabled": {
      backgroundColor: "#2a2a2a", // replace with the color you want
      color: "grey", // replace with the color you want
    },
    "&:hover": {
      backgroundColor: "#b08200",
    },
  },
  textArea: {
    flex: 1,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  modalHeader: {
    padding: "1rem 1rem 0.5rem 2rem",
  },
  modalTitle: {
    fontSize: "1.5rem",
    color: "#fff",
    fontWeight: "600",
    fontFamily: "Basier Square",
    display: "flex",
    justifyContent: "center",
    padding: "0.6rem 0.6rem 0rem 0.6rem",
  },
  modalBody: {
    padding: "2rem",
  },
  modalParagraph: {
    transition: "all 0.2s ease-in-out 0s",
    fontWeight: 400,
    lineHeight: "140%",
    color: "rgb(142, 142, 147)",
    fontSize: "0.875rem",
    padding: "1.8rem 0.2rem",
    textAlign: "center",
  },
  modalClose: {
    color: "#fff !important",
    background: "#404040",
    padding: "8px",
    borderRadius: "100%",
    width: "30px",
    height: "30px",
    padding: "8px 10px 4px 11px",
    display: "flex",
    cursor: "pointer",
    float: "right",
    "&:hover": {
      backgroundColor: "#303030 !important",
    },
  },
}));

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "#121212",
  border: "1px solid #262626",
  boxShadow: 24,
  minHeight: "200px",
  p: 4,
  borderRadius: "15px",
  padding: "10px",
};

const CommentForm = ({ videoId, replyTo, handleReplyComment, onSuccess }) => {
  const [comment, setComment] = useState("");
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const isAuth = useSelector(({ channel }) => channel.isAuth);
  const userId = useSelector(({ channel }) => channel.id);
  const profileImage = useSelector(({ channel }) => channel.image);
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleClick = (e) => {
    if (!comment || !isAuth) {
      handleOpen();
    } else {
      handleSubmit(e);
    }
  };

  const handleChange = (e) => setComment(e.target.value);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isAuth && comment) {
      const data = {
        videoId,
        content: comment,
        replyTo,
      };
      setComment("");
      try {
        const {
          data: { comment: newComment },
        } = await api.post("/api/comments", data);
        handleReplyComment && handleReplyComment();
        onSuccess && onSuccess();
        dispatch(addComment(newComment));
      } catch (err) {
        console.log(err);
      }
    } else {
      setErrorDialogOpen(true);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onBackdropClick={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div className={classes.modalHeader}>
            <a className={classes.modalClose} onClick={handleClose}>
              <FontAwesomeIcon icon={faXmark} size="sm" />
            </a>

            <div className={classes.modalTitle}>Comment Error</div>
          </div>
          <div>
            <div className={classes.modalParagraph}>
              {isAuth
                ? "Please enter text in the comment field before submitting"
                : "You must be signed in to leave a comment"}
            </div>
          </div>
        </Box>
      </Modal>
      <form className={classes.root}>
        <Avatar alt="Avatar" src={profileImage} />
        <TextField
          onChange={handleChange}
          value={comment}
          rowsMin={2}
          className={classes.textArea}
          placeholder="Add a comment..."
        />
        <div onClick={handleClick}>
          <Button
            disableElevation
            disableFocusRipple
            disableRipple
            variant="contained"
            color="secondary"
            className={classes.commentBtn}
            type="submit"
            disabled={!comment}
          >
            Comment
          </Button>
        </div>
      </form>
      <Dialog open={errorDialogOpen} onClose={() => setErrorDialogOpen(false)}>
        <DialogTitle>
          {isAuth
            ? "Please enter a comment before submitting."
            : "Please sign in to comment."}
        </DialogTitle>
      </Dialog>
    </>
  );
};

export default CommentForm;
