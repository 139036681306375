import React from "react";
import { makeStyles, Container, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));

export default function HelpPage() {
    const classes = useStyles();
    return (
        <Container className={classes.root}>
            <Typography variant="h4" align="center">
                Help Page
            </Typography>
        </Container>
    );
};
