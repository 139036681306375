//const PORT = process.env.REACT_APP_BACKEND_PORT;
//const BACKEND = process.env.REACT_APP_BACKEND_URL;
const AWS_URL = "https://videomaster-dev.s3.ap-southeast-2.amazonaws.com/";
const BACKEND_URL =
  process.env.NODE_ENV === "production"
    ? `${process.env.REACT_APP_BACKEND_URL_LIVE}`
    : `${process.env.REACT_APP_BACKEND_URL_DEV}`;
//const BACKEND_URL = BACKEND;

module.exports = { BACKEND_URL, AWS_URL };
