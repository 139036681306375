import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import queryString from "query-string";
import ClipContent from "../Video/ClipContent";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    overflowY: "auto",
    height: "calc(100vh - 64px)",
    scrollSnapType: "y mandatory",
  },
  clipContentOuter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    //padding: "10px",
    scrollSnapAlign: "start",
    height: "85vh",
  },
}));

export default function ClipsPage({ location }) {
  const classes = useStyles();
  const { v: id } = queryString.parse(location.search);
  const theme = useTheme();
  const isMaxScreenSm = useMediaQuery(theme.breakpoints.down("sm"));

  const clipCount = 3; // set this to the actual number of clips
  const [clipRefs, setClipRefs] = useState([]);
  const [focusedIndex, setFocusedIndex] = useState(null);
  const containerRef = useRef(null); // ref for container

  useEffect(() => {
    // Ensure the containerRef is available and then scroll
    if (containerRef.current) {
      setTimeout(() => {
        containerRef.current.scrollBy(0, 1);
      }, 100); // adjust the timeout as needed
    }
  }, []);

  useEffect(() => {
    setClipRefs((refs) =>
      Array(clipCount)
        .fill()
        .map((_, i) => refs[i] || React.createRef())
    );
  }, [clipCount]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = clipRefs.findIndex(
            (ref) => ref.current === entry.target
          );
          if (entry.isIntersecting && entry.intersectionRatio >= 0.5) {
            setFocusedIndex(index);
          }
        });
      },
      { threshold: 0.5 }
    );

    clipRefs.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      clipRefs.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [clipRefs]);

  return (
    <div className={classes.container} ref={containerRef}>
      {clipRefs.map((ref, i) => (
        <div key={i} ref={ref} className={classes.clipContentOuter}>
          <ClipContent videoId={id} inFocus={i === focusedIndex} />
        </div>
      ))}
    </div>
  );
}
