import React, { useEffect } from "react";
import { makeStyles, Container, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  inputField: {
    backgroundColor: "#121212",
    color: "white",
    border: "0px",
    borderRadius: "10px",
    padding: "10px",
    width: "100%",
    zIndex: 1000,
    border: "1px solid #262626",
  },
}));

const TextField = ({ value, name, label, onChange }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  useEffect(() => {}, []);

  return (
    <>
      {label && <div className={classes.inputLabel}>{label}</div>}
      <input
        className={classes.inputField}
        name={name}
        type="text"
        value={value}
        onChange={onChange}
      />
    </>
  );
};

export default TextField;
