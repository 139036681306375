import { api } from "../../utils/api";
import { BACKEND_URL } from "../../config/config";
import cookie from "js-cookie";

const setAuth = (isAuth) => {
  return {
    type: "SET_AUTH",
    payload: { isAuth },
  };
};

const setChannelInfo = (channel) => {
  return {
    type: "SET_CHANNEL_INFO",
    payload: channel,
  };
};

////Remove cookie will not work when httponly is set on the cookie i.e. on the server
// const removeCookie = (key) => {
//   if (typeof window !== "undefined") {
//     cookie.remove(key, {
//       expires: 1,
//     });
//   }
// };

const logoutChannel = () => {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        await fetch(`${BACKEND_URL}/api/auth/logout`, {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        });
        dispatch(setAuth(false));
        dispatch(
          setChannelInfo({
            id: null,
            xrpAddress: null,
            ethAddress: null,
            username: null,
          })
        );
        resolve(); // Resolve the promise after all actions are dispatched
      } catch (err) {
        dispatch(setAuth(false));
        dispatch(
          setChannelInfo({
            id: null,
            xrpAddress: null,
            ethAddress: null,
            username: null,
          })
        );
        reject(err); // Reject the promise on error
      }
    });
  };
};

const reloadAccountInfo = () => {
  return async (dispatch) => {
    try {
      const { data } = await api.get(`${BACKEND_URL}/api/channels/owner`);
      dispatch(
        setChannelInfo({
          id: data.id,
          xrpAddress: data.xrpAddress,
          ethAddress: data.ethAddress,
          username: data.username,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(
        setChannelInfo({
          id: null,
          xrpAddress: null,
          ethAddress: null,
          username: null,
        })
      );
    }
  };
};

const auth = () => {
  return async (dispatch) => {
    try {
      const { data } = await api.get(`${BACKEND_URL}/api/channels/owner`);
      dispatch(setAuth(true));
      dispatch(
        setChannelInfo({
          id: data.id,
          xrpAddress: data.xrpAddress,
          ethAddress: data.ethAddress,
          username: data.username,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(setAuth(false));
      dispatch(
        setChannelInfo({
          id: null,
          xrpAddress: null,
          ethAddress: null,
          username: null,
        })
      );
    }
  };
};
export { auth, logoutChannel, setChannelInfo, reloadAccountInfo };
