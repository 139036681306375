import React, { useState, useEffect } from "react";
import { makeStyles, Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { api } from "../utils/api";

const useStyles = makeStyles((theme) => ({
  subscribeBtn: {
    backgroundColor: "#FBB900",
    color: "black",
    fontWeight: "600",
    borderRadius: 10,
    "&:hover": {
      backgroundColor: "#d89f00",
    },
  },
}));

export default function SubscribeBtn({
  className,
  channelId,
  size,
  onSubscriptionChange,
}) {
  const classes = useStyles();
  const userId = useSelector(({ channel }) => channel.xrpAddress);
  const [isSubscribed, setSubscribed] = useState(false);
  const handleClick = async () => {
    const data = {
      channel: channelId,
      subscriber: userId,
    };

    try {
      if (isSubscribed) {
        await api.delete(`/api/subscriptions/${data.channel}`);
        setSubscribed(false);
        onSubscriptionChange(-1);
      } else {
        await api.post("/api/subscriptions", data);
        setSubscribed(true);
        onSubscriptionChange(1);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchSubscribed = async () => {
    try {
      const {
        data: { isSubscribed },
      } = await api.post(`/api/subscriptions/subscribed`, {
        channel: channelId,
      });
      setSubscribed(isSubscribed);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (channelId) {
      fetchSubscribed();
    }
  }, [channelId]);

  return (
    <Button
      size={size}
      className={clsx(classes.subscribeBtn, className)}
      disableElevation
      disableFocusRipple
      disableRipple
      variant="contained"
      color="secondary"
      onClick={handleClick}
    >
      {!isSubscribed ? "subscribe" : "unsubscribe"}
    </Button>
  );
}
