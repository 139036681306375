import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { Drawer, Divider, useMediaQuery } from "@material-ui/core";
import MainNavMenu from "./MainNavMenu";
import { SideCategoryMenu } from "../CategoryMenus";
import StartNav from "../TopNav/StartNav";
import SubscriptionsList from "./SubscriptionsList";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setDrawer, toggleDrawer } from "../../../redux/actions/layout";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    //backgroundColor: "#1b1b1b",
    backgroundImage: "linear-gradient(to right, #000, #121212)",
    flexShrink: 0,
    whiteSpace: "nowrap",
    border: "0px",
  },
  drawerOpen: {
    width: drawerWidth,
    //backgroundColor: "#1b1b1b",
    backgroundImage: "linear-gradient(to right, #000, #121212)",
    border: "0px",
    // transition: theme.transitions.create(["width", "margin"], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
  },
  drawerClose: {
    backgroundColor: "#1b1b1b",
    transition: theme.transitions.create(["width", "margin", "visibility"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    border: "0px",
    width: "0px",
    // TODO: DRAW adjustments
    // width: theme.spacing(7) + 1,
    // [theme.breakpoints.down("xs")]: {
    //   width: 0,
    //   visibility: "hidden",
    // },
    // [theme.breakpoints.up("sm")]: {
    //   width: theme.spacing(9) + 1,
    // },
  },
  navHead: {
    ...theme.mixins.toolbar,
  },
  sideNavSpacer: {
    margin: "8px",
  },
}));

const SideNav = () => {
  const theme = useTheme();
  const isMaxScreenSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isDrawerOpen = useSelector(({ layout }) => layout.isDrawerOpen);
  const isMobileSearchClick = useSelector(
    ({ layout }) => layout.isMobileSearchClick
  );
  const location = useLocation();
  const dispatch = useDispatch();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const classes = useStyles();
  let isOpen;

  if (isMaxScreenSm) {
    isOpen = isDrawerOpen;
  } else {
    isOpen = true;
  }

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    switch (location.pathname) {
      case "/":
        if (!isDrawerOpen && screenWidth > 960) {
          dispatch(toggleDrawer());
        } else if (isDrawerOpen && screenWidth < 960) {
          dispatch(toggleDrawer());
        }
        break;
    }
  }, []);

  return (
    <Drawer
      variant={isMaxScreenSm ? "temporary" : "persistent"}
      open={isOpen}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: isDrawerOpen,
        [classes.drawerClose]: !isDrawerOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: isDrawerOpen,
          [classes.drawerClose]: !isDrawerOpen,
        }),
      }}
    >
      <div className={classes.navHead}>
        <StartNav mobile />
      </div>
      {/* <Divider /> */}
      <div className={classes.sideNavSpacer}>
        <MainNavMenu />
      </div>
      <Divider />
      {isDrawerOpen && <SideCategoryMenu />}
    </Drawer>
  );
};

export default SideNav;
