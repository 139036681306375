import axios from "axios";
import { API } from "../config/config";

export const fetchChannelBalance = async () => {
  try {
    const response = await axios.get(
      `${API}/channels/balance`,

      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const checkCreatorUploadPermission = async () => {
  try {
    const response = await axios.get(`${API}/channels/upload-permission`, {
      withCredentials: true, // This will ensure credentials are included with the request
    });
    return response.data.permission;
  } catch (error) {
    console.error("Error checking creator payout permission:", error);
    throw error; // Rethrow to handle it in the calling context
  }
};

export const requestCreatorUploadPermission = async () => {
  try {
    const response = await axios.post(
      `${API}/channels/request-upload-permission`,
      {},
      {
        withCredentials: true, // Assuming you need to send cookies for auth
      }
    );
    return response.data; // You might adjust this based on your actual API response
  } catch (error) {
    throw error;
  }
};

export const getProfileImage = async () => {
  try {
    const response = await axios.get(`${API}/channels/profile-image`, {
      withCredentials: true, // Assuming you need to send cookies for auth
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateChannelUsername = async (username) => {
  try {
    const response = await axios.post(
      `${API}/channels/update-username`,
      { username },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateChannelName = async (name) => {
  try {
    const response = await axios.post(
      `${API}/channels/update-name`,
      { name },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
