import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { makeStyles, Typography, Button } from "@material-ui/core";
import NavUserMenuBtn from "../../components/Nav/TopNav/NavUserMenuBtn";
import CloseBtn from "../Buttons/CloseBtn";
import SignInBtn from "../SignInBtn";
import { useHistory } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import RadioBtn from "../Buttons/RadioBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faCartShopping,
} from "@fortawesome/pro-duotone-svg-icons";
import { faHexagonVerticalNft } from "@fortawesome/pro-duotone-svg-icons";
import { faAddressCard } from "@fortawesome/pro-duotone-svg-icons";
import { fetchChannelBalance } from "../../action/channelAction";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  button: {
    alignSelf: "center",
    fontSize: "12px",
    marginLeft: "5px",
    marginRight: "5px",
    backgroundColor: "rgb(0, 119, 247, 0.2)",
    color: "rgb(0, 119, 247)",
    "&:hover": {
      color: "white",
      backgroundColor: "rgb(0, 119, 247) !important",
    },
  },
  continueBtn: {
    backgroundColor: `white`,
    fontWeight: 600,
    color: "black",
    fontFamily: "Basier Square",
    margin: "20px",
    float: "right",
    "&:disabled": {
      backgroundColor: "#2a2a2a", // replace with the color you want
      color: "grey", // replace with the color you want
    },
    "&:hover": {
      color: "black",
      backgroundColor: "#D8D8D8",
    },
  },

  exploreBtn: {
    backgroundColor: `#121212`,
    fontWeight: 600,
    color: "white",
    fontFamily: "Basier Square",
    margin: "20px",
    float: "left",
    "&:disabled": {
      backgroundColor: "#2a2a2a", // replace with the color you want
      color: "grey", // replace with the color you want
    },
    "&:hover": {
      color: "white",
      backgroundColor: "#3d3c3c",
    },
  },

  paymentMethodMenu: {
    "&.hide": {
      transition: "opacity 0.4s ease-in-out",
      display: "none",
    },
  },
  paymentMethodMenuGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "10px",
  },
  paymentAmountMenuGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "10px",
  },
  paymentAmount: {
    "&.hide": {
      transition: "opacity 0.4s ease-in-out",
      display: "none",
    },
  },
  paymentAmountItem: {
    backgroundColor: "#fff3",
    padding: "10px",
    textAlign: "center",
    transition: "opacity 0.4s ease-in-out",
    borderRadius: "10px",
    fontWeight: "700",
    "&:hover": {
      backgroundColor: "#303030",
    },
  },
}));

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "#121212",
  border: "1px solid #262626",
  boxShadow: 24,
  minHeight: "250px",
  p: 4,
  borderRadius: "15px",
  padding: "10px",
  "&:focus-visible": {
    outline: "none",
  },
};

const VideoPaymentModal = ({
  isOpen,
  onClose,
  onPaymentSelection,
  onCancel,
  videoId,
  videoWeb3Settings,
  videoPrice,
}) => {
  const classes = useStyles();
  const isAuth = useSelector(({ channel }) => channel.isAuth);
  const history = useHistory();

  const channelId = useSelector(({ channel }) => channel.id);
  const [optionsSpanHover, setOptionsSpanHover] = useState(false);

  const [usdChannelBalance, setUsdChannelBalance] = useState(0.0);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const handleRadioChange = (event) => {
    setSelectedPaymentMethod(event.target.value);
  };

  const handleContinue = () => {
    const message = "Payment method selected!";
    onPaymentSelection(message, selectedPaymentMethod); // Pass the additional information to onPaymentSelection
  };
  const handleDiscoverMore = () => {
    // Navigate to the home route "/"
    history.push("/");
  };

  const handleFetchChannelBalance = async () => {
    try {
      let usdBalance = await fetchChannelBalance();
      console.log("usdBalance: ", usdBalance);
      if (usdBalance.usdBalance) {
        setUsdChannelBalance(usdBalance.usdBalance.toFixed(2));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleButtonClick = (url) => {
    window.open(url, "_blank"); // '_blank' opens the URL in a new tab or window
  };

  useEffect(() => {
    handleFetchChannelBalance();
  }, [usdChannelBalance]);

  return (
    <Modal
      open={isOpen}
      onBackdropClick={onCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <div
          className={classes.optionsSpan}
          onMouseEnter={() => setOptionsSpanHover(true)}
          onMouseLeave={() => setOptionsSpanHover(false)}
        >
          <CloseBtn onClose={onCancel} />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography
            variant="h5"
            style={{
              fontWeight: "800",
              padding: "10px",
            }}
          >
            Unlock Premium Content
          </Typography>
        </div>
        <div>
          {isAuth ? (
            <div>
              <div
                style={{
                  padding: "20px",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "1rem",
                  fontWeight: "600",
                  opacity: 0.9,
                }}
              >
                {videoPrice.price && (
                  <span>
                    {`$${videoPrice.price.toFixed(2)} ${
                      videoPrice.currency
                    } - ${videoPrice.paymentType}`}
                  </span>
                )}

                <Tooltip
                  title={
                    <React.Fragment>
                      <br />
                      PPV = Pay Per View. <br /> StreamPay = Pay as you watch,
                      only pay for what you watch.
                      <hr style={{ backgroundColor: "black" }} />
                      You never pay twice for watching the same content.
                      <br />
                    </React.Fragment>
                  }
                  placement="bottom"
                >
                  <FontAwesomeIcon
                    style={{ paddingTop: "5px", paddingLeft: "5px" }}
                    icon={faCircleInfo}
                    size="lg"
                  />
                </Tooltip>
              </div>
              <hr style={{ backgroundColor: "grey" }} />
              <div style={{ padding: "10px", fontWeight: 600 }}>
                Select payment type:
              </div>
              <div>
                {/* Radio buttons for payment */}
                <RadioBtn
                  heading={"Account"}
                  description={"Use funds from account top-ups."}
                  checked={selectedPaymentMethod === "Account"}
                  onChange={handleRadioChange}
                  value="Account"
                  icon={faAddressCard}
                  balance={`$${usdChannelBalance}`}
                />
                <RadioBtn
                  heading={"NFT Pass"}
                  description={
                    "Check if your account has an NFT that can be used as a pass."
                  }
                  checked={selectedPaymentMethod === "NFT"}
                  onChange={handleRadioChange}
                  value="NFT"
                  icon={faHexagonVerticalNft}
                />
              </div>
              <br />
              {videoWeb3Settings && videoWeb3Settings.length > 0 && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {/* Loop through each videoWeb3Settings item and render a button */}
                  {videoWeb3Settings.map((setting, index) => {
                    if (setting.nftMarketplaceLink) {
                      // Check if nftMarketplaceLink is truthy (not null/undefined/"")
                      return (
                        <Button
                          disableElevation
                          disableFocusRipple
                          disableRipple
                          variant="contained"
                          color="secondary"
                          className={classes.exploreBtn}
                          onClick={() =>
                            handleButtonClick(setting.nftMarketplaceLink)
                          } // Use the handleButtonClick function here
                        >
                          <FontAwesomeIcon
                            style={{ padding: "5px" }}
                            icon={faCartShopping}
                            size="lg"
                          />
                          {`Open ${setting.chain} NFT Marketplace`}
                        </Button>
                      );
                    }
                  })}
                </div>
              )}
              <br />
              <Button
                disableElevation
                disableFocusRipple
                disableRipple
                variant="contained"
                color="secondary"
                className={classes.exploreBtn}
                type="submit"
                disabled={false}
                onClick={handleDiscoverMore}
              >
                Discover more!
              </Button>
              <Button
                disableElevation
                disableFocusRipple
                disableRipple
                variant="contained"
                color="secondary"
                className={classes.continueBtn}
                type="submit"
                disabled={selectedPaymentMethod === ""}
                onClick={handleContinue}
              >
                Continue
              </Button>
            </div>
          ) : (
            <div
              style={{
                padding: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  justifyContent: "center",
                  padding: "10px",
                  fontWeight: "600",
                  fontSize: "1rem",
                }}
              >
                <div style={{ opacity: 0.8 }}>
                  Ready to feast your eyes on some premium content? You will
                  need to sign in first.
                </div>
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "20px",
                  }}
                >
                  <SignInBtn size="large" />
                </div>
              </div>
            </div>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export { VideoPaymentModal };
