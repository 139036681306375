import { API } from "../config/config";

export const verifyCoreumPayment = async (obj) => {
  try {
    const response = await fetch(`${API}/payment/crypto/verify-coreum-top-up`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    });
    const data = await response.json();
    return data; // Assuming data is an array of organizations
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getCoreumUSDTSpot = async () => {
  try {
    let response = await fetch(`${API}/payment/crypto/coreum-price`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      let data = await response.json();
      return data.coreumPriceLast;
    } else {
      console.error("Failed to getCoreumUSDTSpot");
    }
  } catch (error) {
    console.error(error);
  }
};
