import React, { useEffect, useState, useCallback, useRef } from "react";
import { makeStyles, Container, Typography } from "@material-ui/core";
import VideoGrid from "../Video/VideoGrid";
import { API } from "../../config/config";

const useStyles = makeStyles((theme) => ({
  pageTitleOuter: {
    marginTop: 20,
    marginBottom: 20,
  },
  pageTitle: {
    fontSize: 30,
    fontWeight: 800,
  },
  videoGridOuter: {
    display: "flex",
    padding: "20px",
  },
}));

export default function HistoryPage() {
  const classes = useStyles();

  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const hasMore = useRef(true);
  const page = useRef(1);
  const fetching = useRef(false);

  const fetchWatchedVideos = useCallback(async () => {
    if (fetching.current) return; // Prevent fetching when fetching is already in progress

    try {
      fetching.current = true;
      const response = await fetch(
        `${API}/channels/history?page=${page.current}`,
        {
          method: "GET",
          credentials: "include", // <-- Include cookies in the request
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      console.log("HistoryData: ", data);
      if (data.videos.length > 0) {
        setVideos((prevVideos) => [...prevVideos, ...data.videos]);
        page.current += 1;
      } else {
        hasMore.current = false;
      }
      fetching.current = false;
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const fetchAndCheckScroll = useCallback(async () => {
    await fetchWatchedVideos();

    // If the document doesn't fill the window, fetch more videos
    if (document.documentElement.offsetHeight < window.innerHeight) {
      fetchWatchedVideos();
    }
  }, [fetchWatchedVideos]);

  useEffect(() => {
    fetchAndCheckScroll();
  }, [fetchAndCheckScroll]);

  useEffect(() => {
    const handleScroll = () => {
      const distanceFromBottom =
        document.documentElement.scrollHeight -
        (window.pageYOffset + window.innerHeight);
      if (hasMore.current && distanceFromBottom < 500 && !fetching.current) {
        fetchWatchedVideos();
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove the event listener
    return () => window.removeEventListener("scroll", handleScroll);
  }, [fetchWatchedVideos]);

  return (
    <div>
      <Container className={classes.pageTitleOuter}>
        <div className={classes.pageTitle}>History</div>
      </Container>
      <div className={classes.videoGridOuter}>
        {isLoading && videos.length === 0 ? (
          <VideoGrid type="vertical_2" isLoading={isLoading} videos={videos} />
        ) : videos.length > 0 ? (
          <VideoGrid type="vertical_2" isLoading={isLoading} videos={videos} />
        ) : (
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <div style={{ fontSize: "16px", fontWeight: "700" }}>
              You have not watched any videos*
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
