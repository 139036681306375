import React from "react";
import { useDispatch } from "react-redux";

import { List, Divider, useMediaQuery, useTheme } from "@material-ui/core";
import { Home as HomeIcon, Whatshot as TrendingIcon } from "@material-ui/icons";
import menuAuthIcons from "../../menuAuthIcons";
import NavItem from "../NavItem";
import { toggleDrawer } from "../../../redux/actions/layout";
import { faHouseBlank as faHouseBlankSolid } from '@fortawesome/pro-solid-svg-icons';
import { faHouseBlank as faHouseBlankLight } from '@fortawesome/pro-light-svg-icons';
import { faHouse as faHouseDuotone } from '@fortawesome/pro-duotone-svg-icons';
const MainNavMenu = () => {
  const theme = useTheme();

  const isMinScreenMd = useMediaQuery(theme.breakpoints.up("md"));
  const dispatch = useDispatch();
  const handleItemClick = () => {
    if (!isMinScreenMd) {
      dispatch(toggleDrawer(isMinScreenMd));
    }
  };

  return (
    <List>
      {[
        {
          title: "Home",
          icon: faHouseDuotone,
          activeIcon: faHouseBlankSolid,
          path: "/",
        },
        ...menuAuthIcons,
      ].map((item, index) => {
        return (
          <React.Fragment key={index}>
            <NavItem
              to={item.path}
              title={item.title}
              icon={item.icon}
              activeIcon={item.activeIcon}
              onClick={handleItemClick}
            />
            {/* {index === 1 && <Divider />} */}
          </React.Fragment>
        );
      })}
    </List>
  );
};

export default MainNavMenu;
