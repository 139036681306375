import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import { Avatar, Typography, IconButton } from "@material-ui/core";
import LoggedInModal from "./LoggedInModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";

const useStyles = makeStyles((theme) => ({
  profileLink: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  iconButton: {
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: "#fff2",
    },
    "&:focus": {
      outline: theme.palette.iconBg,
    },
    marginLeft: "12px",
    //border: `1px solid ${grey[500]}`,
    borderRadius: "32px",
    padding: "5px 25px",
    backgroundColor: "#fff3",
    paddingTop: "7px",
    paddingBottom: "7px",
  },
  username: {
    marginRight: "12px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100px",
    fontSize: "14px",
    fontWeight: 600,
    letterSpacing: "-.01em",
  },
  themeSwitch: {
    margin: "16px",
  },
  connectedWalletIcon: {
    backgroundColor: "#C8FE00",
    color: "#C8FE00",
    borderRadius: 9999,
    padding: "6px",
  },
}));

const NavUserMenuBtn = ({ changeTheme, isDarkTheme }) => {
  const image = useSelector(({ channel }) => channel.image);
  const xrpAddress = useSelector(({ channel }) => channel.xrpAddress);
  const username = useSelector(({ channel }) => channel.username);
  const id = useSelector(({ channel }) => channel.id);

  const channel = useSelector(({ channel }) => channel);

  const classes = useStyles();
  const [loggedInModalOpen, setLoggedInModalOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setLoggedInModalOpen(true);
  };

  const handleClose = (event) => {
    setLoggedInModalOpen(false);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleToggle}
        className={classes.iconButton}
      >
        <Typography className={classes.username}>{username}</Typography>
        {/* <Avatar alt="Channel Image" src={image} /> */}
        <div className={classes.connectedWalletIcon}></div>
      </IconButton>

      {loggedInModalOpen && (
        <LoggedInModal
          changeTheme={changeTheme}
          isDarkTheme={isDarkTheme}
          isOpen={loggedInModalOpen}
          onClose={handleClose}
        />
      )}
    </>
  );
};

export default NavUserMenuBtn;
