import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { makeStyles, Typography, Button } from "@material-ui/core";
import FormControl from "@mui/material/FormControl";
import FilledInput from "@mui/material/FilledInput";
import NavUserMenuBtn from "../../components/Nav/TopNav/NavUserMenuBtn";
import phantomImg from "../../assets/image/phantom.png";
import metamaskImg from "../../assets/image/metamask.png";
import coreumImg from "../../assets/image/Coreum.png";
import xummImg from "../../assets/image/xumm.jpeg";
import ConnectAccountBtn from "../Buttons/ConnectAccountBtn";
import cardImg from "../../assets/image/Card.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import Tooltip from "@mui/material/Tooltip";
import { faArrowLeftLongToLine } from "@fortawesome/pro-duotone-svg-icons";
import CloseAndOptionsBtn from "../Buttons/CloseAndOptionsBtn";
import Web3 from "web3";
import ConnectXumm from "../ConnectAccount/ConnectXumm";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import { Buffer } from "buffer";
import { loadStripe } from "@stripe/stripe-js";
import { API, FRONTEND_URL, XUMM_SOCKET_URL } from "../../config/config";
import {
  verifyCoreumPayment,
  getCoreumUSDTSpot,
} from "../../action/coreumAction";

import {
  GasPrice,
  SigningStargateClient,
  calculateFee,
} from "@cosmjs/stargate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Connection,
  PublicKey,
  SystemProgram,
  LAMPORTS_PER_SOL,
  Transaction,
} from "@solana/web3.js";
import { io } from "socket.io-client";

const solanaConnection = new Connection(
  "https://try-rpc.mainnet.solana.blockdaemon.tech"
);

// Assign the `Buffer` object from the `buffer` library
window.Buffer = Buffer;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  button: {
    alignSelf: "center",
    fontSize: "12px",
    marginLeft: "5px",
    marginRight: "5px",
    backgroundColor: "rgb(0, 119, 247, 0.2)",
    color: "rgb(0, 119, 247)",
    "&:hover": {
      color: "white",
      backgroundColor: "rgb(0, 119, 247) !important",
    },
  },

  paymenMethodMenu: {
    "&.hide": {
      transition: "opacity 0.4s ease-in-out",
      display: "none",
    },
  },
  paymentMethodMenuGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "10px",
  },
  paymentAmountMenuGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "10px",
  },
  paymentAmount: {
    "&.hide": {
      transition: "opacity 0.4s ease-in-out",
      display: "none",
    },
  },
  paymentAmountItem: {
    backgroundColor: "#fff3",
    padding: "10px",
    textAlign: "center",
    transition: "opacity 0.4s ease-in-out",
    borderRadius: "10px",
    fontWeight: "700",
    "&:hover": {
      backgroundColor: "#303030",
    },
  },
}));

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "#121212",
  border: "1px solid #262626",
  boxShadow: 24,
  minHeight: "250px",
  p: 4,
  borderRadius: "15px",
  padding: "10px",
};

const PaymentModal = ({ balance, isOpen, onClose, onSuccess, onFailure }) => {
  const classes = useStyles();
  const isAuth = useSelector(({ channel }) => channel.isAuth);

  const [paymentMethodMenu, setPaymentMethodMenu] = useState(true);
  const [paymentAmountMenu, setPaymentAmountMenu] = useState(false);
  const [paymentSummaryMenu, setPaymentSummaryMenu] = useState(false);
  const [paymentStep, setPaymentStep] = useState(false);

  const [modalTitle, setModalTitle] = useState("Account top-up");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [selectedPaymentAmount, setSelectedPaymentAmount] = useState("");

  const [optionsSpanHover, setOptionsSpanHover] = useState(false);

  const channelId = useSelector(({ channel }) => channel.id);

  // const [xummSignIn, setXummSignIn] = useState(false);
  const [xummQrExposed, setXummQrExposed] = useState(false);
  const [xummQrCode, setXummQrCode] = useState("");
  const [xummUpdateMsg, setXummUpdateMsg] = useState("");

  const VM_ETHEREUM_ADDRESS = "0x3632f2cBb8F1fC00178d5477cBC55754A7bd5c7D";
  const VM_SOLANA_ADDRESS = "6FPp9rEr6BhW4JqotRkYzSMwpDfL7NY38NoVKso1rBAr";

  const CREATE_PAYMENT_REQUEST_ENDPOINT = `${API}/payment/crypto/create-payment-request`;
  const VERIFY_ETH_PAYMENT_API = `${API}/payment/crypto/verify-ethereum-top-up`;
  const VERIFY_SOLANA_PAYMENT_API = `${API}/payment/crypto/verify-solana-top-up`;

  // const [web3, setWeb3] = useState(null);

  function setMenu(menu) {
    switch (menu) {
      case "paymentMethod":
        setPaymentMethodMenu(true);
        setPaymentAmountMenu(false);
        setPaymentSummaryMenu(false);
        break;
      case "paymentAmount":
        setPaymentMethodMenu(false);
        setPaymentAmountMenu(true);
        setPaymentSummaryMenu(false);
        break;
      case "paymentSummary":
        setPaymentMethodMenu(false);
        setPaymentAmountMenu(false);
        setPaymentSummaryMenu(true);
    }
  }

  function resetModal() {
    setMenu("paymentMethod");
    setSelectedPaymentMethod("");
    setSelectedPaymentAmount("");
    setModalTitle("Account top-up");
    setPaymentStep(false);
    setXummUpdateMsg("");
  }

  function selectXRPPaymentMethod() {
    setMenu("paymentAmount");
    setSelectedPaymentMethod("XRP");
    setModalTitle("Select Amount");
  }

  function selectETHPaymentMethod() {
    setMenu("paymentAmount");
    setSelectedPaymentMethod("ETHEREUM");
    setModalTitle("Select Amount");
  }

  function selectSOLPaymentMethod() {
    setMenu("paymentAmount");
    setSelectedPaymentMethod("SOLANA");
    setModalTitle("Select Amount");
  }

  function selectCoreumPaymentMethod() {
    setMenu("paymentAmount");
    setSelectedPaymentMethod("COREUM");
    setModalTitle("Select Amount");
  }

  function selectCardPaymentMethod() {
    setMenu("paymentAmount");
    setSelectedPaymentMethod("CARD");
    setModalTitle("Select Amount");
  }

  function selectPaymentAmount(amount) {
    setMenu("paymentSummary");
    setSelectedPaymentAmount(amount);
    setModalTitle("Payment Summary");
  }

  function handleBuyNow() {
    setPaymentSummaryMenu(false);

    switch (selectedPaymentMethod) {
      case "XRP":
        XRPBuyNow();
        break;
      case "SOLANA":
        SolanaBuyNow();
        break;
      case "ETHEREUM":
        EthereumBuyNow();
        break;
      case "COREUM":
        CoreumBuyNow();
        break;
      case "CARD":
        CardBuyNow();
        break;
    }
  }

  async function createPaymentRequest(
    userAddress,
    selectedPaymentAmount,
    chain
  ) {
    return axios.post(
      CREATE_PAYMENT_REQUEST_ENDPOINT,
      {
        address: userAddress,
        usdAmount: selectedPaymentAmount,
        chain: chain,
      },
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }
  async function sendSOLPaymentTransaction(
    solanaConnection,
    fromWallet,
    toAddress,
    amount
  ) {
    // Convert the amount to Lamports (the smallest unit in Solana, like Wei in Ethereum)
    const lamports = LAMPORTS_PER_SOL * amount;

    // Ensure Phantom Wallet is connected
    if (!window.solana || !window.solana.isConnected) {
      console.error("Phantom Wallet is not connected");
      return;
    }

    // Create a transaction
    let transaction = new Transaction().add(
      SystemProgram.transfer({
        fromPubkey: new PublicKey(fromWallet),
        toPubkey: new PublicKey(toAddress),
        lamports: parseInt(lamports),
      })
    );

    transaction.recentBlockhash = (
      await solanaConnection.getRecentBlockhash()
    ).blockhash;

    transaction.feePayer = new PublicKey(fromWallet);

    let signedTransaction;
    try {
      signedTransaction = await window.solana.signTransaction(transaction);
    } catch (error) {
      console.error("Error signing transaction:", error);
      return;
    }

    // Send the signed transaction and get the transaction signature
    let signature;
    try {
      signature = await solanaConnection.sendRawTransaction(
        signedTransaction.serialize()
      );
      return signature;
    } catch (error) {
      console.error("Error sending transaction:", error);
      HandlePaymentFailure();
      return;
    }
  }

  async function sendETHPaymentTransaction(transaction, web3Provider) {
    return web3Provider.eth.sendTransaction(transaction);
  }

  async function verifyEthereumPayment(nonce, transactionHash, userAddress) {
    return axios.post(
      VERIFY_ETH_PAYMENT_API,
      {
        nonce: nonce,
        transactionHash: transactionHash,
        address: userAddress,
      },
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  async function verifySolanaPayment(nonce, transactionHash, userAddress) {
    return axios.post(
      VERIFY_SOLANA_PAYMENT_API,
      {
        nonce: nonce,
        transactionHash: transactionHash,
        address: userAddress,
      },
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  function XRPBuyNow() {
    // Send payment request using XRP
    setPaymentStep(true);
    setXummQrExposed(true);

    const socket = io(XUMM_SOCKET_URL, {
      path: "/xumm",
      auth: {
        // Add any authentication details if required
      },
    });

    socket.on("connect", () => {
      const request = {
        method: "XummPayment",
        usdAmount: selectedPaymentAmount,
        channelId,
      };

      socket.emit("message", JSON.stringify(request));
    });

    socket.on("message", (event) => {
      let message = JSON.parse(event);

      // UPDATE WHEN USER OPENS XUMM
      if (message.opened) {
        setXummUpdateMsg("OPENED");
      }

      // GET XUMM QR CODE
      if (message.refs && message.refs.qr_png) {
        setXummQrCode(message.refs.qr_png);
      }

      if (message.success === true) {
        HandlePaymentSuccess();
        resetModal();
      }

      if (message.success === false) {
        HandlePaymentFailure();
        resetModal();
      }

      // GET XUMM QR CODE
      if (message.refs && message.refs.qr_png) {
        setXummQrCode(message.refs.qr_png);
      }
    });

    socket.on("disconnect", () => {
      console.log("Socket.IO connection closed.");
    });
  }

  async function SolanaBuyNow() {
    setPaymentStep(true);

    try {
      if (window.solana && window.solana.isPhantom) {
        await window.solana.connect();
        const userAddress = window.solana.publicKey.toString();

        //Get payment ammount
        const paymentRequest = await createPaymentRequest(
          userAddress,
          selectedPaymentAmount,
          "SOL"
        );
        const paymentAmount = paymentRequest.data.cryptoAmount;

        const transactionHash = await sendSOLPaymentTransaction(
          solanaConnection,
          userAddress,
          VM_SOLANA_ADDRESS,
          paymentAmount
        );

        // verify payment

        const verifyPayment = await verifySolanaPayment(
          paymentRequest.data.nonce,
          transactionHash,
          userAddress
        );

        verifyPayment.data.message === "Payment successful"
          ? HandlePaymentSuccess()
          : HandlePaymentFailure();
      }
    } catch {}
  }

  const CoreumBuyNow = async () => {
    setPaymentStep(true);

    try {
      //Get recipientaddress from config and dynamic calculate ucore amount
      const recipientAddress = "core155sudcfqmj5utz700trk82pekz7fewut9l8fh2"; // Replace with the recipient's address
      let coreumUSDTSpot = await getCoreumUSDTSpot();
      //let amount = "8000000";
      let amount = ((selectedPaymentAmount / coreumUSDTSpot) * 1000000)
        .toFixed(0)
        .toString();

      if (!window.leap) {
        console.error("Leap wallet extension not found on this web browser.");
        return;
      }

      const chainId = "coreum-mainnet-1"; // Replace with the actual Coreum chain ID
      await window.leap.enable(chainId);

      const offlineSigner = window.leap.getOfflineSigner(chainId);
      const accounts = await offlineSigner.getAccounts();

      console.log("accounts: ", accounts);

      if (accounts.length === 0) {
        console.error("No Coreum account found.");
        return;
      }

      const senderAddress = accounts[0].address;
      console.log("Sender address: " + senderAddress);

      const rpcEndpoint = "https://full-node.mainnet-1.coreum.dev:26657"; // Replace with actual Coreum RPC endpoint
      const senderClient = await SigningStargateClient.connectWithSigner(
        rpcEndpoint,
        offlineSigner
      );

      console.log("senderClient: ", senderClient);

      const msgBankSend = {
        typeUrl: "/cosmos.bank.v1beta1.MsgSend",
        value: {
          fromAddress: senderAddress,
          toAddress: recipientAddress,
          amount: [
            {
              denom: "ucore", // Replace with actual denom
              amount: amount, // Replace with the amount to send
            },
          ],
        },
      };

      const fixedGasAmount = "250000"; // Example fixed gas amount, adjust as needed

      // Example gas price, replace with actual logic to determine gas price
      const gasPrice = GasPrice.fromString("0.025ucore");

      const bankSendFee = {
        amount: [
          {
            denom: "ucore",
            amount: String(Math.ceil(fixedGasAmount * gasPrice.amount)),
          },
        ],
        gas: fixedGasAmount,
      };

      const bankSendResult = await senderClient.signAndBroadcast(
        senderAddress,
        [msgBankSend],
        bankSendFee
      );

      if (bankSendResult.code === 0) {
        const obj = {
          transactionHash: bankSendResult.transactionHash,
          address: senderAddress,
          amount: selectedPaymentAmount,
        };

        const data = await verifyCoreumPayment(obj);

        HandlePaymentSuccess();

        console.log(
          "Successfully sent, tx hash: " + bankSendResult.transactionHash
        );
      } else {
        console.error("Failed to send transaction:", bankSendResult.rawLog);
      }
    } catch (error) {
      console.error("Error in CoreumBuyNow function:", error);
      HandlePaymentFailure();
    }
  };

  async function EthereumBuyNow() {
    setPaymentStep(true);
    try {
      if (window.ethereum) {
        const web3Provider = new Web3(window.ethereum);
        await window.ethereum.enable();
        const userAddress = (await web3Provider.eth.getAccounts())[0];

        const paymentRequest = await createPaymentRequest(
          userAddress,
          selectedPaymentAmount,
          "ETH"
        );

        const paymentAmount = paymentRequest.data.cryptoAmount;
        const nonce = `${paymentRequest.data.nonce}`;

        const transaction = {
          from: userAddress,
          to: VM_ETHEREUM_ADDRESS,
          value: web3Provider.utils.toWei(paymentAmount.toString(), "ether"),
          nonce: web3Provider.utils.toHex(nonce),
        };

        const transactionReceipt = await sendETHPaymentTransaction(
          transaction,
          web3Provider
        );

        if (transactionReceipt.status) {
          const verifyPayment = await verifyEthereumPayment(
            nonce,
            transactionReceipt.transactionHash,
            userAddress
          );

          verifyPayment.data.message === "Payment successful"
            ? HandlePaymentSuccess()
            : HandlePaymentFailure();
        } else {
          HandlePaymentFailure();
        }
      } else {
        HandlePaymentFailure();
      }
    } catch (error) {
      console.error("Error creating payment:", error);
      HandlePaymentFailure();
    }
  }

  async function CardBuyNow() {
    setPaymentStep(true);

    let productId;
    switch (selectedPaymentAmount) {
      case 10:
        productId = [1];
        break;
      case 20:
        productId = [2];
        break;
      case 50:
        productId = [3];
        break;
      case 100:
        productId = [4];
        break;
    }

    const successUrl = `${FRONTEND_URL}/settings/transactions?transactionResult=true`;
    const cancelUrl = `${FRONTEND_URL}/settings/transactions?transactionResult=false`;

    try {
      const response = await fetch(
        `${API}/payment/stripe/create-checkout-session`,
        {
          method: "POST",
          credentials: "include", // <-- Include cookies in the request
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            productId,
            successUrl,
            cancelUrl,
          }),
        }
      );

      if (response.ok) {
        const session = await response.json();

        const stripe = await loadStripe(
          process.env.REACT_APP_STRIPE_PUBLIC_KEY
        );
        if (stripe) {
          // Redirect the user to the checkout page
          const sessionId = session.id;
          stripe.redirectToCheckout({ sessionId });
        } else {
          console.error("Stripe initialization failed");
          HandlePaymentFailure();
        }
      } else {
        // Handle server errors
        console.error("Failed to create checkout session");
        HandlePaymentFailure();
      }
    } catch (error) {
      // Handle client-side errors
      console.error("Error creating checkout session:", error);
      HandlePaymentFailure();
    }
  }

  function HandlePaymentSuccess() {
    toast.info("Payment Success!", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "dark",
      style: {
        backgroundColor: "#1B1B1B", // Replace with your desired background color
      },
    });

    //Handle Success
    onSuccess();
    resetModal();
    onClose();
  }

  function HandlePaymentFailure() {
    toast.error("Payment Failure!", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "dark",
      style: {
        backgroundColor: "#1B1B1B", // Replace with your desired background color
      },
    });

    //Handle failure
    onFailure();
    resetModal();
    onClose();
  }

  return (
    <Modal
      open={isOpen}
      onBackdropClick={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <div
          className={classes.optionsSpan}
          onMouseEnter={() => setOptionsSpanHover(true)}
          onMouseLeave={() => setOptionsSpanHover(false)}
        >
          <CloseAndOptionsBtn onClose={onClose} onOption1={resetModal} />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography
            variant="h5"
            style={{
              fontWeight: "600",
              padding: "10px",
            }}
          >
            {modalTitle}
          </Typography>
        </div>
        <div
          style={{ padding: "10px", display: "flex", justifyContent: "center" }}
        >
          {isAuth && <NavUserMenuBtn />}{" "}
        </div>

        <div
          className={`${classes.paymenMethodMenu} ${
            paymentMethodMenu ? "" : "hide"
          }`}
        >
          <div>
            <Typography
              variant="h7"
              style={{
                fontWeight: "800",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span style={{ opacity: 0.7 }}>Balance:</span>
              &nbsp;
              {"$"}
              {balance}
            </Typography>
            <hr style={{ backgroundColor: "#262626" }} />
            <Typography
              variant="h7"
              style={{
                fontWeight: "600",
                //paddingTop: "20px",
              }}
            >
              Select payment method:
            </Typography>
            <div className={classes.paymentMethodMenuGrid}>
              {/* <ConnectAccountBtn
                type={"web3"}
                img={cardImg}
                label={"Card"}
                onClick={selectCardPaymentMethod}
              /> */}
              <ConnectAccountBtn
                type={"web3"}
                img={xummImg}
                label={"XRP"}
                onClick={selectXRPPaymentMethod}
              />
              <ConnectAccountBtn
                type={"web3"}
                img={coreumImg}
                label={"Coreum"}
                onClick={selectCoreumPaymentMethod}
              />
            </div>
            <div className={classes.paymentMethodMenuGrid}>
              <ConnectAccountBtn
                type={"web3"}
                img={metamaskImg}
                label={"Ethereum"}
                onClick={selectETHPaymentMethod}
              />
              <ConnectAccountBtn
                type={"web3"}
                img={phantomImg}
                label={"Solana"}
                onClick={selectSOLPaymentMethod}
              />
            </div>
            {/* <div className={classes.paymentMethodMenuGrid}>
              <ConnectAccountBtn
                type={"web3"}
                img={coreumImg}
                label={"Coreum"}
                onClick={selectCoreumPaymentMethod}
              />
            </div> */}
          </div>
        </div>
        <div
          className={`${classes.paymentAmount} ${
            paymentAmountMenu ? "" : "hide"
          }`}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Typography
              variant="h7"
              style={{
                fontWeight: "800",
                paddingTop: "10px",
              }}
            >
              <span style={{ opacity: 0.7 }}>Selected payment method: </span>
              {selectedPaymentMethod}
            </Typography>
          </div>
          <hr style={{ backgroundColor: "#262626" }} />

          <Typography
            variant="h7"
            style={{
              fontWeight: "600",
            }}
          >
            Select amount (USD):
          </Typography>
          <br />
          <br />
          <div className={classes.paymentAmountMenuGrid}>
            <div
              className={classes.paymentAmountItem}
              onClick={() => selectPaymentAmount(10)}
            >
              $10
            </div>
            <div
              className={classes.paymentAmountItem}
              onClick={() => selectPaymentAmount(20)}
            >
              $20
            </div>
            <div
              className={classes.paymentAmountItem}
              onClick={() => selectPaymentAmount(50)}
            >
              $50
            </div>
            <div
              className={classes.paymentAmountItem}
              onClick={() => selectPaymentAmount(100)}
            >
              $100
            </div>
          </div>
        </div>
        <div
          className={`${classes.paymentAmount} ${
            paymentSummaryMenu ? "" : "hide"
          }`}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Typography
              variant="h7"
              style={{
                fontWeight: "800",
                paddingTop: "10px",
              }}
            >
              <span style={{ opacity: 0.7 }}>Payment method: </span>
              {selectedPaymentMethod}
            </Typography>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Typography
              variant="h7"
              style={{
                fontWeight: "800",
                paddingTop: "10px",
              }}
            >
              <span style={{ opacity: 0.7 }}>Top-up amount (USD): </span>$
              {selectedPaymentAmount}
              {".00"}
            </Typography>
          </div>
          <br />
          <div style={{ justifyContent: "center", display: "flex" }}>
            <Button
              //onClick={handleOpenFundPaymentChannel}
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={handleBuyNow}
              style={{ width: "300px" }}
            >
              Buy now!
            </Button>
          </div>
        </div>
        <div
          className={`${classes.paymentAmount} ${paymentStep ? "" : "hide"}`}
        >
          <br />
          <div
            className={`${classes.paymentAmount} ${
              selectedPaymentMethod === "XRP" ? "" : "hide"
            }`}
          >
            <ConnectXumm
              show={true}
              qrExposed={xummQrExposed}
              qrCode={xummQrCode}
              updateMsg={xummUpdateMsg}
            />
            <br />
          </div>
          <div
            className={`${classes.paymentAmount} ${
              selectedPaymentMethod === "ETHEREUM" || "SOLANA" ? "" : "hide"
            }`}
            style={{ padding: "20px", opacity: 0.9 }}
          >
            <CircularProgress size={15} thickness={3.6} disableShrink />
            &nbsp; Please refer to the wallet window, and wait on this screen
            until payment is confirmed...
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export { PaymentModal };
