import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Box, Typography, Tab, Tabs } from "@material-ui/core";
import Comments from "./Comments";
import CommentForm from "./CommentForm";
import { getVideoComments } from "../../redux/actions/comments";
import VideoList from "../Video/VideoList";
import { grey } from "@material-ui/core/colors";
import TabPanel from "../TabPanel";

const useStyles = makeStyles((theme) => ({
  title: {
    paddingBottom: theme.spacing(1),
  },
  text: {
    fontWeight: 400,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  subTitle: {
    color: grey[700],
  },
  pointer: {
    cursor: "pointer",
  },
  primaryInfo: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  secondaryInfo: {
    display: "flex",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  secondaryInfo_1: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  secondaryInfo_2: {
    display: "flex",
    width: "100%",
    marginBottom: "10px",
  },
  channel: {
    fontWeight: 500,
  },
  secondaryInfo_3: {
    paddingLeft: theme.spacing(1),
    lineHeight: "80%",
  },
  likeSubscribe: {
    display: "flex",
    marginLeft: "auto",
  },
}));

const CommentsContent = ({ videoId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  const fetchComments = () => {
    dispatch(getVideoComments(videoId));
  };

  useEffect(() => {
    fetchComments();
  }, [videoId]);

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tab-${index}`,
    };
  }

  return (
    <div>
      <TabPanel value={value} index={0}>
        <CommentForm videoId={videoId} onSuccess={fetchComments} />
        <Comments videoId={videoId} />
      </TabPanel>
    </div>
  );
};

export default CommentsContent;
