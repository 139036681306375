import React, { useEffect } from "react";
import { makeStyles, Container, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  web3SignInButton: {
    backgroundColor: "rgb(58, 58, 60)",
    border: "none",
    borderRadius: "10px",
    color: "white",
    fontWeight: "500",
    fontSize: "14px",
    margin: "0.5rem 0rem 0.5rem 0rem",
    padding: "0.8rem 0.8rem 0.8rem 1.2rem",
    minWidth: "100%",
    display: "flex",
    "&:hover": {
      backgroundColor: "#333333 !important",
    },
  },
  web2SignInButton: {
    backgroundColor: "#fff",
    border: "none",
    borderRadius: "10px",
    color: "black",
    fontWeight: "500",
    fontSize: "14px",
    margin: "0.5rem 0rem 0.5rem 0rem",
    padding: "0.8rem 0.8rem 0.8rem 1.2rem",
    minWidth: "100%",
    display: "flex",
    "&:hover": {
      backgroundColor: "#e0e0e0 !important",
    },
  },
  signInImgOuter: {
    top: "50%",
    left: "16px",
    lineHeight: 0,
  },
  signInBtnText: {
    margin: "auto",
    paddingRight: "30px",
    fontWeight: "600",
    fontSize: "16px",
  },

  signInImg: {
    width: "100%",
    height: "100%",
    maxWidth: "2rem",
    maxHeight: "2rem",
    borderRadius: "50%",
    objectFit: "cover",
    minWidth: "2rem",
    minHeight: "2rem",
  },
}));

const ConnectAccountBtn = ({ type, img, label, onClick }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  useEffect(() => {}, []);

  return (
    <button
      onClick={onClick}
      className={
        type === "web3" ? classes.web3SignInButton : classes.web2SignInButton
      }
    >
      <div className={classes.signInImgOuter}>
        <img className={classes.signInImg} src={img} />
      </div>
      <div className={classes.signInBtnText}>{label}</div>
    </button>
  );
};

export default ConnectAccountBtn;
