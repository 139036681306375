import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles, Button, Box } from "@material-ui/core";
import {
  setThumbnail,
  setThumbnails,
  setThumbnailPath,
} from "../redux/actions/upload";
import Image from "./Image";
import { useDropzone } from "react-dropzone";
import { api } from "../utils/api";
import { uploadThumbnail, uploadProfileImage } from "../redux/actions/upload";

const useStyles = makeStyles((theme) => ({
  root: { display: "flex", flexWrap: "wrap", justifyContent: "center" },
  button: {
    marginRight: theme.spacing(1),
  },
  img: {
    margin: theme.spacing(1),
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  picker: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  uploadButton: {
    marginBottom: theme.spacing(2),
  },
}));

export default function ImagePicker({ type, onSuccess }) {
  const [image, setImage] = useState();
  const classes = useStyles();
  const dispatch = useDispatch();

  const onDrop = async ([file]) => {
    setImage(file.name);

    switch (type) {
      case "Thumbnail":
        dispatch(uploadThumbnail(file));
        dispatch(setThumbnail(file.name));
        dispatch(
          setThumbnails(
            `https://videomaster-dev.s3.ap-southeast-2.amazonaws.com/Thumbnails/${file.name}`
          )
        );
        dispatch(setThumbnailPath(file.path));
        break;
      case "Profile Image":
        dispatch(uploadProfileImage(file));
        onSuccess();
        break;
      default:
        const { uploadData } = await api.post("/api/channels/upload", {
          filename: file.name,
          type: type.toLowerCase(),
        });
        // do something with file maybe dispatch
        break;
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className={classes.root}>
      <Box {...getRootProps()} sx={{ padding: "25px" }}>
        <input {...getInputProps()} />
        <Button variant="contained" color="primary">
          Upload {type}
        </Button>
      </Box>
      {image && <Image src={image} />}
    </div>
  );
}
