import axios from "axios";
import { API } from "../config/config";

/**
 * Fetches the creator payout permission status for the current user.
 * @returns {Promise<string>} A promise that resolves to the permission status of the user.
 */
export const checkCreatorPayoutPermission = async () => {
  try {
    const response = await axios.get(`${API}/payouts/permission`, {
      withCredentials: true, // This will ensure credentials are included with the request
    });
    return response.data.permission;
  } catch (error) {
    console.error("Error checking creator payout permission:", error);
    throw error; // Rethrow to handle it in the calling context
  }
};

export const requestCreatorPayoutPermission = async () => {
  try {
    const response = await axios.post(
      `${API}/payouts/request-permission`,
      {},
      {
        withCredentials: true, // Assuming you need to send cookies for auth
      }
    );
    return response.data; // You might adjust this based on your actual API response
  } catch (error) {
    throw error;
  }
};

export const getChannelBalance = async () => {
  try {
    const response = await axios.get(`${API}/payouts/getBalance`, {
      withCredentials: true, // Assuming you need to send cookies for auth
    });
    return response.data; // You might adjust this based on your actual API response
  } catch (error) {
    throw error;
  }
};

export const withdrawCoreum = async (withdrawAmount) => {
  try {
    const response = await axios.post(
      `${API}/payouts/withdraw`,
      {
        chain: "COREUM",
        usdWithdrawAmount: withdrawAmount,
      },
      {
        withCredentials: true, // Assuming you need to send cookies for auth
      }
    );
    return response.data; // You might adjust this based on your actual API response
  } catch (error) {
    throw error;
  }
};

export const getWithdraw = async (id) => {
  try {
    const response = await axios.get(`${API}/payouts/withdraw/${id}`, {
      withCredentials: true, // Assuming you need to send cookies for auth
    });
    return response.data; // You might adjust this based on your actual API response
  } catch (error) {
    throw error;
  }
};

export const getWithdraws = async () => {
  try {
    const response = await axios.get(`${API}/payouts/withdraws`, {
      withCredentials: true, // Assuming you need to send cookies for auth
    });
    return response.data; // You might adjust this based on your actual API response
  } catch (error) {
    throw error;
  }
};
