import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import {
    Facebook as FacebookIcon,
    Instagram as InstagramIcon,
    Twitter as TwitterIcon,
    YouTube as YoutubeIcon,
} from "@material-ui/icons";
import { FB_LINK, IG_LINK, TWITTER_LINK, YT_LINK } from "../../../constants/links";

const useStyles = makeStyles((theme) => ({
    iconsWrapper: {
        display: "flex",
        justifyContent: "space-between",
        padding: "16px 24px"
    },
}));

const SocialMedia = () => {
    const classes = useStyles();

    return (
        <Box className={classes.iconsWrapper}>
            <a href={FB_LINK}>
                <FacebookIcon color="primary" />
            </a>
            <a href={IG_LINK}>
                <InstagramIcon color="primary" />
            </a>
            <a href={TWITTER_LINK}>
                <TwitterIcon color="primary" />
            </a>
            <a href={YT_LINK}>
                <YoutubeIcon color="primary" />
            </a>
        </Box>
    );
};

export default SocialMedia;
