import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SwiperComponent from "../Video/VideoSwiper";
import { makeStyles, Container, Typography } from "@material-ui/core/";
import VideoGrid from "../Video/VideoGrid";
import { getHomeVideos } from "../../redux/actions/videos";
import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import FeaturedVideo from "../../components/misc/FeaturedVideo";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
  },
  text: {
    paddingBottom: theme.spacing(3),
    fontWeight: 700,
  },
  divider: {
    height: "5px",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  banner: {
    width: "100%",
    color: "#02013d",
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    objectFit: "cover",
  },
  top10Swiper: {
    marginTop: "-110px",
  },
}));

const HomePage = () => {
  const recommendedVids = useSelector(({ videos }) => videos.recommended);
  const trendingVids = useSelector(({ videos }) => videos.trending);
  const isLoading = useSelector(({ videos }) => videos.isLoading);
  const dispatch = useDispatch();
  const classes = useStyles();
  let theme = createTheme();

  useEffect(() => {
    dispatch(getHomeVideos());
  }, []);

  return (
    <div>
      <FeaturedVideo />
      {/* Top 10 Videos */}
      <div className={classes.top10Swiper}>
        <SwiperComponent
          featureType="top10"
          exploreAllLink="/trending"
          swiperType="video"
          genre="all"
          limit="10"
          title="Top 10 Videos This Week"
        />
      </div>

      {/* For you */}
      <SwiperComponent
        featureType="recommended"
        exploreAllLink="/trending"
        swiperType="recommendedVideos"
        genre="all"
        limit="10"
        title="Recommended for You"
      />

      {/* Top 10 Channels */}
      <SwiperComponent
        featureType="top10"
        exploreAllLink="/trending"
        swiperType="channel"
        genre="all"
        limit="10"
        title="Featured Comics"
      />

      {/* Continue Watching */}
      {/* <SwiperComponent
        featureType="top10"
        exploreAllLink="/subscriptions"
        swiperType="video"
        genre="all"
        limit="10"
        title="Continue watching"
      /> */}

      <SwiperComponent
        featureType="premium"
        exploreAllLink="/trending"
        swiperType="premiumVideos"
        genre="all"
        limit="10"
        title="Premium Videos"
      />

      {/* Shorts */}
      <SwiperComponent
        featureType="shorts"
        exploreAllLink="/trending"
        swiperType="video"
        genre="all"
        limit="10"
        title="Shorts"
      />

      <Container maxWidth="xl" className={classes.root}>
        <Typography variant="h5" className={classes.text}>
          Featured Videos
        </Typography>
        <VideoGrid
          type="vertical_2"
          isLoading={isLoading}
          videos={trendingVids}
        />
        {/* <Typography variant="h5" className={classes.text}>
          Explore
        </Typography>
        <VideoGrid
          type="vertical_2"
          isLoading={isLoading}
          videos={recommendedVids}
        /> */}
      </Container>
    </div>
  );
};

export default HomePage;
