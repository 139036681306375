import React, { useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-http-source-selector/dist/videojs-http-source-selector.css"; // Import the plugin CSS
import httpSourceSelector from "videojs-http-source-selector"; // Import the plugin

// Register the plugin with Video.js
videojs.registerPlugin("httpSourceSelector", httpSourceSelector);

export default function TestVideoComponent() {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      const customXhrHandler = (options, callback) => {
        console.log(`Custom XHR handler for ${options.uri}`);
        videojs.xhr(options, callback);
      };

      const player = videojs(video, {
        controls: true,
        autoplay: false,
        preload: "auto",
        sources: [
          {
            src: "http://localhost:5001/api/videos/hls/playlist/66840557cf8a2f006dc52a34/null", // Hardcoded video ID
            type: "application/x-mpegURL",
          },
        ],
      });

      console.log("player: ", player);

      player.httpSourceSelector();

      // Cleanup on component unmount
      return () => {
        if (player) {
          player.dispose();
        }
      };
    }
  }, []);

  return (
    <div>
      <video
        ref={videoRef}
        className="video-js vjs-default-skin"
        controls
      ></video>
    </div>
  );
}
