import React from "react";
import TopNav from "./TopNav";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink, useLocation } from "react-router-dom";

import SideNav from "./SideNav";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    overflow: "hidden",
  },
  toolbar: {
    ...theme.mixins.toolbar,
  },
}));

const NavBar = ({ children, changeTheme, isDarkTheme }) => {
  const classes = useStyles();
  const location = useLocation();
  let currentPage = location.pathname;
  const isTopNavVisible = currentPage === "/signin" ? false : true;
  const isSideNavVisible = currentPage === "/signin" ? false : true;
  return (
    <div className={classes.root}>
      {isTopNavVisible && (
        <TopNav changeTheme={changeTheme} isDarkTheme={isDarkTheme} />
      )}
      {isSideNavVisible && <SideNav />}
      <div className={classes.content}>
        {isTopNavVisible && (
          //Padding for top nav bar
          <div className={classes.toolbar} />
        )}

        {children}
      </div>
    </div>
  );
};

export default NavBar;
