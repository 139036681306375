import axios from "axios";
import { API } from "../config/config";

export const getVideoWeb3Settings = async (videoId) => {
  try {
    const response = await axios.get(
      `${API}/videos/web3Settings/${videoId}`,

      {
        withCredentials: true, // Assuming you need to send cookies for auth
      }
    );
    return response.data; // You might adjust this based on your actual API response
  } catch (error) {
    throw error;
  }
};
