import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  walletModal: {
    position: "absolute",
    right: "0px",
    top: "25px",
    height: "80%",
    width: "20%",
    borderRadius: "18px",
    padding: "40px",
    minWidth: "300px",
  },
  ctaButton: {
    width: "100%",
    marginTop: "20px",
  },
  agreement: {
    fontSize: "14px",
    marginTop: "10px",
  },
  learnMore: {
    marginTop: "10px",
    textAlign: "center",
    fontSize: "14px",
  },
  learnMoreLink: {
    marginTop: "10px",
  },
  qrCodeWrapper: {
    margin: "25px 0",
    display: "flex",
    justifyContent: "center",
  },
  close: {
    position: "absolute",
    top: "0",
    right: "0",
    padding: "25px",
    fontWeight: "bold",
  },
  header: {
    fontSize: "1.75rem",
  },
}));

const LoginModal = ({ isOpen, onClose }) => {
  // const [qrExposed, setQrExposed] = useState(false);
  // const classes = useStyles();
  // const [qrCode, setQRCode] = useState("");
  // const loginWithAddress = async (xrpAddress) => {
  //     try {
  //         await api.post('/api/auth/login', {
  //             xrpAddress: xrpAddress,
  //         })
  //             .then(res => {
  //                 if (res.status === 200) {
  //                     window.location.replace("/");
  //                 }
  //             })
  //     } catch (err) {
  //         console.warn(err);
  //     }
  // };
  // const exposeQR = () => {
  //     setQrExposed(true);
  //     getXummSignIn();
  // }
  // const getXummSignIn = async () => {
  //     try {
  //         const signInResponse = await api.get(`/api/xumm/getSignIn`);
  //         console.log(signInResponse);
  //         setQRCode(signInResponse.data.signInResponse.refs.qr_png);
  //         const response = await api.post(`/api/xumm/signIn`, {
  //             uuid: signInResponse.data.signInResponse.uuid,
  //         });
  //         await loginWithAddress(response.data.xrpAddress);
  //     } catch (err) {
  //         console.log(err);
  //     }
  // };
  // const displayQrCode = () => {
  //     if (qrCode) {
  //         return (
  //             <img src={qrCode} width="200" height="200" />
  //         );
  //     } else {
  //         return (
  //             <CircularProgress color="primary" />
  //         );
  //     }
  // }
  // return (
  //     <Dialog open={isOpen} onClose={onClose}
  //         classes={{
  //             paper: classes.walletModal
  //         }}
  //         data-testid="login-modal"
  //     >
  //         <Box>
  //             <Typography onClick={onClose} className={classes.close}>x</Typography>
  //             <Typography variant="h4" className={classes.header} >Connect wallet</Typography>
  //             <Typography className={classes.agreement}>By connecting your wallet, you agree to our <a href={TNC_LINK}>Terms and Conditions</a>, and our <a href={PRIVACY_LINK}>Privacy policy</a></Typography>
  //             <Button onClick={() => loginWithAddress("rLVgKucxkCUGv9TPkBLrwFfhjkgfhjkv8ZKWePy8FWs")} color="primary" variant="contained" className={classes.ctaButton}>Tester Login</Button>
  //             <Button onClick={exposeQR} color="primary" variant="contained" className={classes.ctaButton}>XUMM</Button>
  //             <Box className={classes.qrCodeWrapper}>
  //                 {qrExposed &&
  //                     displayQrCode()
  //                 }
  //             </Box>
  //             <Box className={classes.learnMore}>
  //                 <Typography>New to XRP?</Typography>
  //                 <Typography className={classes.learnMoreLink}><a href="#">Learn more about wallets</a></Typography>
  //             </Box>
  //         </Box>
  //     </Dialog>
  // );
};

export default LoginModal;
