import React from "react";
import { Dialog, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import NavItem from "../NavItem";
import {
  ExitToApp as ExitToAppIcon,
  Settings as SettingsIcon,
  Help as HelpIcon,
  Receipt as TransactionsIcon,
  Brightness2Outlined as DarkModeIcon,
  WbSunnyOutlined as LightModeIcon,
} from "@material-ui/icons";
import { logoutChannel } from "../../../redux/actions/channel";
import SocialMedia from "./SocialMedia";
import {
  faQuestion as faQuestionDuotone,
  faArrowRightFromArc as faArrowRightFromArcDuotone,
  faUser as faUserDuotone,
  faGears as faGearsDuotone,
} from "@fortawesome/pro-duotone-svg-icons";
import {
  Avatar,
  Grid,
  List,
  Divider,
  MenuList,
  Switch,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  profileLink: {
    color: theme.palette.accent,
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.accent,
    },
  },
  walletModal: {
    position: "absolute",
    right: "0px",
    top: "25px",
    width: "20%",
    minWidth: "340px",
    borderRadius: "18px",
    padding: "10px",
    backgroundColor: theme.palette.background.primary,
  },
  ctaButton: {
    width: "100%",
    marginTop: "20px",
  },
  agreement: {
    fontSize: "14px",
    marginTop: "10px",
  },
  learnMore: {
    marginTop: "10px",
    textAlign: "center",
    fontSize: "14px",
  },
  learnMoreLink: {
    marginTop: "10px",
  },
  qrCodeWrapper: {
    margin: "25px 0",
    display: "flex",
    justifyContent: "center",
  },
  close: {
    position: "absolute",
    top: "0",
    right: "0",
    padding: "25px",
    fontWeight: "bold",
  },
  switchContainer: {
    padding: "0 24px",
  },
}));

const LoggedInModal = ({ isOpen, onClose, changeTheme, isDarkTheme }) => {
  const classes = useStyles();
  const image = useSelector(({ channel }) => channel.image);
  const name = useSelector(({ channel }) => channel.xrpAddress);
  const id = useSelector(({ channel }) => channel.id);
  const dispatch = useDispatch();

  const handleSignOut = async () => {
    await dispatch(logoutChannel());
    onClose();
    window.location.replace("/");
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      classes={{
        paper: classes.walletModal,
      }}
      data-testid="login-modal"
    >
      <List component="nav" aria-labelledby="nested-list-subheader">
        <NavItem
          to={`/channel/${id}`}
          title={"View Account"}
          icon={faUserDuotone}
          activeIcon={faUserDuotone}
          onClick={() => onClose()}
        />
        <NavItem
          to={`/settings/account`}
          title={"Settings"}
          icon={faGearsDuotone}
          activeIcon={faGearsDuotone}
          onClick={() => onClose()}
        />
        <NavItem
          to={`/help`}
          title={"Help"}
          icon={faQuestionDuotone}
          activeIcon={faQuestionDuotone}
          onClick={() => onClose()}
        />
        <NavItem
          title={"Sign Out"}
          icon={faArrowRightFromArcDuotone}
          activeIcon={faArrowRightFromArcDuotone}
          onClick={handleSignOut} // Use the async function to handle sign out
        />
      </List>
    </Dialog>
    // <Dialog open={isOpen} onClose={onClose}
    //     classes={{
    //         paper: classes.walletModal
    //     }}
    //     data-testid="login-modal">
    //     <MenuList
    //         id="menu-list-grow"
    //     >
    //         <Grid
    //             container
    //             spacing={1}
    //             alignItems="center"
    //             wrap="nowrap"
    //             style={{ padding: "12px 24px" }}
    //         >
    //             <Grid item>
    //                 <Avatar alt="Profile Image" src={image} size="large" />
    //             </Grid>
    //             <Grid item>
    //                 <Grid container style={{ overflow: "hidden" }}>
    //                     <Grid item xs={12}>
    //                         <strong>{name}</strong>
    //                     </Grid>{" "}
    //                     <Grid item><a className={classes.profileLink} href={`/channel/${id}`}>View profile</a></Grid>
    //                 </Grid>
    //             </Grid>
    //         </Grid>
    //         <Divider />
    //         <Box className={classes.switchContainer}>
    //             <LightModeIcon color="primary" />
    //             <Switch onChange={changeTheme} checked={isDarkTheme} className={classes.themeSwitch} color="primary" />
    //             <DarkModeIcon color="primary" />
    //         </Box>
    //         <Divider />
    //         <List component="nav" aria-labelledby="nested-list-subheader">
    //             <NavItem
    //                 to={`/transactions`}
    //                 title={"Transactions"}
    //                 icon={TransactionsIcon}
    //                 onClick={() => onClose()}
    //             />
    //             <NavItem
    //                 to={`/settings`}
    //                 title={"Settings"}
    //                 icon={SettingsIcon}
    //                 onClick={() => onClose()}
    //             />
    //             <NavItem
    //                 to={`/help`}
    //                 title={"Help"}
    //                 icon={HelpIcon}
    //                 onClick={() => onClose()}
    //             />
    //             <NavItem
    //                 title={"Sign Out"}
    //                 icon={ExitToAppIcon}
    //                 onClick={(e) => {
    //                     onClose();
    //                     dispatch(logoutChannel());
    //                     window.location.reload();
    //                 }}
    //             />
    //         </List>
    //         <Divider />
    //         <SocialMedia />
    //     </MenuList>
    // </Dialog>
  );
};

export default LoggedInModal;
