import React, { useState, useMemo, createContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import TempSignInPage from "./components/Pages/TempSignInPage";
import HomePage from "./components/Pages/HomePage";
import VideoPage from "./components/Pages/VideoPage";
import ClipsPage from "./components/Pages/ClipsPage";
import TrendingPage from "./components/Pages/TrendingPage";
import SearchPage from "./components/Pages/SearchPage";
import ComingSoonPage from "./components/Pages/ComingSoonPage";
import ChannelPage from "./components/Pages/ChannelPage";
import CreatePage from "./components/Pages/CreatePage";
import SignInPage from "./components/Pages/SignInPage";
import HelpPage from "./components/Pages/HelpPage";
import HistoryPage from "./components/Pages/HistoryPage";

//Legal
import TermsOfServicePage from "./components/Pages/TermsOfServicePage";
import PrivacyPolicyPage from "./components/Pages/PrivacyPolicyPage";

//Settings
import { SettingsMenu } from "./components/Nav/SettingsMenu/index";
import SettingsAccountPage from "./components/Pages/Settings/Account";
import SettingsTransactionsPage from "./components/Pages/Settings/Transactions";
import SettingsLinkedAccountsPage from "./components/Pages/Settings/LinkedAccounts";
import SettingsCreatorPayoutPage from "./components/Pages/Settings/CreatorPayout";
import SettingsNotificationPage from "./components/Pages/Settings/Notifications";

//Create
import { CreateMenu } from "./components/Nav/CreateMenu/index";
import CreateDashboardPage from "./components/Pages/Create/Dashboard";
import CreateDraftsPage from "./components/Pages/Create/Drafts";
import CreateVideoPage from "./components/Pages/Create/Video";
import UploadedVideoPage from "./components/Pages/Create/UploadedVideos";
import CreateAnalyticsPage from "./components/Pages/Create/Analytics";
import MonetisationPage from "./components/Pages/Create/Monetisation";

//CharacterGPT
import CharacterSelectionPage from "./components/Pages/CharacterGPT/CharacterSelectionPage";
import ChatPage from "./components/Pages/CharacterGPT/ChatPage";

//User Interaction Pages
import LikedPage from "./components/Pages/LikedPage";
import SubscriptionPage from "./components/Pages/SubscriptionPage";

import TransactionsPage from "./components/Pages/TransactionsPage";
import auth from "./components/authHOC";
import Nav from "./components/Nav";
import { createTheme, ThemeProvider } from "@material-ui/core";
import { createPalette } from "./services/createPalette";

// Define the toastId outside the function
let toastId = null;

// Function to show notification when Wi-Fi disconnects using react-toastify
function showNotification() {
  if (!toast.isActive(toastId)) {
    // If toast is not already showing, show it
    toastId = toast.error("Your Wi-Fi connection has been lost.", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "dark",
      style: {
        backgroundColor: "#1B1B1B", // Replace with your desired background color
      },
    });
  }
}

// Function to handle connectivity status change
function handleConnectionChange() {
  const isConnected = navigator.onLine;

  if (!isConnected) {
    showNotification();
  } else if (toast.isActive(toastId)) {
    // If reconnected and toast is showing, dismiss it
    toast.dismiss(toastId);
  }
}

// Add event listeners for connectivity status change
window.addEventListener("online", handleConnectionChange);
window.addEventListener("offline", handleConnectionChange);

const ColorModeContext = createContext({ toggleColorMode: () => {} });

function App() {
  const Home = auth(HomePage, false);
  const History = auth(TempSignInPage, false);
  const Video = auth(VideoPage, false);
  const Clip = auth(ClipsPage, false);
  const Trending = auth(TrendingPage, false);
  const Search = auth(SearchPage, false);
  const ComingSoon = auth(ComingSoonPage, false);
  const Channel = auth(ChannelPage, false);
  const Create = auth(TempSignInPage, false);
  const Help = auth(HelpPage, false);

  //CHARACTERGPT
  const CharacterSelection = auth(TempSignInPage, false);

  //SETTINGS

  //LEGAL
  const TermsOfService = auth(TermsOfServicePage, false);
  const PrivacyPolicy = auth(PrivacyPolicyPage, false);

  //CREATE
  //const CreateDashboard = auth(TempSignInPage, false);

  const Transactions = auth(TransactionsPage, false);
  const SignIn = auth(SignInPage, false);
  const [mode, setMode] = useState("dark");
  const colorMode = useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  // Update the theme only if the mode changes
  const theme = React.useMemo(() => createTheme(createPalette(mode)), [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ToastContainer />

      <ThemeProvider theme={theme}>
        <div>
          <Nav
            changeTheme={colorMode.toggleColorMode}
            isDarkTheme={mode === "dark" ? true : false}
          >
            <Switch>
              <Route exact path="/" render={(props) => <Home {...props} />} />
              <Route
                exact
                path="/trending"
                render={(props) => <Trending {...props} />}
              />
              <Route
                exact
                path="/subscriptions"
                render={(props) => (
                  <TempSignInPage
                    {...props}
                    page="subscriptions"
                    component={SubscriptionPage}
                  />
                )}
              />
              <Route
                exact
                path="/transactions"
                render={(props) => (
                  <Transactions {...props} page="transactions" />
                )}
              />

              <Route
                exact
                path="/liked"
                render={(props) => (
                  <TempSignInPage
                    {...props}
                    page="liked"
                    component={LikedPage}
                  />
                )}
              />
              <Route
                exact
                path="/create"
                render={(props) => (
                  <Create {...props} page="create secondary" />
                )}
              />
              <Route
                exact
                path="/help"
                render={(props) => <Help {...props} page="help" />}
              />

              <Route
                exact
                path="/settings/account"
                render={(props) => (
                  <div style={{ display: "flex", height: "100%" }}>
                    <SettingsMenu />
                    <TempSignInPage
                      {...props}
                      page="settings"
                      component={SettingsAccountPage}
                    />
                  </div>
                )}
              />
              <Route
                exact
                path="/settings/linked-accounts"
                render={(props) => (
                  <div style={{ display: "flex", height: "100%" }}>
                    <SettingsMenu />
                    <TempSignInPage
                      {...props}
                      page="settings"
                      component={SettingsLinkedAccountsPage}
                    />
                  </div>
                )}
              />
              <Route
                exact
                path="/settings/creator-payout"
                render={(props) => (
                  <div style={{ display: "flex", height: "100%" }}>
                    <SettingsMenu />
                    <TempSignInPage
                      {...props}
                      page="settings"
                      component={SettingsCreatorPayoutPage}
                    />
                  </div>
                )}
              />
              <Route
                exact
                path="/settings/transactions"
                render={(props) => (
                  <div style={{ display: "flex", height: "100%" }}>
                    <SettingsMenu />
                    <TempSignInPage
                      {...props}
                      page="settings"
                      component={SettingsTransactionsPage}
                    />
                  </div>
                )}
              />
              <Route
                exact
                path="/settings/notifications"
                render={(props) => (
                  <div style={{ display: "flex", height: "100%" }}>
                    <SettingsMenu />
                    <TempSignInPage
                      {...props}
                      page="settings"
                      component={SettingsNotificationPage}
                    />
                  </div>
                )}
              />
              <Route
                exact
                path="/history"
                render={(props) => (
                  <History {...props} page="history" component={HistoryPage} />
                )}
              />
              <Route
                exact
                path="/watch"
                render={(props) => <Video {...props} />}
              />
              <Route
                exact
                path="/channel/:id"
                render={(props) => <Channel {...props} />}
              />
              <Route
                exact
                path="/results"
                render={(props) => <Search {...props} />}
              />
              <Route
                exact
                path="/signin"
                render={(props) => <SignIn {...props} page="signin" />}
              />

              <Route
                exact
                path="/c/:character/:id?"
                render={(props) => (
                  <div style={{ display: "flex", height: "100%" }}>
                    <CharacterSelection
                      {...props}
                      page="charactergpt"
                      component={ChatPage}
                    />
                  </div>
                )}
              />

              {/* CLIPS */}

              <Route
                exact
                path="/clips"
                render={(props) => <Clip {...props} />}
              />

              {/* LEGAL */}
              <Route
                exact
                path="/terms-of-service"
                render={(props) => (
                  <TermsOfService {...props} page="Terms of Serivce" />
                )}
              />
              <Route
                exact
                path="/privacy-policy"
                render={(props) => (
                  <PrivacyPolicy {...props} page="Privacy Policy" />
                )}
              />

              {/* CREATE */}
              <Route
                exact
                path="/create/dashboard"
                render={(props) => (
                  <div style={{ display: "flex", height: "100%" }}>
                    <CreateMenu />
                    <TempSignInPage
                      {...props}
                      page="create"
                      component={CreateDashboardPage}
                    />
                  </div>
                )}
              />
              {/* <Route
                exact
                path="/create/drafts"
                render={(props) => (
                  <div style={{ display: "flex", height: "100%" }}>
                    <CreateMenu />
                    <TempSignInPage
                      {...props}
                      page="create"
                      component={CreateDraftsPage}
                    />
                  </div>
                )}
              /> */}
              <Route
                exact
                path="/create/video"
                render={(props) => (
                  <div style={{ display: "flex", height: "100%" }}>
                    <CreateMenu />
                    <TempSignInPage
                      {...props}
                      page="create"
                      component={CreateVideoPage}
                    />
                  </div>
                )}
              />
              {/* <Route
                exact
                path="/create/uploaded-videos"
                render={(props) => (
                  <div style={{ display: "flex", height: "100%" }}>
                    <CreateMenu />
                    <TempSignInPage
                      {...props}
                      page="create"
                      component={UploadedVideoPage}
                    />
                  </div>
                )}
              /> */}

              <Route
                exact
                path="/create/analytics"
                render={(props) => (
                  <div style={{ display: "flex", height: "100%" }}>
                    <CreateMenu />
                    <TempSignInPage
                      {...props}
                      page="create"
                      component={CreateAnalyticsPage}
                    />
                  </div>
                )}
              />

              <Route
                exact
                path="/create/monetisation"
                render={(props) => (
                  <div style={{ display: "flex", height: "100%" }}>
                    <CreateMenu />
                    <TempSignInPage
                      {...props}
                      page="create"
                      component={MonetisationPage}
                    />
                  </div>
                )}
              />

              <Route exact path="/live" render={(props) => <ComingSoon />} />
              <Route render={() => <Redirect to="/" />} />
            </Switch>
          </Nav>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
