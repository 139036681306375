import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  makeStyles,
  Typography,
  Button,
  StepLabel,
  Step,
  Stepper,
} from "@material-ui/core";
import { Box } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";

import UploadForm from "./UploadForm";
import {
  setVisibilitySubmit,
  setDetails,
  setMonetisation,
} from "../../redux/actions/upload";
import ImagePicker from "../ImagePicker";
import { useHistory } from "react-router-dom";
import TextField from "@atlaskit/textfield"; // Assuming default export is used
import { TextFieldColors } from "@atlaskit/textfield";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    // width: "500px",
    "& .MuiStepper-root": {
      backgroundColor: "#1D1D1D",
    },
    "& .MuiStepIcon-active": {
      color: "#F9B715",
    },
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  ctaButtons: {
    display: "flex",
    justifyContent: "space-around",
  },
  videoPreviewWrapper: {
    maxHeight: 200,
    maxWidth: 280,
  },
  videoPreviewInfo: {
    //backgroundColor: "black",
    padding: "10px",
    marginTop: "-5px",
    borderRadius: "0px 0px 10px 10px",
  },
  videoPreviewInfoTitle: {
    fontWeight: 600,
    display: "flex",
    justifyContent: "center",
  },
}));

function getSteps() {
  return ["Details", "Monetisation", "Visiblity"];
}

export default function HorizontalLinearStepper({
  selectedVideo,
  setSelectedVideo,
  uploadProgress,
}) {
  const classes = useStyles();
  const formRef = useRef();
  const [activeStep, setActiveStep] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();

  const isLoading = useSelector(({ upload }) => upload.isLoading);
  const steps = getSteps();

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setUploadProgress((prevProgress) =>
  //       prevProgress >= 100 ? 0 : prevProgress + 1
  //     );
  //   }, 100);
  //   return () => clearInterval(interval);
  // }, []);

  const handleNext = () => {
    formRef.current.submitForm();
    if (!Object.keys(formRef.current.errors).length) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleFinish = () => {
    formRef.current.submitForm();
    history.push("/create/dashboard");
  };

  const submitInfo = ({ visibility }) => {
    dispatch(setVisibilitySubmit(visibility));
  };

  const setMonetisationRx = (monetisation) => {
    dispatch(setMonetisation(monetisation));
  };

  const setDetailsRx = (details) => {
    dispatch(setDetails(details));
  };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };
  const handleBack = () => {
    if (activeStep === 0) {
      setSelectedVideo(null); // clear the selected video
      setActiveStep(0); // reset active step to 0
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const videoPreviewUrl = selectedVideo
    ? URL.createObjectURL(selectedVideo)
    : null;

  return (
    <div className={classes.wrapper}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Stepper
          style={{ width: "100%" }}
          activeStep={activeStep}
          alternativeLabel
        >
          {steps.map((label) => {
            const stepProps = {};
            const labelProps = {};

            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>

      <div
        style={{
          display: "flex",
          width: "100%",
          maxWidth: "800px",
          margin: "0 auto",
        }}
      >
        <div style={{ flexGrow: 1, width: "600px", height: "250px" }}>
          <div className={classes.videoPreviewWrapper}>
            {videoPreviewUrl ? (
              <div style={{ display: "flex", margin: "auto" }}>
                <div style={{ flex: 1, minWidth: "300px" }}>
                  <video
                    className={classes.videoPreview}
                    src={videoPreviewUrl}
                    controls={true}
                    width={280}
                    height={200}
                  />
                </div>

                <div
                  style={{
                    flex: 1,
                    minWidth: "300px",
                    borderRadius: "20px",
                    margin: "auto",
                    backgroundColor: "black",
                    borderRadius: "20px",
                  }}
                >
                  <div
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      width: "100%",
                      padding: "20px",

                      borderRadius: "10px 10px 0px 0px",
                    }}
                  >
                    <Box position="relative" display="inline-flex">
                      <CircularProgress
                        variant="determinate"
                        value={uploadProgress}
                        size={50}
                        thickness={3.6}
                      />
                      <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography
                          variant="caption"
                          component="div"
                          color="white"
                        >
                          {`${Math.round(uploadProgress)}%`}
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                  <div className={classes.videoPreviewInfo}>
                    <div className={classes.videoPreviewInfoTitle}>
                      Upload Progress
                    </div>
                  </div>
                </div>
                <br />
              </div>
            ) : (
              <div className={classes.videoPlaceholder}>No video selected</div>
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          maxWidth: "800px",
          margin: "0 auto",
        }}
      >
        <div style={{ flexGrow: 3, maxWidth: "800px" }}>
          {activeStep === steps.length - 1 ? (
            <div>
              <Typography className={classes.instructions}>
                {
                  <UploadForm
                    type="visibility"
                    formRef={formRef}
                    onSubmit={submitInfo}
                  />
                }
              </Typography>
              <div>
                <Button
                  //disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.button}
                >
                  Back
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleFinish}
                  className={classes.button}
                >
                  Finish
                </Button>
              </div>
            </div>
          ) : null}

          {activeStep === 0 ? (
            <div>
              <div className={classes.content}>
                <UploadForm
                  type="details"
                  formRef={formRef}
                  onSubmit={setDetailsRx}
                />

                <br />
                <div className="atlassian-label-dark">
                  Select Thumbnail <div className="required">*</div>
                </div>
                <span style={{ margin: "-20px" }}>
                  <ImagePicker type="Thumbnail" />
                </span>
              </div>
              <br />
              <div className={classes.ctaButtons}>
                <Button
                  //disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.button}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  disabled={isLoading}
                  className={classes.button}
                >
                  Next
                </Button>
              </div>
            </div>
          ) : null}

          {activeStep === 1 ? (
            <div>
              <div className={classes.content}>
                <UploadForm
                  type="monetisation"
                  formRef={formRef}
                  onSubmit={setMonetisationRx}
                />
              </div>
              <br />
              <br />
              <div className={classes.ctaButtons}>
                <Button
                  //disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.button}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  disabled={isLoading}
                  className={classes.button}
                >
                  Next
                </Button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
