import {
  faCirclePlay as faCirclePlaySolid,
  faHeart as faHeartSolid,
  faRectangleHistory as faRectangleHistorySolid,
  faSquarePlus as faSquarePlusSolid,
} from "@fortawesome/pro-solid-svg-icons";

import {
  faSquarePlus as faSquarePlusDuotone,
  faCirclePlay as faCirclePlayDuotone,
  faHeart as faHeartDuotone,
  faRectangleHistory as faRectangleHistoryDuotone,
  faGears as faGearsDuotone,
} from "@fortawesome/pro-duotone-svg-icons";

const icons = [
  {
    title: "Create", // Changes the name of the sidebar
    description: "Create or upload content", //Changes the title in the auth page
    signInMsg: "Sign up to start creating!", // Changes the description in the auth page under the title
    icon: faSquarePlusDuotone,
    activeIcon: faSquarePlusSolid,
    path: "/create/video",
  },

  {
    title: "Subscriptions",
    description: "Don't miss new videos",
    signInMsg: "Sign in to see updates from your favorite channels",
    icon: faCirclePlayDuotone,
    activeIcon: faCirclePlaySolid,
    path: "/subscriptions",
  },
  {
    title: "Liked",
    description: "Enjoy your favorite videos",
    signInMsg: "Sign in to access videos that you’ve liked or saved",
    path: "/liked",
    icon: faHeartDuotone,
    activeIcon: faHeartSolid,
  },
  {
    title: "History",
    description: "Keep track of what you watch",
    signInMsg: "Watch history isn't viewable when signed out.",
    path: "/history",
    icon: faRectangleHistoryDuotone,
    activeIcon: faRectangleHistorySolid,
  },
  {
    title: "Settings",
    description: "Adjust settings",
    signInMsg: "Settings isn't viewable when signed out.",
    path: "/settings/account",
    icon: faGearsDuotone,
    activeIcon: faGearsDuotone,
  },
];

export default icons;
