import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Toolbar, IconButton, Hidden, Tooltip } from "@material-ui/core";
import {
  Search as SearchIcon,
  Notifications as NotificationsIcon,
} from "@material-ui/icons";
import { setMobileSearch } from "../../../redux/actions/layout";
import SignInBtn from "../../SignInBtn";
import NavUserMenuBtn from "./NavUserMenuBtn";
import NavVidMenuBtn from "./NavVidMenuBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingLeft: "0px",
  },
  iconButton: {
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: theme.palette.iconBg,
    },
    "&:focus": {
      outline: theme.palette.iconBg,
    },
  },
  discordBtn: {
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: "#fff2",
    },
    "&:focus": {
      outline: theme.palette.iconBg,
    },
    marginLeft: "12px",
    minHeight: "35px",
    borderRadius: 99999,
    backgroundColor: "#fff3",
    paddingLeft: "7px",
    paddingRight: "7px",
    paddingBottom: "7px",
    paddingTop: "7px",
    fontSize: "12px",
  },
  discord: {
    fontSize: "18px",
  },
}));

const NavBar = ({ changeTheme, isDarkTheme }) => {
  const dispatch = useDispatch();
  const isAuth = useSelector(({ channel }) => channel.isAuth);
  const classes = useStyles();
  const theme = useTheme();
  const anchorRef = useRef(null);

  return (
    <Toolbar
      classes={{
        gutters: classes.toolbar,
      }}
    >
      <>
        <Hidden smUp>
          <IconButton
            onClick={() => dispatch(setMobileSearch(true))}
            size={theme.breakpoints.up("md") ? "small" : "medium"}
            className={classes.iconButton}
          >
            <SearchIcon />
          </IconButton>
        </Hidden>
        {isAuth && (
          <Tooltip title="Create">
            <NavVidMenuBtn />
          </Tooltip>
        )}
        {/* {isAuth && (
          <Tooltip title="Notifications">
            <IconButton className={classes.iconButton}>
              <NotificationsIcon />
            </IconButton>
          </Tooltip>
        )} */}
        {isAuth && (
          <>
            <NavUserMenuBtn
              changeTheme={changeTheme}
              isDarkTheme={isDarkTheme}
            />
            <IconButton
              onClick={() => {
                window.open("https://discord.gg/KvMduk7KKB", "_blank");
              }}
              ref={anchorRef}
              className={classes.discordBtn}
            >
              <FontAwesomeIcon
                className={classes.discord}
                icon={faDiscord}
                size="small"
              />
            </IconButton>
          </>
        )}
        {isAuth || (
          <SignInBtn size={theme.breakpoints.up("md") ? "medium" : "large"} />
        )}
      </>
    </Toolbar>
  );
};

export default NavBar;
