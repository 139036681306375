import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { makeStyles, Typography, Button } from "@material-ui/core";
import NavUserMenuBtn from "../../components/Nav/TopNav/NavUserMenuBtn";
import coreumImg from "../../assets/image/Coreum.png";
import ConnectAccountBtn from "../Buttons/ConnectAccountBtn";
import CloseAndOptionsBtn from "../Buttons/CloseAndOptionsBtn";
import { CircularProgress } from "@material-ui/core";
import { Buffer } from "buffer";
import { withdrawCoreum } from "../../action/creatorPayoutsAction";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Connection } from "@solana/web3.js";

// Assign the `Buffer` object from the `buffer` library
window.Buffer = Buffer;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  button: {
    alignSelf: "center",
    fontSize: "12px",
    marginLeft: "5px",
    marginRight: "5px",
    backgroundColor: "rgb(0, 119, 247, 0.2)",
    color: "rgb(0, 119, 247)",
    "&:hover": {
      color: "white",
      backgroundColor: "rgb(0, 119, 247) !important",
    },
  },

  paymenMethodMenu: {
    "&.hide": {
      transition: "opacity 0.4s ease-in-out",
      display: "none",
    },
  },
  cashoutMethodMenuGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "10px",
  },
  cashoutAmountMenuGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "10px",
  },
  cashoutAmount: {
    "&.hide": {
      transition: "opacity 0.4s ease-in-out",
      display: "none",
    },
  },
  cashoutAmountItem: {
    backgroundColor: "#fff3",
    padding: "10px",
    textAlign: "center",
    transition: "opacity 0.4s ease-in-out",
    borderRadius: "10px",
    fontWeight: "700",
    "&:hover": {
      backgroundColor: "#303030",
    },
  },
}));

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "#121212",
  border: "1px solid #262626",
  boxShadow: 24,
  minHeight: "250px",
  p: 4,
  borderRadius: "15px",
  padding: "10px",
};

const CreatorPayoutModal = ({
  balance,
  isOpen,
  onClose,
  onSuccess,
  onFailure,
}) => {
  const classes = useStyles();
  const isAuth = useSelector(({ channel }) => channel.isAuth);

  const [cashoutMethodMenu, setCashoutMethodMenu] = useState(true);
  const [cashoutAmountMenu, setCashoutAmountMenu] = useState(false);
  const [cashoutSummaryMenu, setCashoutSummaryMenu] = useState(false);
  const [cashoutStep, setCashoutStep] = useState(false);

  const [modalTitle, setModalTitle] = useState("Request payout");
  const [selectedCashoutMethod, setSelectedCashoutMethod] = useState("");
  const [selectedCashoutAmount, setSelectedCashoutAmount] = useState("");

  const [optionsSpanHover, setOptionsSpanHover] = useState(false);

  const channelId = useSelector(({ channel }) => channel.id);

  // const [xummSignIn, setXummSignIn] = useState(false);
  const [xummQrExposed, setXummQrExposed] = useState(false);
  const [xummQrCode, setXummQrCode] = useState("");
  const [xummUpdateMsg, setXummUpdateMsg] = useState("");

  // const [web3, setWeb3] = useState(null);

  function setMenu(menu) {
    switch (menu) {
      case "cashoutMethod":
        setCashoutMethodMenu(true);
        setCashoutAmountMenu(false);
        setCashoutSummaryMenu(false);
        break;
      case "cashoutAmount":
        setCashoutMethodMenu(false);
        setCashoutAmountMenu(true);
        setCashoutSummaryMenu(false);
        break;
      case "cashoutSummary":
        setCashoutMethodMenu(false);
        setCashoutAmountMenu(false);
        setCashoutSummaryMenu(true);
    }
  }

  function resetModal() {
    setMenu("cashoutMethod");
    setSelectedCashoutMethod("");
    setSelectedCashoutAmount("");
    setModalTitle("Request payout");
    setCashoutStep(false);
    setXummUpdateMsg("");
  }

  function selectCoreumCashoutMethod() {
    setMenu("cashoutAmount");
    setSelectedCashoutMethod("COREUM");
    setModalTitle("Select Amount");
  }

  function selectCashoutAmount(amount) {
    setMenu("cashoutSummary");
    setSelectedCashoutAmount(amount);
    setModalTitle("Cashout Summary");
  }

  function handleBuyNow() {
    setCashoutSummaryMenu(false);

    switch (selectedCashoutMethod) {
      case "COREUM":
        CoreumCashout();
        break;
    }
  }

  const CoreumCashout = async () => {
    try {
      setCashoutStep(true);
      let response = await withdrawCoreum(selectedCashoutAmount);
      HandleCashoutSuccess(response.message);
      onSuccess();
    } catch (error) {
      HandleCashoutFailure(error.message);
      //onFailure();
    }
  };

  function HandleCashoutSuccess(message) {
    toast.info(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "dark",
      style: {
        backgroundColor: "#1B1B1B", // Replace with your desired background color
      },
    });

    //Handle Success
    //onSuccess();
    resetModal();
    onClose();
  }

  function HandleCashoutFailure(message) {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "dark",
      style: {
        backgroundColor: "#1B1B1B", // Replace with your desired background color
      },
    });

    //Handle failure
    //onFailure();
    resetModal();
    onClose();
  }

  return (
    <Modal
      open={isOpen}
      onBackdropClick={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <div
          className={classes.optionsSpan}
          onMouseEnter={() => setOptionsSpanHover(true)}
          onMouseLeave={() => setOptionsSpanHover(false)}
        >
          <CloseAndOptionsBtn onClose={onClose} onOption1={resetModal} />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography
            variant="h5"
            style={{
              fontWeight: "600",
              padding: "10px",
            }}
          >
            {modalTitle}
          </Typography>
        </div>
        <div
          style={{ padding: "10px", display: "flex", justifyContent: "center" }}
        >
          {isAuth && <NavUserMenuBtn />}{" "}
        </div>

        <div
          className={`${classes.paymenMethodMenu} ${
            cashoutMethodMenu ? "" : "hide"
          }`}
        >
          <div>
            <Typography
              variant="h7"
              style={{
                fontWeight: "800",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span style={{ opacity: 0.7 }}>Balance:</span>
              &nbsp;
              {"$"}
              {balance}
            </Typography>
            <hr style={{ backgroundColor: "#262626" }} />
            <Typography
              variant="h7"
              style={{
                fontWeight: "600",
                //paddingTop: "20px",
              }}
            >
              Select cashout method:
            </Typography>

            <div className={classes.cashoutMethodMenuGrid}>
              <ConnectAccountBtn
                type={"web3"}
                img={coreumImg}
                label={"Coreum"}
                onClick={selectCoreumCashoutMethod}
              />
            </div>
          </div>
        </div>
        <div
          className={`${classes.cashoutAmount} ${
            cashoutAmountMenu ? "" : "hide"
          }`}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Typography
              variant="h7"
              style={{
                fontWeight: "800",
                paddingTop: "10px",
              }}
            >
              <span style={{ opacity: 0.7 }}>Selected cashout method: </span>
              {selectedCashoutMethod}
            </Typography>
          </div>
          <hr style={{ backgroundColor: "#262626" }} />

          <Typography
            variant="h7"
            style={{
              fontWeight: "600",
            }}
          >
            Select amount (USD):
          </Typography>
          <br />
          <br />
          <div className={classes.cashoutAmountMenuGrid}>
            <div
              className={classes.cashoutAmountItem}
              onClick={() => selectCashoutAmount(10)}
            >
              $10
            </div>
            <div
              className={classes.cashoutAmountItem}
              onClick={() => selectCashoutAmount(20)}
            >
              $20
            </div>
            <div
              className={classes.cashoutAmountItem}
              onClick={() => selectCashoutAmount(50)}
            >
              $50
            </div>
            <div
              className={classes.cashoutAmountItem}
              onClick={() => selectCashoutAmount(100)}
            >
              $100
            </div>
          </div>
        </div>
        <div
          className={`${classes.cashoutAmount} ${
            cashoutSummaryMenu ? "" : "hide"
          }`}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Typography
              variant="h7"
              style={{
                fontWeight: "800",
                paddingTop: "10px",
              }}
            >
              <span style={{ opacity: 0.7 }}>Cashout method: </span>
              {selectedCashoutMethod}
            </Typography>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Typography
              variant="h7"
              style={{
                fontWeight: "800",
                paddingTop: "10px",
              }}
            >
              <span style={{ opacity: 0.7 }}>Cashout amount (USD): </span>$
              {selectedCashoutAmount}
              {".00"}
            </Typography>
          </div>
          <br />
          <div style={{ justifyContent: "center", display: "flex" }}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={handleBuyNow}
              style={{ width: "300px" }}
            >
              Cashout now!
            </Button>
          </div>
        </div>
        <div
          className={`${classes.cashoutAmount} ${cashoutStep ? "" : "hide"}`}
        >
          <CircularProgress size={15} thickness={3.6} disableShrink />
          &nbsp; Please wait as we process your payout!
        </div>
      </Box>
    </Modal>
  );
};

export { CreatorPayoutModal };
