import React, { useState, useEffect, useRef } from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { api } from "../../utils/api";
import {
  makeStyles,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core/";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme) => ({
  swiperSlideVideo: {
    width: "100%",
    zIndex: 1,
    "&:hover": {
      transition: "250ms all ease-in-out 0.4s",
      zIndex: 2,
      transform: "scale(1.2)",
    },
  },
  swiperSlideChannel: {
    height: 400,
    width: "100%",
    zIndex: 1,
    "&:hover": {
      transition: "250ms all ease-in-out 0.4s",
      zIndex: 2,
      transform: "scale(1.1)",
    },
  },
  swiperVideoImg1: {
    width: "100%",
    maxWidth: 300,
    maxHeight: "300px",
    objectFit: "cover",
    marginBottom: "10px",
    marginTop: "20px",
    borderRadius: "5px",
    height: "175px",
  },
  swiperChannelImg1: {
    width: "100%",
    height: "100%",
    maxWidth: 300,
    maxHeight: "400px",
    objectFit: "cover",
    borderRadius: "10px",
  },
  text: {
    paddingBottom: theme.spacing(3),
    fontWeight: 700,
  },
  loadingTile: {
    borderRadius: 10,
    width: "100%",
  },
  loadingGrid: {
    margin: "20px",
  },
  videoRank: {
    position: "absolute",
    bottom: "20px",
    right: "20px",
    background: "#0009",
    fontWeight: 800,
    borderRadius: "20px",
    padding: "2px 8px",
    color: "white !important",
  },
  channelInfo: {
    position: "absolute",
    bottom: "20px",
    right: "20px",
    left: "20px",
    background: "#0009",
    height: "40px",
    borderRadius: "10px",
    padding: "15px 10px",
    color: "white !important",
  },
  channelInfoTitle: {
    fontSize: "16px",
    fontWeight: 800,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  channelInfoDescription: {
    fontSize: "14px",
    fontWeight: 500,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  titleHeader: {
    display: "flex",
  },
  exploreAllOuter: {
    display: "flex",
    textDecoration: "none !important",
  },
  exploreAllBtn: {
    color: "#FAB810",
    zIndex: 1,
    opacity: 0,
    transition: "opacity 0.1s ease",
    marginTop: "auto",
    marginBottom: "auto",
    position: "absolute",
    padding: "7px 10px",
    fontWeight: 700,
    textDecoration: "none !important",
  },
  arrowContainer: {
    fontSize: "20px",
    fontWeight: 800,
    zIndex: 1,
    transform: "scale(1,1.4)",
    padding: "0px 10px",
    color: "#FAB810",
    transition: "transform 0.3s ease",
    textDecoration: "none !important",
  },
  containerHover: {
    opacity: "1 !important",
  },
  arrowContainerHover: {
    transform: "translateX(90px)",
    textDecoration: "none !important",
  },
  "@keyframes fadeDown": {
    "0%": {
      transform: "translateY(0)",
      opacity: 1,
    },
    "100%": {
      transform: "translateY(30px)",
      opacity: 0,
    },
  },
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  fadeDown: {
    animation: "$fadeDown 0.5s ease-in-out forwards",
  },
  fadeIn: {
    animation: "fadeIn 0.5s ease-in-out forwards",
  },
  channelLink: {
    "&:hover $channelInfo": {
      animation: "$fadeDown 0.5s ease-in-out forwards",
    },
    "&:hover $viewChannelBtn": {
      animation: "$fadeIn 0.5s ease-in-out forwards",
    },
  },
  viewChannelBtn: {
    opacity: 0,
    position: "absolute",
    bottom: "20px",
    right: "20px",
    left: "20px",
    background: "#000",
    fontWeight: 700,
    borderRadius: "20px",
    padding: "10px",
    color: "white !important",
  },
}));

const SwiperComponent = ({
  title,
  exploreAllLink,
  featureType,
  swiperType,
  genre,
  limit,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const [isLoading, setIsLoading] = useState(true);
  const containerRef = useRef(null);
  const [isHoveringExploreAll, setIsHoveringExploreAll] = useState(false);
  const [isHoveringFeaturedChannel, setIsHoveringFeaturedChannel] =
    useState(false);
  const [content, setContent] = useState({});

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleMouseEnter = () => {
    setIsHoveringExploreAll(true);
  };

  const handleMouseLeave = () => {
    setIsHoveringExploreAll(false);
  };

  const handleFeaturedChannelMouseEnter = () => {
    setIsHoveringFeaturedChannel(true);
  };
  const handleFeaturedChannelMouseLeave = () => {
    setIsHoveringFeaturedChannel(false);
  };

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  const getSlidesPerView = () => {
    if (screenWidth > 1350) {
      return 5;
    } else if (screenWidth > 1000) {
      return 4;
    } else if (screenWidth > 750) {
      return 3;
    } else if (screenWidth > 550) {
      return 2;
    } else {
      return 1;
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchFeaturedVideos = async () => {
      try {
        const { data } = await api.get(
          `/api/videos/featured/${featureType}/${genre}/${limit}`
        );

        const featuredVideos = data.featuredVideos;
        setContent(
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={10}
            slidesPerView={getSlidesPerView()}
            navigation
            loop={true}
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            onSlideChange={() => console.log("slide change")}
          >
            {featuredVideos.map((video) => (
              <SwiperSlide className={classes.swiperSlideVideo} key={video._id}>
                <a
                  href={`/watch?v=${video.video._id}`}
                  style={{ color: "white !important" }}
                >
                  <div className={classes.videoRank}>{"#" + video.rank}</div>
                  <img
                    className={classes.swiperVideoImg1}
                    src={video.thumbnail}
                    alt="thumbnail"
                  ></img>
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
        );
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchRecommendedVideos = async () => {
      try {
        const { data } = await api.get(
          `/api/videos/featured/${featureType}/${genre}/${limit}`
        );

        const featuredVideos = data.featuredVideos.reverse();
        setContent(
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={10}
            slidesPerView={getSlidesPerView()}
            navigation
            loop={true}
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            onSlideChange={() => console.log("slide change")}
          >
            {featuredVideos.map((video) => (
              <SwiperSlide className={classes.swiperSlideVideo} key={video._id}>
                <a
                  href={`/watch?v=${video.video._id}`}
                  style={{ color: "white !important" }}
                >
                  <div className={classes.videoRank}>{"#" + video.rank}</div>
                  <img
                    className={classes.swiperVideoImg1}
                    src={video.thumbnail}
                    alt="thumbnail"
                  ></img>
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
        );
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchPremiumVideos = async () => {
      try {
        const { data } = await api.get(
          `/api/videos/featured/${featureType}/${genre}/${limit}`
        );

        const featuredVideos = data.featuredVideos;
        setContent(
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={10}
            slidesPerView={getSlidesPerView()}
            navigation
            loop={true}
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            onSlideChange={() => console.log("slide change")}
          >
            {featuredVideos.map((video) => (
              <SwiperSlide className={classes.swiperSlideVideo} key={video._id}>
                <a
                  href={`/watch?v=${video.video._id}`}
                  style={{ color: "white !important" }}
                >
                  <div className={classes.videoRank}>{"#" + video.rank}</div>
                  <img
                    className={classes.swiperVideoImg1}
                    src={video.thumbnail}
                    alt="thumbnail"
                  ></img>
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
        );
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchFeaturedChannels = async () => {
      try {
        const { data } = await api.get(
          `/api/channels/featured/${featureType}/${genre}/${limit}`
        );
        const featuredChannels = data.featuredChannels;
        setContent(
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={10}
            slidesPerView={getSlidesPerView()}
            navigation
            loop={true}
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            style={{ padding: "25px" }}
            onSlideChange={() => console.log("slide change")}
          >
            {featuredChannels.map((channel) => (
              <SwiperSlide
                onMouseEnter={handleFeaturedChannelMouseEnter}
                onMouseLeave={handleFeaturedChannelMouseLeave}
                className={classes.swiperSlideChannel}
                key={channel._id}
              >
                <a
                  className={classes.channelLink}
                  href={`/channel/${channel.channel._id}`}
                  style={{ color: "white !important" }}
                >
                  <div className={classes.viewChannelBtn}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <span>{"View channel"}</span>
                    </div>
                  </div>
                  <div
                    className={`${classes.channelInfo} ${
                      isHoveringFeaturedChannel ? classes.fadeDown : ""
                    }`}
                  >
                    <div className={classes.channelInfoTitle}>
                      {channel.title}
                    </div>
                    <div className={classes.channelInfoDescription}>
                      {channel.description}
                    </div>
                  </div>

                  <img
                    className={classes.swiperChannelImg1}
                    src={channel.thumbnail}
                    alt="thumbnail"
                  ></img>
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
        );
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchContinueWatching = async () => {};

    switch (swiperType) {
      case "video":
        fetchFeaturedVideos();
        break;
      case "channel":
        fetchFeaturedChannels();
        break;
      case "continueWatching":
        fetchContinueWatching();
        break;
      case "recommendedVideos":
        fetchRecommendedVideos();
        break;
      case "premiumVideos":
        fetchPremiumVideos();
      default:
        break;
    }
  }, [featureType, genre, limit, screenWidth]);

  return (
    <Container maxWidth="xl">
      <div className={classes.titleHeader}>
        <Typography
          variant="h5"
          className={classes.text}
          style={{ paddingBottom: 0, position: "relative" }}
        >
          {title}
        </Typography>
        {exploreAllLink ? (
          <a
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            href={exploreAllLink}
            className={classes.exploreAllOuter}
          >
            <div
              className={`${classes.arrowContainer} ${
                isHoveringExploreAll ? classes.arrowContainerHover : ""
              }`}
            >
              &#8227;
            </div>
            <div
              className={`${classes.exploreAllBtn} ${
                isHoveringExploreAll ? classes.containerHover : ""
              }`}
            >
              Explore More
            </div>
          </a>
        ) : null}
      </div>
      {isLoading ? (
        <div className={classes.loadingGrid} ref={containerRef}>
          <Grid container spacing={1}>
            {Array.from({ length: 5 }).map((_, index) => (
              <Grid item xs={2} md={2} key={index}>
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  variant="rectangular"
                  height={118}
                  className={classes.loadingTile}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      ) : (
        content
      )}
    </Container>
  );
};

export default SwiperComponent;
