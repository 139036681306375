import React, { useEffect, useState } from "react";
import { grey } from "@material-ui/core/colors";
import {
  Avatar,
  makeStyles,
  Box,
  Typography,
  Tab,
  Tabs,
} from "@material-ui/core";
import UploadModal from "../../Upload/UploadModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill } from "@fortawesome/pro-duotone-svg-icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "60px",
    width: "100%",
  },
}));

const Monetisation = () => {
  const classes = useStyles();

  useEffect(() => {});

  return (
    <div className={classes.root}>
      <div style={{ maxWidth: "700px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "80px",
            padding: "10px",
          }}
        >
          <FontAwesomeIcon icon={faMoneyBill} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <h2>Monetisation Coming Soon!</h2>
        </div>
        <br />
        <p>
          You will soon be able to see exactly how much each video has made and
          how users are paying for your content. You can currently see
          transactions made for videos in the transactions page.
        </p>
      </div>
    </div>
  );
};

export default Monetisation;
