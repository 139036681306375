import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, Container, Typography } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import clsx from "clsx";
import { faArrowRightToArc as faArrowRightToArcDuotone } from "@fortawesome/pro-duotone-svg-icons";
import SignInBtn from "../SignInBtn";
import menuAuthIcons from "../menuAuthIcons";
import ComingSoonPage from "./ComingSoonPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { auth } from "../../redux/actions/channel";

const comingSoon = ["likedVideos", "myVideos", "live", "watchLater"];
const authPages = [
  "subscriptions",
  "Settings",
  "Create",
  "Liked",
  "history",
  "charactergpt",
];

const useStyles = makeStyles((theme) => ({
  root_1: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  root_2: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  SignInIcon: {
    paddingBottom: "20px",
  },
  icon: { fontSize: "7em", color: grey[500], paddingBottom: theme.spacing(2) },
  text: {
    paddingBottom: theme.spacing(2),
  },
}));

const TempSignInPage = ({ page, component: Component }) => {
  const dispatch = useDispatch();
  dispatch(auth());

  const isAuth = useSelector(({ channel }) => channel.isAuth);
  let authIcon;

  if (page === null) {
    authIcon = {
      icon: faArrowRightToArcDuotone,
      description: "Sign In required",
      signInMsg: "Please sign in to view this page.",
    };
  } else {
    authIcon = menuAuthIcons.filter(
      ({ title }) => title.toLowerCase() === page
    )[0];
  }

  const Icon = authIcon.icon;
  const classes = useStyles();

  return (
    <>
      {(() => {
        if (!isAuth) {
          //UNAUTHORISED USERS -> TEMP SIGN IN PAGE
          return (
            <Container
              className={clsx({
                [classes.root_1]: !isAuth || !page || comingSoon.includes(page),
                [classes.root_2]: authPages.includes(page),
              })}
            >
              <FontAwesomeIcon
                icon={Icon}
                size="4x"
                className={classes.SignInIcon}
              />
              <Typography variant="h4" align="center" className={classes.text}>
                {authIcon.description}
              </Typography>
              <Typography
                variant="subtitle1"
                align="center"
                className={classes.text}
              >
                {authIcon.signInMsg}
              </Typography>
              <SignInBtn size="large" />
            </Container>
          );
        } else if (comingSoon.includes(page)) {
          //COMING SOON PAGES
          return (
            <Container
              className={clsx({
                [classes.root_1]: !isAuth || !page || comingSoon.includes(page),
                [classes.root_2]: authPages.includes(page),
              })}
            >
              <ComingSoonPage />
            </Container>
          );
        } else {
          //RETURNS COMPONENT PASSED FROM ROUTE
          return <Component />;
        }
      })()}
    </>
  );
};

export default TempSignInPage;
