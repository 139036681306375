import React, { useEffect } from "react";
import { makeStyles, Container, Typography } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux";
import { Box, CircularProgress } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({

    xummSignInBody: {
        display: 'none',
        opacity: 0,
        color: 'white !important',
        maxHeight: '0px',

        "&.show": {
            display: 'block',
            transition: 'opacity 1s ease-in-out',
            opacity: 1,
            maxHeight: 'fit-content',
        }
    },
    signInModalParagraph: {
        transition: 'all 0.2s ease-in-out 0s',
        fontWeight: 400,
        lineHeight: '140%',
        color: 'rgb(142, 142, 147)',
        fontSize: '0.875rem',
        padding: '0.2rem',
        textAlign: 'center',
    },
    qrCodeWrapper: {
        margin: "25px 0",
        display: "flex",
        justifyContent: "center",
        background: '#404040',
        padding: '40px',
        borderRadius: '20px',
    }

}));

const displayQrCode = (qrCode) => {
    if (qrCode) {
        return (
            <img src={qrCode} width="200" height="200" />
        );
    } else {
        return (
            <CircularProgress color="primary" />
        );
    }
}



const ConnectXumm = ({ show, qrExposed, qrCode, updateMsg }) => {
    const dispatch = useDispatch();

    const classes = useStyles();
    useEffect(() => {

    }, []);

    return (
        <div className={`${classes.xummSignInBody} ${show ? "show" : ""}`}>
            <div className={classes.signInModalParagraph}>
                <span style={{ fontWeight: "600", fontSize: "16px" }}>XUMM</span>
            </div>
            <Box className={classes.qrCodeWrapper}>
                {qrExposed &&
                    displayQrCode(qrCode)
                }
            </Box>
            <div className={classes.signInModalParagraph}>
                <span style={{ fontWeight: "600", fontSize: "16px" }}>{updateMsg}</span>
            </div>
        </div>
    );
};

export default ConnectXumm;
