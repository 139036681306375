import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TabPanel from "../TabPanel";
import { Box, Tab, Tabs } from "@material-ui/core";
import VideoList from "../Video/VideoList";

export default function SecondaryVidContentTabs() {
  const [tabValue, setTabValue] = useState(0);
  const recommendedVids = useSelector(({ videos }) => videos.recommended);
  const isLoading = useSelector(({ videos }) => videos.isLoading);

  const handleChange = (e, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Recommended Videos" {...a11yProps(0)} />
          <Tab label="Related Creators" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <VideoList
          type="horizontal_2"
          isLoading={isLoading}
          videos={recommendedVids}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <p>Related Creators</p>
      </TabPanel>
    </Box>
  );
}
