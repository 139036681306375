import React, { useEffect, useState } from "react";
import { makeStyles, Button, Typography } from "@material-ui/core";
import "react-day-picker/dist/style.css";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import { API } from "../../config/config";

// import TextField from "../Inputs/TextField";

const useStyles = makeStyles((theme) => ({
  completeProfileBody: {
    display: "block",
    opacity: 0,
    color: "white !important",
    maxHeight: "0px",
    display: "none",

    "&.show": {
      display: "block",
      transition: "opacity 1s ease-in-out",
      opacity: 1,
      maxHeight: "fit-content",
    },
  },
  signInModalParagraph: {
    transition: "all 0.2s ease-in-out 0s",
    fontWeight: 400,
    lineHeight: "140%",
    color: "rgb(142, 142, 147)",
    fontSize: "0.875rem",
    padding: "0.2rem",
    textAlign: "center",
  },
  inputField: {
    backgroundColor: "lightgrey",
    border: "0px",
    borderRadius: "10px",
    padding: "10px",
    width: "100%",
    zIndex: 1000,
  },
  inputLabel: {
    padding: "10px",
    width: "100%",
  },
  datePicker: {
    width: "100%",
    "& .MuiTextField-root": {
      width: "100%",
    },
    "& .MuiInputBase-input": {
      color: "white",
    },
    "& .MuiOutlinedInput-root": {
      width: "100%",
    },
    "& .MuiSvgIcon-root": {
      color: "white",
    },
  },
  button: {
    alignSelf: "center",
    width: "100%",
    backgroundColor: "rgb(0, 119, 247, 0.2)",
    color: "rgb(0, 119, 247)",
    "&:hover": {
      color: "white",
      backgroundColor: "rgb(0, 119, 247) !important",
    },
  },
  textField: {
    width: "100%",
    "& .MuiInputBase-formControl": {
      backgroundColor: "#1c1c1c !important",
      borderRadius: "12px",
      "&:hover fieldset": {
        borderColor: "#1c1c1c !important",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#1c1c1c !important",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: 12,
    },
  },
}));

const CompleteProfile = ({ show, name, username }) => {
  const [selected, setSelected] = useState(new Date());
  const [errorMessage, setErrorMessage] = useState("");

  const history = useHistory();

  const [formData, setFormData] = useState({
    username: "",
    name: "",
    email: "",
    dob: null,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${API}/channels/update`, {
        method: "POST",
        credentials: "include", // <-- Include cookies in the request
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        // Handle the response, e.g., show a success message or update the state
        history.push("/");
      } else {
        // Handle errors, e.g., show an error message or update the state
        const data = await response.json();
        setErrorMessage(data.error.message);
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(
        "An error occurred while submitting the form. Please try again later."
      );

      // Handle errors, e.g., show an error message or update the state
    }
  };

  const classes = useStyles();
  useEffect(() => {}, []);

  return (
    <div className={`${classes.completeProfileBody} ${show ? "show" : ""}`}>
      <div className={classes.signInModalParagraph}>
        <span style={{ fontWeight: "600", fontSize: "16px" }}>
          COMPLETE PROFILE
        </span>
      </div>
      {errorMessage && (
        <Typography color="error" variant="body2">
          {errorMessage}
        </Typography>
      )}
      <form onSubmit={handleSubmit}>
        <div>
          <div className={classes.inputLabel}>Username*</div>
          <TextField
            required
            id="username"
            name="username"
            variant="outlined"
            value={formData.username}
            onChange={handleChange}
            className={classes.textField}
          />
        </div>

        <div>
          <div className={classes.inputLabel}>Name*</div>
          <TextField
            required
            id="name"
            name="name"
            variant="outlined"
            value={formData.name}
            onChange={handleChange}
            className={classes.textField}
          />
        </div>

        {/* <div>
          <div className={classes.inputLabel}>Email</div>
          <TextField
            id="email"
            name="email"
            variant="outlined"
            value={formData.email}
            onChange={handleChange}
            className={classes.textField}
          />
        </div> */}

        <div className={classes.inputLabel}>DOB</div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            className={classes.datePicker}
            value={formData.dob}
            onChange={(date) =>
              handleChange({ target: { name: "dob", value: date } })
            }
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <br />
        <br />
        <Button
          type="submit"
          className={classes.button}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </form>
    </div>
  );
};

export default CompleteProfile;
