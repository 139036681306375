import React, { useEffect, useState, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles, TextField, Button } from "@material-ui/core";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { TypeAnimation } from "react-type-animation";
import ReactLoading from "react-loading";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  headerOuter: {
    width: "100%",
    padding: "20px",
    display: "flex",
    borderBottom: "2px solid #1B1B1B",
  },
  characterImage: {
    height: "100px",
    width: "100px",
    borderRadius: "10px",
    objectFit: "cover",
    marginRight: "20px",
  },
  characterName: {
    fontSize: "1.8rem",
    fontWeight: 800,
    marginTop: "auto",
    marginBottom: "auto",
  },
  chatArea: {
    padding: "20px",
    height: "200px",
    overflowY: "scroll",
    flexGrow: 1,
    overflowX: "hidden",
  },
  messageBox: {
    display: "flex",
    alignItems: "center",
    margin: "8px",
  },
  textField: {
    width: "100%",
    margin: "10px",
    "& .MuiInputBase-formControl": {
      backgroundColor: "#1c1c1c !important",
      borderRadius: "12px",
      "&:hover fieldset": {
        borderColor: "#1c1c1c !important",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#1c1c1c !important",
      },
    },
    "& .MuiOutlinedInput-input": {
      // "$:focus": {
      //     margin: "10px",
      // }
    },
  },
  assistantMessage: {
    minWidth: "300px",
    width: "40%",
    backgroundColor: "#2F2F2F",
    float: "left",
    padding: "15px",
    borderRadius: "20px",
  },
  userMessage: {
    minWidth: "300px",
    width: "40%",
    backgroundColor: "blue",
    float: "right",
    padding: "15px",
    borderRadius: "20px",
    background: "linear-gradient(180deg, #0084FF 0%, #0D66BD 100%)",
  },
  messageOuter: {
    width: "100%",
    margin: "10px",
    display: "inline-block",
  },
  sendButton: {
    fontWeight: 600,
    fontFamily: "Basier Square",
    "&:disabled": {
      backgroundColor: "#2a2a2a", // replace with the color you want
      color: "grey", // replace with the color you want
    },
  },
}));

const ChatPage = () => {
  const classes = useStyles();
  const { character, id } = useParams();
  const history = useHistory();
  const [characterData, setCharacterData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [chatId, setChatId] = useState(id || "");
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const chatAreaRef = useRef(null);

  useEffect(() => {
    fetchCharacter();
    generateChatId();
    fetchMessages();
  }, []);

  const fetchCharacter = async () => {
    try {
      const response = await axios.get(`/api/character-gpt/${character}`);
      setCharacterData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching character:", error);
      setLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (message.trim() !== "") {
        handleSendMessage();
      }
    }
  };

  const generateChatId = async () => {
    if (!chatId) {
      const generatedChatId = uuidv4();
      setChatId(generatedChatId);
      // Update the URL with the generated chat ID
      history.replace(`/c/${character}/${generatedChatId}`);

      // Check if the chat already exists
      try {
        // If chat doesn't exist, create it
        const createChatResponse = await axios.post(
          `/api/character-gpt/create-chat`,
          {
            chatId: generatedChatId,
            characterId: character,
          }
        );
        // Add the first message from the create-chat response to the messages array
        const assistantResponse = createChatResponse.data.message;
        const updatedMessages = [
          {
            role: assistantResponse.role,
            content: assistantResponse.content,
          },
        ];
        setMessages(updatedMessages);
      } catch (error) {
        console.error("Error checking chat existence:", error);
      }
    }
  };

  const fetchMessages = async () => {
    try {
      const response = await axios.get(`/api/character-gpt/chat/${chatId}`);
      setMessages(response.data);
      scrollToBottom();
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const handleSendMessage = async () => {
    try {
      setMessage(""); // Clear the message input
      const newMessage = {
        chatId,
        channelId: characterData.channelId,
        characterGPTId: characterData._id,
        role: "user",
        content: message,
      };
      // Add the new message to the list
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      const loadingMessage = {
        chatId,
        channelId: characterData.channelId,
        characterGPTId: characterData._id,
        role: "assistant",
        content: "...",
      };
      setMessages((prevMessages) => [...prevMessages, loadingMessage]);
      await axios.post("/api/character-gpt/chat", newMessage);
      fetchMessages(); // Refresh the messages after sending a new message
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const scrollToBottom = () => {
    chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight;
  };

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <div className={classes.root}>
      <div className={classes.headerOuter}>
        <img
          className={classes.characterImage}
          src={characterData.characterImage}
          alt={characterData.characterName}
        />
        <span className={classes.characterName}>
          {characterData.characterName}
        </span>
      </div>
      <div className={classes.chatArea} ref={chatAreaRef}>
        {messages.map((message, index) => (
          <div key={message._id}>
            {message.role === "system" || message.role === "assistant" ? (
              <div className={classes.messageOuter}>
                <div className={classes.assistantMessage}>
                  {index === messages.length - 1 ? (
                    <>
                      {message.content === "..." ? (
                        <ReactLoading type={"bubbles"} height={40} width={40} />
                      ) : (
                        <TypeAnimation
                          sequence={[message.content]}
                          wrapper="span"
                          cursor={false}
                          repeat={1}
                        />
                      )}
                    </>
                  ) : (
                    <span>{message.content}</span>
                  )}
                </div>
              </div>
            ) : (
              <div className={classes.messageOuter}>
                <div className={classes.userMessage}>{message.content}</div>
              </div>
            )}
          </div>
        ))}

        {messages.length === 0 && (
          <div className={classes.messageOuter}>
            <div className={classes.assistantMessage}>
              <ReactLoading type={"bubbles"} height={40} width={40} />
            </div>
          </div>
        )}
      </div>
      <div className={classes.messageBox}>
        <TextField
          className={classes.textField}
          label="Message"
          variant="outlined"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter" && message.trim() !== "") {
              handleSendMessage();
            }
          }}
        />
        <Button
          style={{ padding: "12px 40px" }}
          variant="contained"
          color="primary"
          onClick={handleSendMessage}
          className={classes.sendButton}
          disabled={message.trim() === ""}
        >
          Send
        </Button>
      </div>
    </div>
  );
};

export default ChatPage;
