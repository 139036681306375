import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles, Typography, Button } from "@material-ui/core";
import Chip from "@mui/material/Chip";

const useStyles = makeStyles((theme) => ({
  radioContainer: {
    border: "2px solid grey",
    opacity: 0.8,
    width: "100%",
    padding: "20px",
    borderRadius: "10px",
    "&.selected": {
      border: "2px solid white",
      opacity: 1,
    },
  },
  customRadio: {
    position: "absolute",
    top: 0,
    left: 0,
    opacity: 0,
    width: 0,
    height: 0,
  },
  customIcon: {
    fontSize: "2.5rem",
    padding: "10px",
    color: "grey",
    "&.selected": {
      color: "#F9B715",
    },
  },
  balanceCol: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  heading: {
    display: "flex",
    fontSize: "1.1em",
    fontWeight: "700",
  },
  description: {
    display: "flex",
    fontSize: "0.8rem",
  },
  selectionInfo: {
    marginTop: "auto",
    marginBottom: "auto",
    flexGrow: 1,
  },
}));
const RadioBtn = ({
  heading,
  description,
  checked,
  onChange,
  value,
  icon,
  balance,
}) => {
  const classes = useStyles();

  return (
    <label className={`${classes.radioContainer} ${checked ? "selected" : ""}`}>
      <input
        type="radio"
        checked={checked}
        onChange={onChange}
        className={classes.customRadio}
        value={value}
      />
      <div style={{ display: "flex" }}>
        <span className="custom-radio-checkmark">
          <FontAwesomeIcon
            icon={icon}
            className={`${classes.customIcon} ${checked ? "selected" : ""}`}
          />
        </span>
        <div className={classes.selectionInfo}>
          <span className={classes.heading}>{heading}</span>
          <span className={classes.description}>{description}</span>
        </div>
        {balance && (
          <div className={classes.balanceCol}>
            <Chip
              style={{
                color: "white",
                backgroundColor: "#373737",
                fontWeight: "600",
              }}
              label={balance}
            />
          </div>
        )}
      </div>
    </label>
  );
};

export default RadioBtn;
