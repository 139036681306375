import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Container, Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    backgroundColor: "#000000", // Black background
    color: "#ffffff", // White text
  },
  section: {
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    fontWeight: "bold",
    marginTop: theme.spacing(2),
  },
  content: {
    marginBottom: theme.spacing(1),
  },
}));

const PrivacyPolicy = () => {
  const classes = useStyles();

  useEffect(() => {
    document.title = "Privacy Policy";
  }, []);

  return (
    <Container className={classes.root}>
      <Paper
        elevation={3}
        style={{ backgroundColor: "#000000", color: "#ffffff" }}
      >
        <Box p={3}>
          <Typography variant="h4" className={classes.title}>
            Privacy Policy
          </Typography>

          <div className={classes.section}>
            <Typography variant="h6" className={classes.subtitle}>
              1. Introduction
            </Typography>
            <Typography className={classes.content}>
              Welcome to our website. We are committed to protecting your
              privacy and ensuring that your personal information is handled in
              a safe and responsible manner.
            </Typography>
          </div>

          <div className={classes.section}>
            <Typography variant="h6" className={classes.subtitle}>
              2. Information We Collect
            </Typography>
            <Typography className={classes.content}>
              We collect information that you provide directly to us, such as
              when you create or modify your account, request information from
              us, or otherwise communicate with us. This information may
              include:
              <br />
              - Name
              <br />
              - Email address
              <br />
              - Contact information
              <br />
              - Payment information
              <br />- Other information you choose to provide
            </Typography>
          </div>

          <div className={classes.section}>
            <Typography variant="h6" className={classes.subtitle}>
              3. Use of Information
            </Typography>
            <Typography className={classes.content}>
              We use the information we collect to:
              <br />
              - Provide, operate, and maintain our services
              <br />
              - Improve, personalize, and expand our services
              <br />
              - Understand and analyze how you use our services
              <br />
              - Process transactions and send related information
              <br />
              - Communicate with you, including for customer service, to provide
              updates and other information
              <br />
              - Send marketing and promotional communications
              <br />- Find and prevent fraud
            </Typography>
          </div>

          <div className={classes.section}>
            <Typography variant="h6" className={classes.subtitle}>
              4. Sharing of Information
            </Typography>
            <Typography className={classes.content}>
              We may share the information we collect with:
              <br />
              - Service providers who perform services on our behalf
              <br />
              - Affiliates for purposes consistent with this Privacy Policy
              <br />
              - Business partners to offer you certain products, services, or
              promotions
              <br />
              - Other users if you share information or content in a public area
              of the site
              <br />- Law enforcement or government agencies if required by law
            </Typography>
          </div>

          <div className={classes.section}>
            <Typography variant="h6" className={classes.subtitle}>
              5. Security
            </Typography>
            <Typography className={classes.content}>
              We implement a variety of security measures to maintain the safety
              of your personal information. However, no method of transmission
              over the internet or method of electronic storage is 100% secure,
              and we cannot guarantee absolute security.
            </Typography>
          </div>

          <div className={classes.section}>
            <Typography variant="h6" className={classes.subtitle}>
              6. Your Choices
            </Typography>
            <Typography className={classes.content}>
              You have choices regarding the use of your personal information,
              including:
              <br />
              - Opting out of receiving promotional emails from us by following
              the unsubscribe instructions provided in those emails
              <br />
              - Accessing, updating, or deleting your personal information by
              logging into your account or contacting us
              <br />- Setting your browser to refuse cookies, or to alert you
              when cookies are being sent
            </Typography>
          </div>

          <div className={classes.section}>
            <Typography variant="h6" className={classes.subtitle}>
              7. Changes to This Privacy Policy
            </Typography>
            <Typography className={classes.content}>
              We may update this Privacy Policy from time to time. If we make
              changes, we will notify you by revising the date at the top of the
              policy, and in some cases, we may provide additional notice (such
              as adding a statement to our homepage or sending you a
              notification).
            </Typography>
          </div>
        </Box>
      </Paper>
    </Container>
  );
};

export default PrivacyPolicy;
