import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { makeStyles, Button } from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";

import CommentsContent from "../Comments/CommentsContent";

const useStyles = makeStyles((theme) => ({
  //   root: {
  //     display: "flex",
  //     justifyContent: "center",
  //     width: "100%",
  //   },
  modalHeader: {
    padding: "1rem 1rem 0.5rem 2rem",
  },
  modalTitle: {
    fontSize: "1.5rem",
    color: "#fff",
    fontWeight: "700",
    fontFamily: "Basier Square",
    display: "flex",
    justifyContent: "center",
    padding: "0.6rem 0.6rem 0rem 0.6rem",
  },
  modalBody: {
    padding: "2rem",
  },
  modalParagraph: {
    transition: "all 0.2s ease-in-out 0s",
    fontWeight: 400,
    lineHeight: "140%",
    color: "rgb(142, 142, 147)",
    fontSize: "0.875rem",
    padding: "0.2rem",
    textAlign: "center",
  },
  modalClose: {
    color: "#fff !important",
    background: "#404040",
    padding: "8px",
    borderRadius: "100%",
    width: "30px",
    height: "30px",
    padding: "8px 10px 4px 11px",
    display: "flex",
    cursor: "pointer",
    float: "right",
    "&:hover": {
      backgroundColor: "#303030 !important",
    },
  },
  shareButton: {
    backgroundColor: "#404040",
    color: "#fff",
    margin: "0 auto",
    "&:hover": {
      backgroundColor: "#303030",
    },
  },
  buttonLabel: {
    color: "#fff",
    fontSize: "0.875rem",
    marginTop: theme.spacing(1),
    textAlign: "center",
    fontWeight: 600,
  },
  shareWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    margin: theme.spacing(1),
  },
  videoRouteTextField: {
    backgroundColor: "#000000",
    borderRadius: "10px",
    margin: "30px",
    marginTop: "30px",
    border: "2px solid #262626",
    "& .MuiInputBase-input": {
      color: "#fff",
      fontSize: "14px",
    },
  },
}));

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "#121212",
  border: "1px solid #262626",
  boxShadow: 24,
  minHeight: "250px",
  p: 4,
  borderRadius: "15px",
  padding: "10px",
};

const CommentModal = ({ isOpen, onClose, videoId }) => {
  const classes = useStyles();

  return (
    <Modal
      open={isOpen}
      onBackdropClick={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <div className={classes.modalHeader}>
          <a className={classes.modalClose} onClick={onClose}>
            <FontAwesomeIcon icon={faXmark} size="sm" />
          </a>

          <div className={classes.modalTitle}>Comments</div>
        </div>
        <div>
          <div className={classes.modalParagraph}>
            <CommentsContent videoId={videoId} />
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export { CommentModal };
