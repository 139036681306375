import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { makeStyles, Button } from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";

// import IconButton from "@mui/material/IconButton";
// import FacebookIcon from "@mui/icons-material/Facebook";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import EmailIcon from "@mui/icons-material/Email";
// import WhatsAppIcon from "@mui/icons-material/WhatsApp";
// import FileCopyIcon from "@mui/icons-material/FileCopy";

import { TextField, InputAdornment } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  //   root: {
  //     display: "flex",
  //     justifyContent: "center",
  //     width: "100%",
  //   },
  modalHeader: {
    padding: "1rem 1rem 0.5rem 2rem",
  },
  modalTitle: {
    fontSize: "1.5rem",
    color: "#fff",
    fontWeight: "700",
    fontFamily: "Basier Square",
    display: "flex",
    justifyContent: "center",
    padding: "0.6rem 0.6rem 0rem 0.6rem",
  },
  modalBody: {
    padding: "2rem",
  },
  modalParagraph: {
    transition: "all 0.2s ease-in-out 0s",
    fontWeight: 400,
    lineHeight: "140%",
    color: "rgb(142, 142, 147)",
    fontSize: "0.875rem",
    padding: "0.2rem",
    textAlign: "center",
  },
  modalClose: {
    color: "#fff !important",
    background: "#404040",
    padding: "8px",
    borderRadius: "100%",
    width: "30px",
    height: "30px",
    padding: "8px 10px 4px 11px",
    display: "flex",
    cursor: "pointer",
    float: "right",
    "&:hover": {
      backgroundColor: "#303030 !important",
    },
  },
  shareButton: {
    backgroundColor: "#404040",
    color: "#fff",
    margin: "0 auto",
    "&:hover": {
      backgroundColor: "#303030",
    },
  },
  buttonLabel: {
    color: "#fff",
    fontSize: "0.875rem",
    marginTop: theme.spacing(1),
    textAlign: "center",
    fontWeight: 600,
  },
  shareWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    margin: theme.spacing(1),
  },
  videoRouteTextField: {
    backgroundColor: "#000000",
    borderRadius: "10px",
    margin: "30px",
    marginTop: "30px",
    border: "2px solid #262626",
    "& .MuiInputBase-input": {
      color: "#fff",
      fontSize: "14px",
    },
  },
}));

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "#121212",
  border: "1px solid #262626",
  boxShadow: 24,
  minHeight: "250px",
  p: 4,
  borderRadius: "15px",
  padding: "10px",
};

const ShareModal = ({ isOpen, onClose, videoRoute }) => {
  const classes = useStyles();

  const handleShare = () => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      console.log("URL copied to clipboard");
    });
  };

  return (
    <Modal
      open={isOpen}
      onBackdropClick={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <div className={classes.modalHeader}>
          <a className={classes.modalClose} onClick={onClose}>
            <FontAwesomeIcon icon={faXmark} size="sm" />
          </a>

          <div className={classes.modalTitle}>Share Video</div>
        </div>
        <div>
          {/* <div className={classes.modalParagraph}>
            <div className={classes.shareWrapper}>
              <Tooltip title="Share on Facebook">
                <div style={{ padding: "10px" }}>
                  <IconButton
                    className={classes.shareButton}
                    onClick={() =>
                      window.open(
                        `https://www.facebook.com/sharer/sharer.php?u=www.nftmaster.com`,
                        "_blank"
                      )
                    }
                  >
                    <Avatar
                      sx={{ width: 56, height: 56 }}
                      style={{ backgroundColor: "#3C5997" }}
                    >
                      <FacebookIcon style={{ fontSize: "30px" }} />
                    </Avatar>
                  </IconButton>
                  <div className={classes.buttonLabel}>Facebook</div>
                </div>
              </Tooltip>

              <Tooltip title="Share on Twitter">
                <div style={{ padding: "10px" }}>
                  <IconButton
                    className={classes.shareButton}
                    onClick={() =>
                      window.open(
                        `https://twitter.com/share?url=${videoRoute}&text=${videoRoute}`,
                        "_blank"
                      )
                    }
                  >
                    <Avatar
                      sx={{ width: 56, height: 56 }}
                      style={{ backgroundColor: "#1DA1F1" }}
                    >
                      <TwitterIcon style={{ fontSize: "30px" }} />
                    </Avatar>
                  </IconButton>
                  <div className={classes.buttonLabel}>Twitter</div>
                </div>
              </Tooltip>

              <Tooltip title="Share via Email">
                <div style={{ padding: "10px" }}>
                  <IconButton
                    className={classes.shareButton}
                    onClick={() =>
                      window.open(
                        `mailto:?subject=${videoRoute}&body=${videoRoute}`,
                        "_blank"
                      )
                    }
                  >
                    <Avatar
                      sx={{ width: 56, height: 56 }}
                      style={{ backgroundColor: "#000000" }}
                    >
                      <EmailIcon style={{ fontSize: "30px" }} />
                    </Avatar>
                  </IconButton>
                  <div className={classes.buttonLabel}>Email</div>
                </div>
              </Tooltip>

              <Tooltip title="Share on WhatsApp">
                <div style={{ padding: "10px" }}>
                  <IconButton
                    className={classes.shareButton}
                    onClick={() =>
                      window.open(
                        `https://api.whatsapp.com/send?text=${videoRoute}`,
                        "_blank"
                      )
                    }
                  >
                    <Avatar
                      sx={{ width: 56, height: 56 }}
                      style={{ backgroundColor: "#23D366" }}
                    >
                      <WhatsAppIcon style={{ fontSize: "30px" }} />
                    </Avatar>
                  </IconButton>
                  <div className={classes.buttonLabel}>WhatsApp</div>
                </div>
              </Tooltip>
              <div style={{ marginTop: "40px", width: "100%" }}>
                <TextField
                  fullWidth
                  className={classes.videoRouteTextField}
                  value={videoRoute}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Copy link ">
                          <IconButton
                            style={{
                              color: "white",
                              backgroundColor: "#303030",
                              borderRadius: "10px",
                              margin: "1px",
                            }}
                            onClick={handleShare}
                            edge="end"
                          >
                            <FileCopyIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </div> */}
        </div>
      </Box>
    </Modal>
  );
};

export { ShareModal };
