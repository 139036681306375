import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { makeStyles, Button } from "@material-ui/core";
import { getWithdraw } from "../../action/creatorPayoutsAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXmark,
  faArrowUpRight as faArrowUpRightSolid,
  faUpRightAndDownLeftFromCenter as faUpRightAndDownLeftFromCenterSolid,
} from "@fortawesome/pro-solid-svg-icons";
import { toast } from "react-toastify";
import Lozenge from "@atlaskit/lozenge";

const useStyles = makeStyles((theme) => ({
  modalHeader: {
    padding: "1rem 1rem 0.5rem 2rem",
  },
  modalTitle: {
    fontSize: "1.5rem",
    color: "#fff",
    fontWeight: "700",
    fontFamily: "Basier Square",
    display: "flex",
    justifyContent: "center",
    padding: "0.6rem 0.6rem 0rem 0.6rem",
  },
  modalBody: {
    padding: "2rem",
  },
  modalParagraph: {
    transition: "all 0.2s ease-in-out 0s",
    fontWeight: 400,
    lineHeight: "140%",
    color: "rgb(142, 142, 147)",
    fontSize: "0.875rem",
    padding: "0.2rem",
    textAlign: "center",
  },
  modalClose: {
    color: "#fff !important",
    background: "#404040",
    padding: "8px",
    borderRadius: "100%",
    width: "30px",
    height: "30px",
    padding: "8px 10px 4px 11px",
    display: "flex",
    cursor: "pointer",
    float: "right",
    "&:hover": {
      backgroundColor: "#303030 !important",
    },
  },
  shareButton: {
    backgroundColor: "#404040",
    color: "#fff",
    margin: "0 auto",
    "&:hover": {
      backgroundColor: "#303030",
    },
  },
  buttonLabel: {
    color: "#fff",
    fontSize: "0.875rem",
    marginTop: theme.spacing(1),
    textAlign: "center",
    fontWeight: 600,
  },
  shareWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    margin: theme.spacing(1),
  },
  videoRouteTextField: {
    backgroundColor: "#000000",
    borderRadius: "10px",
    margin: "30px",
    marginTop: "30px",
    border: "2px solid #262626",
    "& .MuiInputBase-input": {
      color: "#fff",
      fontSize: "14px",
    },
  },
  detailText1: {
    fontSize: "16px",
    wordBreak: "break-all",
  },
}));

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "#121212",
  border: "1px solid #262626",
  boxShadow: 24,
  minHeight: "250px",
  p: 4,
  borderRadius: "15px",
  padding: "10px",
};

const CashoutDetailModal = ({
  isOpen,
  onClose,
  withdrawId,
  parsedWithdraw,
}) => {
  const classes = useStyles();

  const [withdraw, setWithdraw] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const fetchCreatorPayouts = async () => {
    console.log("parsedWithdraw: ", parsedWithdraw);
    console.log("withdrawId: ", withdrawId);

    try {
      if (
        parsedWithdraw === undefined ||
        parsedWithdraw === null ||
        parsedWithdraw === {}
      ) {
        let response = await getWithdraw(withdrawId);
        console.log("setWithdraw: ", response);
        setWithdraw(response);
      } else {
        setWithdraw(parsedWithdraw);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Failed to fetch payouts:", error);
      toast.error("Failed to fetch payouts.");
    }
  };

  const getAmountFromResponse = (withdrawData) => {
    if (
      withdrawData.gateIOAPIResponse &&
      withdrawData.gateIOAPIResponse.trim() !== ""
    ) {
      try {
        const responseObj = JSON.parse(withdrawData.gateIOAPIResponse);
        const amount = parseFloat(responseObj.amount); // Convert amount to a float
        return amount.toFixed(2); // Round to two decimal places
      } catch (error) {
        console.error("Error parsing gateIOAPIResponse:", error);
      }
    }
    return "N/A"; // Default or fallback value
  };

  const getWithdrawId = (withdrawData) => {
    if (
      withdrawData.gateIOAPIResponse &&
      withdrawData.gateIOAPIResponse.trim() !== ""
    ) {
      try {
        const responseObj = JSON.parse(withdrawData.gateIOAPIResponse);
        return responseObj.id;
      } catch (error) {
        console.error("Error parsing gateIOAPIResponse:", error);
      }
    }
    return "N/A"; // Default or fallback value
  };

  const getTXID = (withdrawData) => {
    if (withdrawData.gateIODetails && withdrawData.gateIODetails.txid !== "") {
      try {
        return withdrawData.gateIODetails.txid;
      } catch (error) {
        console.error("Error parsing gateIOAPIResponse:", error);
      }
    }
    return "N/A"; // Default or fallback value
  };

  const getWithdrawMethodUSDConversionRate = (withdrawData) => {
    if (withdrawData.withdrawMethodUSDConversionRate) {
      try {
        return withdrawData.withdrawMethodUSDConversionRate.toFixed(2);
      } catch (error) {
        console.error("Error parsing gateIOAPIResponse:", error);
      }
    }
    return "N/A";
  };

  const getWithdrawAddress = (withdrawData) => {
    if (
      withdrawData.gateIOAPIResponse &&
      withdrawData.gateIOAPIResponse.trim() !== ""
    ) {
      try {
        const responseObj = JSON.parse(withdrawData.gateIOAPIResponse);
        return responseObj.address;
      } catch (error) {
        console.error("Error parsing gateIOAPIResponse:", error);
      }
    }
    return "N/A"; // Default or fallback value
  };

  const getUsdAmountFromWithdraw = (withdrawData) => {
    try {
      if (withdrawData.usdWithdrawAmount) {
        return withdrawData.usdWithdrawAmount.toFixed(2);
      }
      console.log(withdrawData);
    } catch (error) {}
    return "N/A"; // Default or fallback value
  };

  const getStatusLozenge = (status) => {
    switch (status) {
      case "Completed":
        return <Lozenge appearance="success">{status}</Lozenge>;
      case "Failure":
        return <Lozenge appearance="removed">{status}</Lozenge>;
      case "Pending":
        return <Lozenge appearance="moved">{status}</Lozenge>;
      default:
        return <Lozenge>{status}</Lozenge>;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (isOpen && withdrawId) {
        await fetchCreatorPayouts();
      }
    };

    fetchData();
  }, [isOpen, withdrawId, parsedWithdraw]);
  return (
    <Modal
      open={isOpen}
      onBackdropClick={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <div className={classes.modalHeader}>
          <a className={classes.modalClose} onClick={onClose}>
            <FontAwesomeIcon icon={faXmark} size="sm" />
          </a>
          {withdraw === {} && isLoading === false && (
            <div className={classes.modalTitle}>
              Unable to load withdrawal status
            </div>
          )}
          {withdraw !== {} &&
            withdraw !== undefined &&
            withdraw !== "" &&
            isLoading === false && (
              <>
                <div className={classes.modalTitle}>Withdraw Details</div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img src="../images/cashoutImg.png" width="75" height="75" />
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{ fontSize: 18, fontWeight: 700 }}>
                    You got paid ${getUsdAmountFromWithdraw(withdraw)} USD!
                  </div>
                </div>
                <br />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div>{getStatusLozenge(withdraw.status)}</div>
                </div>

                <hr style={{ backgroundColor: "white" }} />

                <div
                  style={{
                    marginTop: "10px",
                    backgroundColor: "#323232",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ fontWeight: 700 }}>Details</div>
                  </div>
                  <div className={classes.detailText1}>
                    <b>Method: </b>
                    {withdraw.withdrawMethod}
                  </div>
                  <div className={classes.detailText1}>
                    <b>Amount: </b>${getUsdAmountFromWithdraw(withdraw)} (
                    {getAmountFromResponse(withdraw)} {withdraw.withdrawMethod})
                  </div>

                  <div className={classes.detailText1}>
                    <b>USD Conversion Rate: </b>
                    {getWithdrawMethodUSDConversionRate(withdraw)}
                  </div>
                  <div className={classes.detailText1}>
                    <b>Withdraw Address: </b>
                    {getWithdrawAddress(withdraw)}
                  </div>
                  <div className={classes.detailText1}>
                    <b>Date: </b>
                    {withdraw.createdAt}
                  </div>
                  <div className={classes.detailText1}>
                    <b>Withdraw ID: </b>
                    {getWithdrawId(withdraw)}
                  </div>
                  <div className={classes.detailText1}>
                    <b>TXID: </b>
                    {getTXID(withdraw)}
                  </div>
                </div>
              </>
            )}
        </div>
      </Box>
    </Modal>
  );
};

export { CashoutDetailModal };
