import React, { useEffect, useState } from "react";
import { grey } from "@material-ui/core/colors";
import { Avatar, makeStyles, Box, Typography, Tab, Tabs } from "@material-ui/core";
import UploadModal from "../../Upload/UploadModal";


import CreatedVideos, { createTheme } from "react-data-table-component"

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        backgroundColor: 'black',
    },
    thumbnail: {
        height: "90px",
        width: "160px",
        padding: '10px',
        borderRadius: 20,
        objectFit: 'cover',
    },
    editBtn: {
        border: '0px solid',
        backgroundColor: 'rgb(251,185,0, 0.2)',
        color: '#FBB900',
        padding: '10px',
        borderRadius: '10px',
        marginRight: '10px',
        fontWeight: '600',
    },
    deleteBtn: {
        border: '0px solid',
        backgroundColor: 'rgb(194,5,6, 0.3)',
        color: 'red',
        padding: '10px',
        borderRadius: '10px',
        marginRight: '10px',
        fontWeight: '600',
    }
}));

const customStyles = {
    tableHead: {
        style: {
            fontWeight: '700',
            opacity: 0.8,
        },
    },
    rows: {
        style: {
        },
    },
    headCells: {
        style: {
            fontWeight: '700',
            fontSize: '17px'
        },
    },
    cells: {
        style: {
            fontWeight: '600',
            fontSize: '14px'
        },
    },
};

createTheme('dark', {
    text: {
        primary: 'white',
        fontWeight: '700',
        secondary: '#FBB900',
    },
    background: {
        default: '#111111',
    },

    context: {
        background: '#FBB900',
        text: '#000000',
        fontWeight: '600'
    },
    divider: {
        default: '#262626',
    },
    action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
    },
}, 'dark');

const UploadedVideos = () => {
    const classes = useStyles();


    const columns = [
        {
            name: 'Videos',
            grow: 1,
            cell: row => <img className={classes.thumbnail} src={row.img} />,

        },
        {
            name: 'Title',
            grow: 2,
            selector: row => row.title,
        },
        {
            name: 'Visibility',
            grow: 1,
            selector: row => row.visibility,
        },
        {
            name: 'Date',
            grow: 1,
            selector: row => row.date,
        },
        {
            name: 'Views',
            grow: 1,
            selector: row => row.views,
        },
        {
            name: 'Actions',
            grow: 1,
            cell: row => <><button className={classes.editBtn}>Edit</button> <button className={classes.deleteBtn}>Delete</button></>,

        },
    ];

    const data = [
        {
            id: 1,
            img: 'https://upload.wikimedia.org/wikipedia/commons/b/b6/Image_created_with_a_mobile_phone.png',
            title: 'Beetlejuice',
            visibility: 'Listed',
            views: "40,000",
            date: '1988',
        },
        {
            id: 2,
            img: 'https://upload.wikimedia.org/wikipedia/commons/b/b6/Image_created_with_a_mobile_phone.png',
            title: 'Ghostbusters',
            visibility: 'Listed',
            views: "340",
            date: '1984',
        },
    ]

    useEffect(() => {

    });
    return (
        <div className={classes.root}>
            <CreatedVideos
                direction="auto"
                fixedHeaderScrollHeight="300px"
                pagination
                responsive
                selectableRows
                subHeaderAlign="right"
                subHeaderWrap
                columns={columns}
                data={data}
                title={'Published Videos'}
                theme="dark"
                customStyles={customStyles}
            />
        </div>
    );
};

export default UploadedVideos;
