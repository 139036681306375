import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import Tooltip from "@mui/material/Tooltip";
import { faArrowLeftLongToLine } from "@fortawesome/pro-duotone-svg-icons";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  optionsSpan: {
    color: "#fff !important",
    background: "#404040",
    borderRadius: "100%",
    width: "30px",
    height: "30px",
    display: "flex",
    cursor: "pointer",
    float: "right",
    transition: "all 0.2s ease-in-out 0s",
    position: "absolute",
    right: "10px",
  },
  closeBtn: {
    color: "#fff !important",
    background: "#404040",
    borderRadius: "100%",
    padding: "8px 10px 4px 11px",
    minWidth: "30px",
    height: "30px",
    display: "flex",
    cursor: "pointer",
    margin: "0px",
    "&:hover": {
      backgroundColor: "#303030 !important",
    },
  },
}));
export default function CloseBtn({ onClose }) {
  const classes = useStyles();
  return (
    <div className={classes.optionsSpan}>
      <Tooltip title="close" placement="top">
        <a className={classes.closeBtn} onClick={onClose}>
          <FontAwesomeIcon icon={faXmark} size="sm" />
        </a>
      </Tooltip>
    </div>
  );
}
