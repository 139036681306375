import React, { Fragment } from "react";
import {
  FormHelperText,
  TextField,
  MenuItem,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
  InputLabel,
} from "@material-ui/core/";
import { Formik } from "formik";
import * as Yup from "yup";
import { capitalize } from "lodash";
import { TextareaAutosize } from "@material-ui/core";
import Select from "@mui/material/Select";

import TextArea from "@atlaskit/textarea";
import AtlassianTextField from "@atlaskit/textfield";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faTimer } from "@fortawesome/pro-regular-svg-icons";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";

import {
  CheckboxField,
  ErrorMessage,
  Field,
  FormFooter,
  FormHeader,
  FormSection,
  HelperMessage,
  RequiredAsterisk,
  ValidMessage,
} from "@atlaskit/form";

const visibility = ["public", "unlisted", "private"];
const categories = [
  "comedy",
  "podcasts",
  "news",
  "sports",
  "documentary",
  "other",
];

const useStyles = makeStyles((theme) => ({
  input: {
    margin: theme.spacing(2),
    width: "75%",
    "& .MuiSelect-select": {
      paddingLeft: "12px",
      backgroundColor: "#000000",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#919599", // Replace 'yourColorHere' with your desired color
      },
      "&:hover fieldset": {
        //borderColor: "blue", // Replace 'yourColorHere' with your desired color for hover
      },
      "&.Mui-focused fieldset": {
        //borderColor: "blue", // Replace 'yourColorHere' with your desired color for focus
      },
    },
  },
}));

const UploadForm = ({ type, onSubmit, formRef }) => {
  const classes = useStyles();

  const form = (type) => {
    let validation = {};
    let initialValues = {};

    if (["details"].includes(type)) {
      initialValues = {
        title: "",
        description: "",
        category: "",
      };
      validation = {
        title: Yup.string().required("Title is required"),
        description: Yup.string()
          .max(500, "Max characters is 500")
          .required("Description is required"),
        category: Yup.string().required("Category is required"),
      };
    }

    if (["monetisation"].includes(type)) {
      initialValues = {
        price: "0",
        paymentType: "",
        paymentPeriodStart: "0",
        freeVideoXRPLNFTIssuerAddress: "",
        freeVideoXRPLNFTCollectionTaxon: "",
        freeVideoCoreumNFTClassId: "",
        freeVideoCoreumNFTIssuerAddress: "",
        nftMarketplaceLink: "",
      };

      validation = {
        price: Yup.string().required("Price is required"),
        paymentType: Yup.string().required("Payment Type is required"),
        paymentPeriodStart: Yup.string().required(
          "Payment Period Start is required"
        ),
      };
    }

    if (["visibility"].includes(type)) {
      initialValues.visibility = "0";
      validation.visibility = Yup.string().required("Visibility is required");
    }
    return { validationSchema: Yup.object().shape(validation), initialValues };
  };

  const validationSchema = form(type).validationSchema;
  const initialValues = form(type).initialValues;

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          onSubmit(values);
        }}
        validateOnMount
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <>
            {type === "details" && (
              <>
                <div className="atlassian-label-dark">
                  Title <div className="required">*</div>
                </div>
                <AtlassianTextField
                  isInvalid={touched.title && !!errors.title}
                  id="details-title"
                  label="Title"
                  className="atlassian-textfield-dark-1"
                  name="title"
                  value={values.title}
                  invalidMessage={
                    touched.title && errors.title ? errors.title : ""
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {touched.title && errors.title ? (
                  <div className="atlassian-input-error">{errors.title}</div>
                ) : (
                  <div className="atlassian-info-label">
                    Add a title that describes your video
                  </div>
                )}

                <br />
                <div className="atlassian-label-dark">
                  Description <div className="required">*</div>
                </div>
                <TextArea
                  isInvalid={touched.description && !!errors.description}
                  id="details-description"
                  label="Description"
                  name="description"
                  value={values.description}
                  invalidMessage={
                    touched.description && errors.description
                      ? errors.description
                      : ""
                  }
                  multiline
                  rows={7}
                  onChange={handleChange}
                  className="atlassian-textfield-dark-1"
                  onBlur={handleBlur}
                />

                {touched.description && errors.description ? (
                  <div className="atlassian-input-error">
                    {errors.description}
                  </div>
                ) : (
                  <div className="atlassian-info-label">
                    Tell your audience about your video.
                  </div>
                )}

                <br />

                <div className="atlassian-label-dark">
                  Select Category <div className="required">*</div>
                </div>
                <Select
                  labelId="category-label"
                  id="category"
                  name="category"
                  value={values.category}
                  className={classes.input}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={{
                    color: "inherit",
                  }}
                  sx={{
                    "&:hover": {
                      "&& fieldset": {
                        border: "1px solid white",
                      },
                    },
                    "& fieldset": {
                      borderColor: "#919599",
                    },
                    "& .MuiSvgIcon-root": {
                      fill: "white",
                    },
                  }}
                >
                  <MenuItem key={""} value={""}>
                    Select category
                  </MenuItem>
                  {categories.map((value) => (
                    <MenuItem key={value} value={value}>
                      {value === "" ? "Select category" : capitalize(value)}
                    </MenuItem>
                  ))}
                  <br />
                </Select>

                {touched.category && errors.category ? (
                  <div className="atlassian-input-error">{errors.category}</div>
                ) : (
                  <div className="atlassian-info-label">
                    What category does your video fall under?
                  </div>
                )}
              </>
            )}

            {type === "monetisation" && (
              <>
                <div style={{ padding: 10, fontWeight: 600, opacity: 0.9 }}>
                  You can always change monetisation settings later.
                </div>

                <div className="atlassian-label-dark">
                  Select Payment Type <div className="required">*</div>
                </div>
                <Select
                  labelId="paymentType-label"
                  id="paymentType"
                  name="paymentType"
                  value={values.paymentType}
                  className={classes.input}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={{
                    color: "inherit",
                  }}
                  sx={{
                    width: "80%",
                    "&:hover": {
                      "&& fieldset": {
                        // border: "1px solid white",
                      },
                    },
                    "& fieldset": {
                      border: "2px solid #2e2e2e",
                    },
                    "& .MuiSvgIcon-root": {
                      fill: "white",
                    },
                  }}
                >
                  <MenuItem key={"Free"} value={"Free"}>
                    Free
                  </MenuItem>
                  <MenuItem key={"Stream Pay"} value={"Stream Pay"}>
                    Stream Pay
                  </MenuItem>
                  <MenuItem key={"Pay Per View"} value={"Pay Per View"}>
                    Pay Per View
                  </MenuItem>
                </Select>
                {touched.paymentType && errors.paymentType ? (
                  <div className="atlassian-input-error">
                    {errors.paymentType}
                  </div>
                ) : (
                  <div className="atlassian-info-label">
                    Stream Pay: Allows users to stream a payment as they watch.
                    <br />
                    Pay Per View: User pays the entire amount before watching.
                  </div>
                )}

                <br />
                {values.paymentType !== "" && values.paymentType !== "Free" && (
                  <>
                    <div className="atlassian-label-dark">
                      Price <div className="required">*</div>
                    </div>
                    <AtlassianTextField
                      isInvalid={touched.price && !!errors.price}
                      id="details-price"
                      label="Price"
                      className="atlassian-textfield-dark-1"
                      name="price"
                      placeholder="5.00"
                      value={values.price}
                      invalidMessage={
                        touched.title && errors.price ? errors.price : ""
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      elemBeforeInput={
                        <span
                          style={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                          }}
                        >
                          <FontAwesomeIcon icon={faDollarSign} size="sm" />
                        </span>
                      }
                    />

                    {touched.price && errors.price ? (
                      <div className="atlassian-input-error">
                        {errors.price}
                      </div>
                    ) : (
                      <div className="atlassian-info-label">
                        Total price of your video in USD. If free enter 0
                      </div>
                    )}

                    <br />

                    <div className="atlassian-label-dark">
                      Payment Period Start Time{" "}
                      <div className="required">*</div>
                    </div>
                    <AtlassianTextField
                      isInvalid={
                        touched.paymentPeriodStart &&
                        !!errors.paymentPeriodStart
                      }
                      id="details-paymentPeriodStart"
                      label="paymentPeriodStart"
                      className="atlassian-textfield-dark-1"
                      name="paymentPeriodStart"
                      value={values.paymentPeriodStart}
                      invalidMessage={
                        touched.title && errors.paymentPeriodStart
                          ? errors.paymentPeriodStart
                          : ""
                      }
                      placeholder="0:30"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      elemBeforeInput={
                        <span
                          style={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                          }}
                        >
                          <FontAwesomeIcon icon={faTimer} size="sm" />
                        </span>
                      }
                    />

                    {touched.paymentPeriodStart && errors.paymentPeriodStart ? (
                      <div className="atlassian-input-error">
                        {errors.paymentPeriodStart}
                      </div>
                    ) : (
                      <div className="atlassian-info-label">
                        0:30 = 30 seconds into the video 1:00 = 1 minute into
                        the video
                      </div>
                    )}
                  </>
                )}

                <br />
                <div className="atlassian-label-dark">
                  Web3 Advanced Settings<div className="required">*</div>
                </div>
                <Accordion
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    opacity: 0.9,
                    width: "80%",
                    border: "2px solid #2e2e2e !important",
                    fontSize: "1 rem",
                  }}
                >
                  <AccordionSummary
                    // expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                    expandIcon={<div>expand</div>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div style={{ fontWeight: "bold" }}>Advanced Settings</div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Accordion
                      sx={{
                        backgroundColor: "black",
                        color: "white",
                        opacity: 0.9,
                        width: "80%",
                        border: "2px solid #2e2e2e !important",
                        fontSize: "1 rem",
                      }}
                    >
                      <AccordionSummary
                        // expandIcon={
                        //   <ExpandMoreIcon style={{ color: "white" }} />
                        // }
                        expandIcon={<div>expand</div>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <div style={{ fontWeight: "bold" }}>XRPL</div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div>
                          Allow free viewing for NFT holders. If the fields are
                          incomplete NFT viewership will not be free.
                        </div>
                        <br />
                        <div className="atlassian-label-dark">
                          Issuing Address <div className="required">*</div>
                        </div>
                        <AtlassianTextField
                          id="details-freeVideoXRPLNFTIssuerAddress"
                          label="freeVideoXRPLNFTIssuerAddress"
                          className="atlassian-textfield-dark-1"
                          name="freeVideoXRPLNFTIssuerAddress"
                          placeholder="r...."
                          value={values.freeVideoXRPLNFTIssuerAddress}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        <div className="atlassian-label-dark">
                          Collection ID (Taxon){" "}
                          <div className="required">*</div>
                        </div>
                        <AtlassianTextField
                          id="details-freeVideoXRPLNFTCollectionTaxon"
                          label="freeVideoXRPLNFTCollectionTaxon"
                          className="atlassian-textfield-dark-1"
                          name="freeVideoXRPLNFTCollectionTaxon"
                          placeholder="1"
                          value={values.freeVideoXRPLNFTCollectionTaxon}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div className="atlassian-info-label">
                          Taxon must be included.
                        </div>
                        <br />
                        <div className="atlassian-label-dark">
                          NFT Marketplace Link
                        </div>
                        <AtlassianTextField
                          id="details-nftMarketplaceLink"
                          label="nftMarketplaceLink"
                          className="atlassian-textfield-dark-1"
                          name="nftMarketplaceLink"
                          placeholder="https://"
                          value={values.nftMarketplaceLink}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div className="atlassian-info-label">
                          Add a link to where users can purchase your NFTs.
                        </div>
                      </AccordionDetails>
                    </Accordion>

                    <br />
                    <Accordion
                      sx={{
                        backgroundColor: "black",
                        color: "white",
                        opacity: 0.9,
                        width: "80%",
                        border: "2px solid #2e2e2e !important",
                        fontSize: "1 rem",
                      }}
                    >
                      <AccordionSummary
                        // expandIcon={
                        //   <ExpandMoreIcon style={{ color: "white" }} />
                        // }
                        expandIcon={<div>expand</div>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <div style={{ fontWeight: "bold" }}>COREUM</div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div>
                          Allow free viewing for NFT holders. If the fields are
                          incomplete NFT viewership will not be free.
                        </div>
                        <br />
                        <div className="atlassian-label-dark">
                          Coreum Issuing Address
                          <div className="required">*</div>
                        </div>
                        <AtlassianTextField
                          id="details-freeVideoCoreumNFTIssuerAddress"
                          label="freeVideoCoreumNFTIssuerAddress"
                          className="atlassian-textfield-dark-1"
                          name="freeVideoCoreumNFTIssuerAddress"
                          value={values.freeVideoCoreumNFTIssuerAddress}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div className="atlassian-info-label">
                          The amount of NFTs users are required to hold from the
                          Class Id. E.g. 1
                        </div>
                        <div className="atlassian-label-dark">
                          NFT Class ID <div className="required">*</div>
                        </div>
                        <AtlassianTextField
                          id="details-freeVideoCoreumNFTClassId"
                          label="freeVideoCoreumNFTClassId"
                          className="atlassian-textfield-dark-1"
                          name="freeVideoCoreumNFTClassId"
                          placeholder=""
                          value={values.freeVideoCoreumNFTClassId}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <br />
                        <div className="atlassian-label-dark">
                          NFT Marketplace Link
                        </div>
                        <AtlassianTextField
                          id="details-nftMarketplaceLink"
                          label="nftMarketplaceLink"
                          className="atlassian-textfield-dark-1"
                          name="nftMarketplaceLink"
                          placeholder="https://"
                          value={values.nftMarketplaceLink}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div className="atlassian-info-label">
                          Add a link to where users can purchase your NFTs.
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </AccordionDetails>
                </Accordion>
              </>
            )}
            {type === "visibility" && (
              <>
                <FormControl
                  component="fieldset"
                  error={errors.visibility}
                  className={classes.formControl}
                  style={{ height: "300px" }}
                >
                  <FormLabel component="legend">
                    Pick the visibility of this video:
                  </FormLabel>
                  <RadioGroup
                    aria-label="visibility"
                    name="visibility"
                    value={values.visibility}
                    onChange={handleChange}
                  >
                    {visibility.map((value, index) => (
                      <FormControlLabel
                        key={index}
                        value={index + ""}
                        control={<Radio />}
                        label={capitalize(value)}
                      />
                    ))}
                  </RadioGroup>
                  {errors.visibility && touched.color && (
                    <FormHelperText>{errors.visibility}</FormHelperText>
                  )}{" "}
                </FormControl>
              </>
            )}
          </>
        )}
      </Formik>
    </>
  );
};

export default UploadForm;
