import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Typography,
  TextField,
  Avatar,
  Box,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  faFolders as faFoldersDuotone,
  faObjectsColumn as faObjectsColumnDuotone,
  faVideo as faVideoDuotone,
  faChartSimple as faChartSimpleDuotone,
  faDollarSign as faDollarSignDuotone,
  faPen as faPenDuotone,
  faLinkHorizontal as faLinkHorizontalDuotone,
  faGears as faGearsDuotone,
} from "@fortawesome/pro-duotone-svg-icons";

import { faPlusLarge as faPlusLargeSolid } from "@fortawesome/pro-solid-svg-icons";

import NavItem from "../NavItem";

const useStyles = makeStyles((theme) => ({
  containerType1: {
    minWidth: 250,
    backgroundColor: "#121212",
    //height: '100%',
    //border: '1px solid #262626',
    margin: "8px",
    borderRadius: "20px",
    padding: 10,
  },
  settingTitle: {
    padding: "20px",
    display: "flex",
    fontWeight: 600,
    fontSize: "1.8rem",
  },
  stickySideNav: {
    position: "fixed",
  },
}));

const CreateMenu = () => {
  const classes = useStyles();
  const isAuth = useSelector(({ channel }) => channel.isAuth);

  useEffect(() => {});

  return (
    <>
      {isAuth && (
        <div className={classes.containerType1}>
          <div className={classes.stickySideNav}>
            <div className={classes.settingTitle}>Create</div>
            <NavItem
              to={"/create/dashboard"}
              title={"Dashboard"}
              icon={faObjectsColumnDuotone}
              activeIcon={faObjectsColumnDuotone}
            />
            <NavItem
              to={"/create/video"}
              title={"Create Video"}
              icon={faPlusLargeSolid}
              activeIcon={faPlusLargeSolid}
            />
            {/* <NavItem
              to={"/create/uploaded-videos"}
              title={"Uploaded Videos"}
              icon={faVideoDuotone}
              activeIcon={faVideoDuotone}
            />
            <NavItem
              to={"/create/drafts"}
              title={"Drafts"}
              icon={faFoldersDuotone}
              activeIcon={faFoldersDuotone}
            /> */}
            <NavItem
              to={"/create/analytics"}
              title={"Analytics"}
              icon={faChartSimpleDuotone}
              activeIcon={faChartSimpleDuotone}
            />
            <NavItem
              to={"/create/monetisation"}
              title={"Monetisation"}
              icon={faDollarSignDuotone}
              activeIcon={faDollarSignDuotone}
            />
            <hr style={{ backgroundColor: "grey" }} />

            <NavItem
              to={"/settings/account"}
              title={"Settings"}
              icon={faGearsDuotone}
              activeIcon={faGearsDuotone}
            />
          </div>
        </div>
      )}
    </>
  );
};

export { CreateMenu };
