import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, Typography, Divider, Avatar } from "@material-ui/core";
import clsx from "clsx";
import NumAbbr from "number-abbreviate";
import SubscribeBtn from "../SubscribeBtn";
import { grey } from "@material-ui/core/colors";
import { getSuggestedVideos } from "../../redux/actions/videos";
import SecondaryVidContentTabs from "./SecondaryVidContentTabs";
import { api } from "../../utils/api";

const useStyles = makeStyles((theme) => ({
  title: {
    paddingBottom: theme.spacing(1),
  },
  text: {
    fontWeight: 400,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  subTitle: {
    color: grey[700],
  },
  pointer: {
    cursor: "pointer",
  },
  primaryInfo: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  secondaryInfo: {
    display: "flex",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  secondaryInfo_1: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  secondaryInfo_2: {
    display: "flex",
    width: "100%",
    marginBottom: "10px",
  },
  channel: {
    fontWeight: 500,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  secondaryInfo_3: {
    paddingLeft: theme.spacing(1),
    lineHeight: "80%",
    maxWidth: "calc(100% - 160px)",
  },
  likeSubscribe: {
    display: "flex",
    marginLeft: "20px",
  },
}));

export default function SecondaryVidContent({ videoId }) {
  const dispatch = useDispatch();
  const [video, setVideo] = useState({});
  const [numberOfSubscribers, setSubscribers] = useState(0);
  const classes = useStyles();

  const fetchVideoContent = async () => {
    try {
      const {
        data: { video },
      } = await api.patch(`/api/videos/${videoId}`, { updateViews: true });

      setVideo(video || {});
      const {
        data: { subscribers },
      } = await api.post(`/api/subscriptions/count`, {
        channel: video.channelId,
      });
      setSubscribers(subscribers || 0);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchVideoContent();
  }, [videoId]);

  useEffect(() => {
    dispatch(getSuggestedVideos());
  }, []);

  const handleSubscriptionChange = (change) => {
    setSubscribers((prev) => prev + change);
  };

  return (
    <div>
      <div className={classes.secondaryInfo}>
        <Avatar alt="Avatar" src={video.profileImage} />
        <div className={classes.secondaryInfo_1}>
          <div className={classes.secondaryInfo_2}>
            <div className={classes.secondaryInfo_3}>
              <Typography variant="body2" className={clsx(classes.channel)}>
                {video.channelName}
              </Typography>
              {numberOfSubscribers === 1 ? (
                <Typography
                  variant="caption"
                  className={clsx(classes.subTitle)}
                >
                  1 subscriber
                </Typography>
              ) : (
                <Typography
                  variant="caption"
                  className={clsx(classes.subTitle)}
                >
                  {new NumAbbr().abbreviate(numberOfSubscribers, 2)} subscribers
                </Typography>
              )}
            </div>
            <div className={classes.likeSubscribe}>
              <SubscribeBtn
                channelId={video.channelId}
                onSubscriptionChange={handleSubscriptionChange}
              />
            </div>
          </div>
        </div>
      </div>
      <SecondaryVidContentTabs />
    </div>
  );
}
