import { grey, blue } from "@material-ui/core/colors";

export const createPalette = (mode) => ({
  typography: {
    fontFamily: [
      '"Basier Square"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  overrides: {
    mode,
    ...(mode === "light"
      ? {
          MuiTab: {
            root: {
              backgroundColor: "grey !important",
              color: "black !important",
              fontWeight: 600,
              borderRadius: "10px",
              minHeight: "0px",
              fontSize: "0.7rem",
              marginTop: "1px",
              marginBottom: "1px",
              marginLeft: "5px",
              marginRight: "5px",
              "&:focus": {
                outline: "0px !important",
              },
              "&.Mui-selected": {
                background: "black !important",
                color: "white !important",
                fontWeight: 600,
                "&:focus": {
                  outline: "0px !important",
                },
              },
            },
          },
          MuiTabs: {
            indicator: {
              height: "0px",
            },
          },
        }
      : {
          // palette values for dark mode
          MuiTab: {
            root: {
              backgroundColor: "grey !important",
              color: "black !important",
              fontWeight: 600,
              borderRadius: "10px",
              minHeight: "0px",
              fontSize: "0.7rem",
              marginTop: "1px",
              marginBottom: "1px",
              marginLeft: "5px",
              marginRight: "5px",
              "&:focus": {
                outline: "0px !imortant",
              },
              "&.Mui-selected": {
                background: "white !important",
                color: "black !important",
                fontWeight: 600,
                "&:focus": {
                  outline: "0px !important",
                },
              },
              "&.vm-navigation": {
                backgroundColor: "transparent !important",
                color: "white !important",
              },
            },
          },

          MuiTabs: {
            indicator: {
              height: "0px",
            },
          },
        }),
  },
  palette: {
    mode,
    ...(mode === "light"
      ? {
          // palette values for light mode

          primary: blue,
          secondary: blue,
          accent: "#FBB900",
          iconBg: grey[300],
          nav: {
            side: {
              background: "#ffffff",
            },
          },
          button: {
            alternateBackground: "#000000",
            alternateBackgroundHover: "#121212",
            alternateText: "#ffffff",
          },
          background: {
            default: "#f2f1f8",
            secondary: "#ffffff",
          },
          text: {
            primary: grey[900],
            secondary: grey[800],
          },
          icon: {
            primary: "#000000",
            background: "#f2f1f8",
            hover: "rgb(251,185,0, 0.2)",
          },
        }
      : {
          // palette values for dark mode
          primary: blue,
          secondary: blue,
          accent: "#FBB900",
          divider: "#121212",
          iconBg: "#121212",
          nav: {
            side: {
              background: "#000000",
            },
          },
          button: {
            alternate: {
              background: "#ffffff",
              backgroundHover: "#f2f1f8",
              text: "#000000",
            },
          },
          background: {
            default: "#121212",
            primary: "#121212",
            secondary: "#000000",
            //paper: '#001220',
          },
          text: {
            primary: "#ffffff",
            secondary: "#6c757d",
          },
          icon: {
            primary: "#ffffff",
            background: "#282828",
            hover: "rgb(251,185,0, 0.2)",
          },
        }),
  },
});
