import React, { useState, useRef } from "react";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { InputBase, Button, Tooltip } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import { withRouter } from "react-router";

const useStyles = makeStyles((theme) => ({
  searchButton: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    "&:hover": {
      backgroundColor: theme.palette.background.default,
    },
    "&:focus": {
      outline: "none",
    },
  },
  border: {
    borderColor: theme.palette.iconBg,
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: 15,
    padding: 5,
  },
  searchForm: {
    backgroundColor: theme.palette.background.default,
    width: "100%",
    borderRadius: "15px",
    border: "1px solid #303030",
  },
  input: {
    padding: theme.spacing(0, 2),
    fontSize: "16px",
    fontWeight: 400,
    // color: grey[500],
    color: theme.palette.text.primary,
  },
}));

const MiddleNav = ({ history }) => {
  const [searchValue, setSearchValue] = useState("");
  const classes = useStyles();
  const searchBtn = useRef(null);

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      // Handle Enter key press here
      searchBtn.current.click();
    }
  }

  const handleSearch = () => {
    if (searchValue) {
      history.push(`/results?search_query=${searchValue}`);
    }
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };
  return (
    <div className={classes.searchForm}>
      <InputBase
        fullWidth
        onKeyPress={handleKeyPress}
        value={searchValue}
        onChange={handleSearchChange}
        className={classes.border}
        classes={{
          input: classes.input,
        }}
        placeholder="Search..."
        inputProps={{ "aria-label": "search" }}
        endAdornment={
          <Tooltip title="Search">
            <Button
              ref={searchBtn}
              disableRipple
              size="small"
              type="submit"
              className={classes.searchButton}
              onClick={handleSearch}
              aria-label="search"
            >
              <SearchIcon fontSize="small" />
            </Button>
          </Tooltip>
        }
      />
    </div>
  );
};

export default withRouter(MiddleNav);
