import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import clsx from "clsx";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPodcast,
  faMicrophoneStand,
} from "@fortawesome/pro-duotone-svg-icons";

const useStyles = makeStyles((theme) => ({
  active: {
    // backgroundColor: 'rgb(251,185,0, 0.2)',
    fontWeight: "600 !important",
    borderRadius: "25px",
    "&:hover": { backgroundColor: grey[300] },
    opacity: "1 !important",
  },
  icon: {
    color: theme.palette.icon.primary,
  },
  iconActive: {
    color: "#fff",
  },
  iconBackground: {
    //backgroundColor: theme.palette.icon.background,
    padding: "8px",
    borderRadius: "50%",
    width: "36px",
    display: "flex",
    height: "36px",
    justifyContent: "center",
    alignItems: "center",
    // "&.active": {
    //   backgroundColor: "rgb(251,185,0, 0.2)",
    // },
  },
  listItem: {
    fontSize: "1rem",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontWeight: 600,
  },
  textActive: {
    fontWeight: 700,
  },
  roundedEdges: {
    opacity: "0.8",
    fontWeight: 500,
    borderRadius: "25px",
    "&:hover": {
      backgroundColor: "#343434",
    },
  },
  secondaryItem: {
    display: "flex",
    justifyContent: "center",
  },
  text: {
    color: "inherit",
    textDecoration: "none",
    "&:hover": {
      color: "inherit",
      textDecoration: "none",
    },
  },
}));
const CircleItem = ({ children, title }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }}
  >
    <div
      style={{
        width: "80px",
        height: "80px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {children}
    </div>
    <div>{title}</div>
  </div>
);
const NavItem = ({
  to,
  title,
  activeIcon,
  icon,
  onClick,
  disableActive,
  type,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const isActive =
    location.pathname === "/trending"
      ? location.pathname + location.search === to
      : location.pathname === to;
  const Icon = icon;
  const activeClass = isActive ? classes.active : "";
  const classSet = `${classes.roundedEdges} ${activeClass}`;
  const Item =
    type === "secondary" ? (
      <CircleItem title={title}>
        <FontAwesomeIcon icon={icon} size="3x" />
      </CircleItem>
    ) : (
      <ListItem button onClick={onClick} className={classSet}>
        <ListItemIcon
          className={clsx(classes.icon, {
            [classes.iconActive]: isActive && !disableActive,
          })}
        >
          <div
            className={`${classes.iconBackground} ${isActive ? "active" : ""}`}
          >
            {isActive ? (
              <FontAwesomeIcon icon={activeIcon} size="lg" />
            ) : (
              <FontAwesomeIcon icon={icon} size="lg" />
            )}
          </div>
        </ListItemIcon>
        <span
          primary={title}
          className={clsx(classes.listItem, {
            [classes.textActive]: isActive && !disableActive,
          })}
        >
          {title}
        </span>
      </ListItem>
    );
  return to ? (
    <NavLink to={to} className={classes.text}>
      {Item}
    </NavLink>
  ) : (
    <> {Item} </>
  );
};

export default NavItem;
