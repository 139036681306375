import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles, Typography } from "@material-ui/core";
import moment from "moment";
import { api } from "../../../utils/api";
import { API } from "../../../config/config.js";
import CreatedVideos, { createTheme } from "react-data-table-component";
import thumbnailCover from "../../../assets/image/thumbnailCover.png";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  thumbnail: {
    height: "90px",
    width: "160px",
    padding: "10px",
    borderRadius: 20,
    objectFit: "cover",
  },
  viewBtn: {
    border: "0px solid",
    backgroundColor: "rgb(168 168 168 / 20%)",
    color: "white",
    padding: "10px",
    borderRadius: "10px",
    marginRight: "10px",
    fontWeight: "600",
  },
  deleteBtn: {
    border: "0px solid",
    backgroundColor: "rgb(189 189 189 / 30%)",
    color: "white",
    padding: "10px",
    borderRadius: "10px",
    marginRight: "10px",
    fontWeight: "600",
  },
}));

const customStyles = {
  tableHead: {
    style: {
      fontWeight: "700",
    },
  },
  rows: {
    style: {},
  },
  headCells: {
    style: {
      fontWeight: "700",
      fontSize: "17px",
    },
  },
  cells: {
    style: {
      fontWeight: "600",
      fontSize: "14px",
    },
  },
};

createTheme(
  "dark",
  {
    text: {
      primary: "white",
      fontWeight: "700",
      secondary: "#FBB900",
    },
    background: {
      default: "#111111",
    },
    context: {
      background: "#FBB900",
      text: "#000000",
      fontWeight: "600",
    },
    divider: {
      default: "#262626",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  },
  "dark"
);

const CreateDashboard = () => {
  const classes = useStyles();
  const loggedInId = useSelector(({ channel }) => channel.id);

  const history = useHistory();

  const [data, setData] = useState([]);
  const [totalVideos, setTotalVideos] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const handleImageError = (e) => {
    e.target.onerror = null; // Prevent infinite loop in case of default image also fails
    e.target.src = thumbnailCover;
  };

  const handleViewClick = (videoId) => {
    history.push(`/watch?v=${videoId}`);
  };

  const visibilityText = (visibility) => {
    switch (visibility) {
      case 0:
        return "Public";
      case 1:
        return "Unlisted";
      case 2:
        return "Private";
      default:
        return "Unknown";
    }
  };

  const hashText = (hash) => {
    if (hash) {
      return hash;
    } else {
      return "No hash found";
    }
  };

  const columns = [
    {
      name: "",
      grow: 1,
      cell: (row) => (
        <img
          className={classes.thumbnail}
          src={row.thumbnailLink || thumbnailCover}
          onError={handleImageError}
        />
      ),
    },
    {
      name: "Title",
      grow: 2,
      cell: (row) => (
        <div>
          <Typography>
            <b>
              {row.title?.length > 100
                ? row.title.substring(0, 100) + "..."
                : row.title || "Untitled"}
            </b>
          </Typography>
          <Typography variant="caption" style={{ opacity: 0.6 }}>
            {row.description?.length > 150
              ? row.description.substring(0, 150) + "..."
              : row.description || "No description"}
          </Typography>
        </div>
      ),
    },
    {
      name: "Date",
      grow: 1,
      selector: (row) => moment(row.createdAt).fromNow() || "Unknown",
      style: {
        opacity: 0.6,
      },
    },
    {
      name: "Views",
      grow: 1,
      selector: (row) => row.views || 0,
    },

    {
      name: "Visibility",
      grow: 1,
      cell: (row) => visibilityText(row.visibility),
    },
    {
      name: "IPFS",
      grow: 1,
      cell: (row) => hashText(row.hash),
    },
    {
      name: "Actions",
      grow: 1,
      cell: (row) => (
        <>
          <button
            className={classes.viewBtn}
            onClick={() => handleViewClick(row.id)}
          >
            View
          </button>{" "}
          <button className={classes.deleteBtn}>Delete</button>
        </>
      ),
    },
  ];

  const fetchData = async (page) => {
    if (!loggedInId) return; // Prevent API call if channelId is not available
    setLoading(true);
    try {
      const response = await api.get(
        `${API}/videos/channel/${loggedInId}?page=${page}&limit=10`
      );
      console.log("response.data.videos.videos: ", response.data.videos.videos);
      setData(response.data.videos.videos);
      setTotalVideos(response.data.videos.totalVideos);
      setTotalPages(response.data.videos.totalPages);
      setCurrentPage(response.data.videos.page);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (loggedInId) {
      fetchData(1);
    }
  }, [loggedInId]);

  const handlePageChange = (page) => {
    fetchData(page);
  };

  return (
    <div className={classes.root}>
      <CreatedVideos
        direction="auto"
        fixedHeaderScrollHeight="300px"
        pagination
        responsive
        subHeaderAlign="right"
        subHeaderWrap
        columns={columns}
        data={data}
        title={"Published Videos"}
        theme="dark"
        customStyles={customStyles}
        paginationServer
        paginationTotalRows={totalVideos}
        paginationPerPage={10}
        paginationCurrentPage={currentPage}
        onChangePage={handlePageChange}
        progressPending={loading}
      />
    </div>
  );
};

export default CreateDashboard;
