export const BACKEND_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BACKEND_URL_LIVE
    : process.env.REACT_APP_BACKEND_URL_DEV;
export const API = `${BACKEND_URL}/api`;
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;
export const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;
// export const UPLOAD_SOCKET_URL = process.env.REACT_APP_UPLOAD_SOCKET_URL;
// export const STREAM_SOCKET_URL = process.env.REACT_APP_STREAM_SOCKET_URL;
export const UPLOAD_SOCKET_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BACKEND_URL_LIVE
    : process.env.REACT_APP_BACKEND_URL_DEV;
export const STREAM_SOCKET_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BACKEND_URL_LIVE
    : process.env.REACT_APP_BACKEND_URL_DEV;
export const XUMM_SOCKET_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BACKEND_URL_LIVE
    : process.env.REACT_APP_BACKEND_URL_DEV;
