import React, { useEffect, useState } from "react";
import { makeStyles, Button, Typography } from "@material-ui/core";
import "react-day-picker/dist/style.css";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import { API } from "../../config/config";

// import TextField from "../Inputs/TextField";

const useStyles = makeStyles((theme) => ({
  xummSignInBody: {
    display: "block",
    opacity: 0,
    color: "white !important",
    maxHeight: "0px",

    "&.show": {
      transition: "opacity 1s ease-in-out",
      opacity: 1,
      maxHeight: "fit-content",
    },
  },
  signInModalParagraph: {
    transition: "all 0.2s ease-in-out 0s",
    fontWeight: 400,
    lineHeight: "140%",
    color: "rgb(142, 142, 147)",
    fontSize: "0.875rem",
    padding: "0.2rem",
    textAlign: "center",
  },
  inputField: {
    backgroundColor: "lightgrey",
    border: "0px",
    borderRadius: "10px",
    padding: "10px",
    width: "100%",
    zIndex: 1000,
  },
  inputLabel: {
    padding: "10px",
    width: "100%",
  },
  datePicker: {
    width: "100%",
    "& .MuiTextField-root": {
      width: "100%",
    },
    "& .MuiInputBase-input": {
      color: "white",
    },
    "& .MuiOutlinedInput-root": {
      width: "100%",
    },
    "& .MuiSvgIcon-root": {
      color: "white",
    },
  },
  button: {
    alignSelf: "center",
    width: "100%",
    backgroundColor: "rgb(0, 119, 247, 0.2)",
    color: "rgb(0, 119, 247)",
    "&:hover": {
      color: "white",
      backgroundColor: "rgb(0, 119, 247) !important",
    },
  },
  textField: {
    width: "100%",
    "& .MuiInputBase-formControl": {
      backgroundColor: "#1c1c1c !important",
      borderRadius: "12px",
      "&:hover fieldset": {
        borderColor: "#1c1c1c !important",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#1c1c1c !important",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: 12,
    },
  },
}));

const ConnectEmail = ({ show, onSelect, onSuccess }) => {
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState("");

  const handleSignIn = () => {
    onSelect("SignIn");
  };

  const handleSignUp = () => {
    onSelect("SignUp");
  };

  const [formData, setFormData] = useState({
    emailAddress: "",
    password: "",
    confirmPassword: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    try {
      const response = await fetch(`${API}/auth/email/sign-up`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: formData.emailAddress,
          password: formData.password,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        onSuccess(data);
      } else {
        // Handle errors, e.g., show an error message or update the state
        const data = await response.json();
        setErrorMessage(data.error);
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(
        "An error occurred while submitting the form. Please try again later."
      );
    }
  };

  const classes = useStyles();
  useEffect(() => {}, []);

  return (
    <div className={`${classes.xummSignInBody} ${show ? "show" : ""}`}>
      <br />
      {errorMessage && (
        <Typography color="error" variant="body2">
          {errorMessage}
        </Typography>
      )}
      <form onSubmit={handleSubmit}>
        <div>
          <div className={classes.inputLabel}>Email Address*</div>
          <TextField
            required
            id="emailAddress"
            name="emailAddress"
            variant="outlined"
            value={formData.emailAddress}
            onChange={handleChange}
            className={classes.textField}
          />
        </div>

        <div>
          <div className={classes.inputLabel}>Password*</div>
          <TextField
            required
            type="password"
            id="password"
            name="password"
            variant="outlined"
            value={formData.password}
            onChange={handleChange}
            className={classes.textField}
          />
        </div>

        <div>
          <div className={classes.inputLabel}>Confirm Password*</div>
          <TextField
            required
            id="confirmPassword"
            name="confirmPassword"
            variant="outlined"
            type="password"
            value={formData.confirmPassword}
            onChange={handleChange}
            className={classes.textField}
          />
        </div>
        <br />
        <Button
          type="submit"
          className={classes.button}
          variant="contained"
          color="primary"
        >
          Sign Up
        </Button>
      </form>
      <br />

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button variant="text" color="primary" onClick={handleSignIn}>
          Login here
        </Button>
      </div>
    </div>
  );
};

export default ConnectEmail;
