import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { AccountBalanceWalletOutlined as AccountIcon } from "@material-ui/icons";
import LoginModal from "./Nav/TopNav/LoginModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightToArc } from "@fortawesome/pro-duotone-svg-icons";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  signButton: {
    margin: "0",
    //breakpoint at 900px
    [theme.breakpoints.up("md")]: {
      margin: "0 0 0 20px",
    },
    padding: "7px 20px",
    fontSize: "14px",
    fontWeight: 600,
    color: theme.palette.button.alternate.text,
    backgroundColor: theme.palette.button.alternate.background,
    borderRadius: "25px",
    textTransform: "capitalize",
    border: "none",
    boxShadow: "0px",
    "&:hover": {
      boxShadow: "none",
      border: "none",
      backgroundColor: theme.palette.button.alternate.backgroundHover,
    },
    "&:active": {
      border: "none",
    },
  },
}));

export default function SignInBtn() {
  const history = useHistory();

  const classes = useStyles();
  // const [loginModalOpen, setLoginModalOpen] = useState(false);

  const handleClick = () => {
    // setLoginModalOpen(true);
  };
  const handleClose = () => {
    // setLoginModalOpen(false);
  };

  return (
    <>
      <Button
        disableElevation
        disableFocusRipple
        disableRipple
        disableTouchRipple
        className={classes.signButton}
        endIcon={<FontAwesomeIcon icon={faArrowRightToArc} size="xl" />}
        onClick={() => history.push("/signin")}
        //onClick={handleClick}
      >
        Sign In
      </Button>
      {/* {loginModalOpen &&
        <LoginModal isOpen={loginModalOpen} onClose={handleClose} />
      } */}
    </>
  );
}
