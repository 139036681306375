import React, { useState, useEffect, useRef } from "react";
//import ReactPlayer from 'react-player';
import "../../../node_modules/video-react/dist/video-react.css"; // import css
import { Player, ControlBar, BigPlayButton, LoadingSpinner } from "video-react";
import Skeleton from "@mui/material/Skeleton";
import { makeStyles, Button } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import LikeDislikes from "../LikeDislikes";
import { useSelector, useDispatch } from "react-redux";
import { getVideoComments } from "../../redux/actions/comments";
import { api } from "../../utils/api";
import io from "socket.io-client";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ShareModal } from "../../components/Modals/shareModal";
import { CommentModal } from "../../components/Modals/commentModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/pro-solid-svg-icons";
import { faComments } from "@fortawesome/pro-solid-svg-icons";
import { STREAM_SOCKET_URL } from "../../config/config";

const useStyles = makeStyles((theme) => ({
  player: {
    aspectRatio: 9 / 16,
    height: "80vh",
    borderRadius: "10px",
    "& .video-react-video": {
      borderRadius: "10px",
    },
    "& .video-react-poster": {
      borderRadius: "10px",
    },
  },
  text: {
    fontWeight: 400,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  subTitle: {
    color: grey[700],
  },
  pointer: {
    cursor: "pointer",
  },
  primaryInfo: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  clipTitle: {
    position: "absolute",
    marginTop: "-50px",
    fontFamily: "Basier Square",
    fontWeight: 700,
    paddingLeft: "10px",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%", // restrict the width to 100% of the parent
  },
  secondaryInfo: {
    display: "flex",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  secondaryInfo_1: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  secondaryInfo_2: {
    display: "flex",
    width: "100%",
    marginBottom: "10px",
  },
  channel: {
    fontWeight: 500,
  },
  secondaryInfo_3: {
    paddingLeft: theme.spacing(1),
    lineHeight: "80%",
  },
  likeSubscribe: {
    display: "flex",
    marginLeft: "auto",
  },
  clipOptions: {
    display: "flex",
    width: "60px",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
  shareBtn: {
    //borderRadius: "50%",
    //margin: "5px",
    // height: "40px",
    // width: "40px",

    color: "#9E9E9E",
    "&:hover": {
      backgroundColor: "inherit",
      color: "#FFFFFF",
    },
  },
}));

const isSocketConnected = (socket) => {
  return socket && socket.connected;
};

// Function to show notification when Wi-Fi disconnects using react-toastify
function showPaymentNotification() {
  toast.info("Payment period starting soon.", {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 8000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "dark",
    // progressStyle: {
    //   backgroundColor: 'your_color_here', // Add the desired color here
    // },
    style: {
      backgroundColor: "#1B1B1B", // Replace with your desired background color
    },
  });
}

export default function ClipContent({ videoId }) {
  const [shareModalOpen, setShareModalOpen] = React.useState(false);
  const handleShareModalOpen = () => setShareModalOpen(true);
  const handleShareModalClose = () => setShareModalOpen(false);

  const [commentModalOpen, setCommentModalOpen] = React.useState(false);
  const handleCommentModalOpen = () => setCommentModalOpen(true);
  const handleCommentModalClose = () => setCommentModalOpen(false);

  const [video, setVideo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [numberOfSubscribers, setSubscribers] = useState(0);

  const comments = useSelector(({ comments }) => comments);
  const dispatch = useDispatch();
  const cookies = document.cookie.split("; ");
  const jwt_auth = cookies.find((cookie) => cookie.startsWith("jwt_auth="));
  const playerRef = useRef(null);
  let currentVideo = null;
  let paymentNotification = false;

  let videoFreePeriod = -1;
  let videoPrice = {};

  useEffect(() => {
    const socket = io(STREAM_SOCKET_URL, {
      auth: {
        jwt_auth: `${jwt_auth}`,
      },
      path: "/videoPlayback",
    });

    socket.on("message", (data) => {
      console.log("Received data:", data);
    });

    // Periodic function to check for socket connection and send video playback info
    const updatePlaybackInfo = () => {
      if (playerRef.current) {
        if (isSocketConnected(socket) && currentVideo != null) {
          const currentTime = playerRef.current.getState().player.currentTime;
          socket.emit("videoPlaybackInfo", { videoId, currentTime });
        }
      }
    };

    // Set up the interval to call periodicFunction
    const playbackInfoInterval = setInterval(updatePlaybackInfo, 5000); // Check every 5 seconds

    const fetchVideoContent = async () => {
      try {
        const response = await api.get(`/api/videos/${videoId}`);
        setVideo(response.data);
        setIsLoading(false);
        currentVideo = response.data;
        await api.patch(`/api/videos/${videoId}`, { updateViews: true });
        const {
          data: { subscribers },
        } = await api.post(`/api/subscriptions/count`, {
          channel: video.video.channelId,
        });
        setSubscribers(subscribers || 0);
      } catch (err) {
        console.log(err);
      }
    };

    const fetchVideoPrice = async () => {
      try {
        const response = await api.get(
          `/api/videos/price/${video.video.videoFreePeriod}`
        );
        if (response.data.videoPrice != null) {
          videoPrice = response.data.videoPrice;
          videoFreePeriod = response.data.videoPrice.freePeriodSeconds;
        }
      } catch (err) {
        console.log(err);
      }
    };

    const paymentNotificationCheck = async () => {
      if (
        videoFreePeriod > 0 &&
        currentVideo != null &&
        playerRef.current.getState().player.currentTime > videoFreePeriod &&
        paymentNotification === false
      ) {
        showPaymentNotification();
        paymentNotification = true;
      }
    };

    setInterval(paymentNotificationCheck, 1000);

    dispatch(getVideoComments(videoId));
    fetchVideoContent();
    fetchVideoPrice();
    return () => {
      clearInterval(playbackInfoInterval); // Clear the interval
      socket.disconnect(); // Disconnect the socket
      // Any other cleanup operations can go here
    };
  }, [videoId]);

  const classes = useStyles();

  const handleVideoEvent = (eventType) => {
    console.log(`User performed action: ${eventType}`);
  };

  const handleSeek = (time) => {
    const currentTime = playerRef.current.video.currentTime;
    console.log("User seeks to:", currentTime);
  };

  return (
    <div>
      {isLoading ? (
        //Show pre loader
        <div>
          <Skeleton
            style={{ backgroundColor: "#222222" }}
            variant="rectangular"
            width={"100%"}
            className={classes.player}
          />
        </div>
      ) : (
        <>
          <ShareModal
            isOpen={shareModalOpen}
            onClose={handleShareModalClose}
            videoRoute={videoId}
          />
          <CommentModal
            isOpen={commentModalOpen}
            onClose={handleCommentModalClose}
            videoId={videoId}
          />

          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ position: "relative", flexGrow: 1 }}>
              {/* Your existing content */}
              <Player
                width={"100%"}
                autoPlay={true}
                ref={playerRef}
                className={classes.player}
                fluid={false}
                poster={video.video.thumbnailLink}
                src={video.video.videoLink}
                onPlay={() => handleVideoEvent("play")}
                onPause={() => handleVideoEvent("pause")}
                onEnded={() => handleVideoEvent("ended")}
              >
                <LoadingSpinner />
                <BigPlayButton position="center" />
                <ControlBar disableDefaultControls={true} />
              </Player>
              {video.video ? (
                <div className={classes.clipTitle}>{video.video.title}</div>
              ) : (
                <div>
                  <br />
                  <Skeleton
                    variant="text"
                    sx={{
                      fontSize: "3rem",
                      backgroundColor: "#222222",
                      width: "50%",
                    }}
                  />
                  <br />
                </div>
              )}
            </div>
            <div className={classes.clipOptions}>
              <div style={{ marginLeft: "auto" }}>
                <LikeDislikes
                  type="video"
                  id={videoId}
                  videoId={videoId}
                  orientation="vertical"
                />
              </div>
              <Button
                onClick={handleCommentModalOpen}
                className={classes.shareBtn}
              >
                <FontAwesomeIcon
                  icon={faComments}
                  style={{ fontSize: "1.4rem" }}
                />
              </Button>

              <Button
                onClick={handleShareModalOpen}
                className={classes.shareBtn}
              >
                <FontAwesomeIcon
                  icon={faShare}
                  style={{ fontSize: "1.4rem" }}
                />
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
