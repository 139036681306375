import React, { useEffect, useState } from "react";
import { formatDistanceToNow } from "date-fns";
import {
  Avatar,
  makeStyles,
  Typography,
  Tab,
  Tabs,
  Container,
  Button,
} from "@material-ui/core";
import clsx from "clsx";
import {
  faSackDollar,
  faArrowRightToArc,
  faClock,
  faMoneyBill1Wave,
} from "@fortawesome/pro-duotone-svg-icons";
import { toast } from "react-toastify";
import Transactions, { createTheme } from "react-data-table-component";
import "react-toastify/dist/ReactToastify.css";
import {
  checkCreatorPayoutPermission,
  requestCreatorPayoutPermission,
  getChannelBalance,
  getWithdraws,
} from "../../../action/creatorPayoutsAction";
import { CreatorPayoutModal } from "../../Modals/creatorPayoutModal";
import Lozenge from "@atlaskit/lozenge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRight as faArrowUpRightSolid,
  faUpRightAndDownLeftFromCenter as faUpRightAndDownLeftFromCenterSolid,
} from "@fortawesome/pro-solid-svg-icons";
import { CashoutDetailModal } from "../../Modals/cashoutDetailModal";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  container: {
    width: "100%",
    maxWidth: "800px",
    padding: 20,
    backgroundColor: "black",
    border: "1px solid #262626",
  },
  requestButton: {
    margin: "0",
    //breakpoint at 900px
    [theme.breakpoints.up("md")]: {
      margin: "0 0 0 20px",
    },
    padding: "7px 20px",
    fontSize: "14px",
    fontWeight: 600,
    color: theme.palette.button.alternate.text,
    backgroundColor: theme.palette.button.alternate.background,
    borderRadius: "25px",
    textTransform: "capitalize",
    border: "none",
    boxShadow: "0px",
    "&:hover": {
      boxShadow: "none",
      border: "none",
      backgroundColor: theme.palette.button.alternate.backgroundHover,
    },
    "&:active": {
      border: "none",
    },
  },
  text: {
    paddingBottom: theme.spacing(2),
  },
  subContainer: {
    width: "100%",
    padding: "10px 0px 10px 0px",
  },
  subHeading: {
    fontSize: "14px",
    textTransform: "uppercase",
    opacity: 0.7,
    padding: "8px 20px 10px 20px",
  },
  viewTransaction: {
    border: "0px solid",
    backgroundColor: "rgb(194,5,6, 0.3)",
    color: "red",
    padding: "10px",
    borderRadius: "10px",
    marginRight: "10px",
    fontWeight: "600",
  },
  button: {
    alignSelf: "center",
    fontSize: "12px",
    marginLeft: "5px",
    marginRight: "5px",
    backgroundColor: "rgb(0, 119, 247, 0.2)",
    color: "rgb(0, 119, 247)",
    "&:hover": {
      color: "white",
      backgroundColor: "rgb(0, 119, 247) !important",
    },
  },
  root_1: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  root_2: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
}));
const CircleItem = ({ children, title }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }}
  >
    <div
      style={{
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        display: "flex",
        padding: "5px",
        justifyContent: "center",
        backgroundColor: "#282828",
      }}
    >
      {children}
    </div>
    {title && <div>{title}</div>}
  </div>
);

const customStyles = {
  tableHead: {
    style: {
      fontWeight: "700",
    },
  },
  rows: {
    style: {},
  },
  headCells: {
    style: {
      fontWeight: "700",
      fontSize: "17px",
    },
  },
  cells: {
    style: {
      fontWeight: "600",
      fontSize: "14px",
    },
  },
};

const CreatorPayout = () => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [isCashoutDetailModalOpen, setIsCashoutDetailModalOpen] =
    useState(false);
  const [selectedCashoutForDetailModal, setSelectedCashoutForDetailModal] =
    useState({});
  const [usdBalance, setUsdBalance] = useState(0); // State for storing the channel's USD balance
  const [open, setOpen] = React.useState(false);
  const [permissionStatus, setPermissionStatus] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const handleOpenCreatorPayoutModal = () => {
    setOpen(true);
  };
  const handleCloseCreatorPayoutModal = () => setOpen(false);

  const handleOpenCashoutDetailModal = (cashoutDetails) => {
    setSelectedCashoutForDetailModal(cashoutDetails);
    setIsCashoutDetailModalOpen(true);
  };

  const handleCloseCashoutDetailModal = () => {
    setSelectedCashoutForDetailModal({});
    setIsCashoutDetailModalOpen(false);
  };

  const columns = [
    {
      name: "Withdraw Method",
      grow: 2,
      selector: (row) => row.withdrawMethod,
    },
    {
      name: "USD Amount",
      grow: 1,
      selector: (row) => `$${row.usdWithdrawAmount.toFixed(2)}`,
    },
    {
      name: "Date",
      grow: 1,
      selector: (row) =>
        formatDistanceToNow(new Date(row.createdAt), { addSuffix: true }),
    },
    {
      name: "Status",
      grow: 1,
      cell: (row) => getStatusLozenge(row.status),
    },
    {
      grow: 1,
      cell: (row) => (
        <>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => handleOpenCashoutDetailModal(row)}
          >
            <FontAwesomeIcon
              icon={faUpRightAndDownLeftFromCenterSolid}
              size="lg"
            />
          </Button>
        </>
      ),
    },
  ];

  const getStatusLozenge = (status) => {
    switch (status) {
      case "Completed":
        return <Lozenge appearance="success">{status}</Lozenge>;
      case "Failure":
        return <Lozenge appearance="removed">{status}</Lozenge>;
      case "Pending":
        return <Lozenge appearance="moved">{status}</Lozenge>;
      default:
        return <Lozenge>{status}</Lozenge>;
    }
  };

  const fetchCreatorPayouts = async () => {
    try {
      let response = await getWithdraws();
      console.log(response);

      setTransactions(response);
    } catch (error) {
      console.error("Failed to fetch payouts:", error);
      toast.error("Failed to fetch payouts.");
    }
  };

  const handleRequestPermission = async () => {
    try {
      console.log("handleRequestPermission");

      let response = await requestCreatorPayoutPermission();

      toast.success("Permission request submitted successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      // Optionally, you can refetch the permission status to update the UI
      const status = await checkCreatorPayoutPermission();
      setPermissionStatus(status);
    } catch (error) {
      console.error("Failed to request creator payout permission:", error);
      toast.error("Failed to request permission");
    }
  };

  const fetchBalance = async () => {
    try {
      const { usdBalance } = await getChannelBalance(); // Destructure usdBalance from the response
      setUsdBalance(usdBalance); // Update state with fetched balance
    } catch (error) {
      console.error("Failed to fetch channel balance:", error);
      toast.error("Failed to fetch balance");
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const fetchPermissionStatus = async () => {
      setIsLoading(true);
      try {
        const status = await checkCreatorPayoutPermission();
        setPermissionStatus(status);
      } catch (error) {
        console.error(
          "Failed to fetch creator payout permission status:",
          error
        );
        // Optionally handle the error, e.g., by setting an error state
      } finally {
        setIsLoading(false);
      }
    };

    fetchBalance();
    fetchPermissionStatus();
    fetchCreatorPayouts();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  let content;
  switch (permissionStatus) {
    case "Approved":
      content = (
        <div className={classes.root}>
          <CashoutDetailModal
            isOpen={isCashoutDetailModalOpen}
            onClose={handleCloseCashoutDetailModal}
            //parsedWithdraw={selectedCashoutForDetailModal}
            withdrawId={selectedCashoutForDetailModal._id}
          />
          <div className={classes.container}>
            <CreatorPayoutModal
              balance={usdBalance.toFixed(2)}
              isOpen={open}
              onClose={handleCloseCreatorPayoutModal}
              onSuccess={fetchCreatorPayouts}
            />
            <Typography
              variant="h5"
              style={{
                fontWeight: "600",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              Creator Payouts
              <span style={{ float: "right" }}>
                <div style={{ justifyContent: "center", display: "flex" }}>
                  <h5 style={{ fontWeight: 600, paddingRight: "20px" }}>
                    Balance: ${usdBalance.toFixed(2)}
                  </h5>
                </div>
              </span>
            </Typography>

            <span style={{ display: "flex", width: "100%" }}>
              <div className={classes.subHeading} style={{ width: "70%" }}>
                View your recent payouts
              </div>
              <div style={{ float: "right" }}>
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  className={classes.requestButton}
                  endIcon={
                    <FontAwesomeIcon icon={faMoneyBill1Wave} size="xl" />
                  }
                  onClick={handleOpenCreatorPayoutModal} // Add this line
                >
                  Request payout
                </Button>
              </div>
            </span>
            <hr style={{ backgroundColor: "white" }} />
            <Transactions
              direction="auto"
              fixedHeaderScrollHeight="300px"
              pagination
              paginationServer
              responsive
              subHeaderAlign="right"
              subHeaderWrap
              columns={columns}
              data={transactions}
              paginationTotalRows={totalCount}
              onChangePage={handlePageChange}
              theme="dark"
              customStyles={customStyles}
            />
          </div>
        </div>
      );
      break;
    case "Not approved":
      content = (
        <Container className={classes.root_1}>
          <FontAwesomeIcon
            icon={faSackDollar}
            size="4x"
            className={classes.SignInIcon}
          />
          <Typography variant="h4" align="center" className={classes.text}>
            {"Apply for creator payouts"}
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            className={classes.text}
          >
            {
              "Once you have applied for creator payouts and have been approved you will be able to claim revenue from your content."
            }
          </Typography>
          <Button
            disableElevation
            disableFocusRipple
            disableRipple
            disableTouchRipple
            className={classes.requestButton}
            endIcon={<FontAwesomeIcon icon={faArrowRightToArc} size="xl" />}
            onClick={handleRequestPermission} // Add this line
          >
            Request Payout Permission
          </Button>
        </Container>
      );
      break;
    case "Requested":
    default:
      content = (
        <Container className={classes.root_1}>
          <FontAwesomeIcon
            icon={faClock}
            size="4x"
            className={classes.SignInIcon}
          />
          <Typography variant="h4" align="center" className={classes.text}>
            {"Creator payouts pending"}
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            className={classes.text}
          >
            {
              "You have already applied for creator payouts please wait until approved. Once you have applied for creator payouts and have been approved you will be able to claim revenue from your content."
            }
          </Typography>
        </Container>
      );
      break;
  }

  return content;
};

export default CreatorPayout;
