import React from "react";
import {
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core/";
import featuredVideoHero from "../../assets/image/MorganJay.webp";
import { Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/pro-duotone-svg-icons";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  featuredVideoInfo: {
    height: "calc(100% - 100px)",
    zIndex: 1,
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "50px",
  },
  featuredVideoDescription: {
    maxWidth: "350px",
  },
  heroBanner: {
    width: "100%",
    position: "relative",
    "&::-webkit-media-controls": {
      display: "none",
    },
    "&::-webkit-media-controls-enclosure": {
      display: "none",
    },
    "&::-webkit-media-controls-panel": {
      display: "none",
    },
    "&::-webkit-media-controls-play-button": {
      display: "none",
    },
    "&::-webkit-media-controls-start-playback-button": {
      display: "none",
    },
  },
  overlay: {
    backgroundColor: "transparent",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,8%,0) 0,hsla(0,0%,8%,.15) 10%,hsla(0,0%,8%,.35) 29%,hsla(0,0%,8%,.58) 40%,#121212 90%,#121212)",
    backgroundPosition: "0 top",
    backgroundRepeat: "repeat-x",
    backgroundSize: "100% 100%",
    bottom: "-1px",
    height: "100px",
    opacity: 1,
    top: "auto",
    width: "100%",
    position: "relative",
    marginTop: "-100px",
  },
  playButton: {
    margin: "0",
    //breakpoint at 900px
    [theme.breakpoints.up("md")]: {
      margin: "0 0 0 20px",
    },
    padding: "7px 20px",
    fontSize: "14px",
    fontWeight: 600,
    color: theme.palette.button.alternate.text,
    backgroundColor: theme.palette.button.alternate.background,
    borderRadius: "10px",
    textTransform: "capitalize",
    border: "none",
    boxShadow: "0px",
    "&:hover": {
      boxShadow: "none",
      border: "none",
      backgroundColor: theme.palette.button.alternate.backgroundHover,
    },
    "&:active": {
      border: "none",
    },
  },
  viewChannelButton: {
    marginLeft: "20px",
    background: "#fff3",
    borderRadius: "10px",
    fontWeight: 600,
    textTransform: "none",
    padding: "7px 20px",
  },
  featuredVideoButtonsOuter: {
    paddingTop: "30px",
  },
}));

const FeaturedVideo = ({ hero }) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div style={{ position: "relative", minHeight: "600px" }}>
      <div className={classes.featuredVideoInfo}>
        <Typography
          variant={isMobile ? "h4" : "h2"}
          style={{ fontWeight: 700, fontStyle: "italic" }}
        >
          No Ads,
          <br />
          No Monthly Subscriptions
        </Typography>
        <div className={classes.featuredVideoDescription}>
          Experience the comedic genius of Morgan Jay as he fearlessly explores
          the absurdities of everyday life, effortlessly blending music,
          storytelling, and razor-sharp wit.
        </div>
        <div className={classes.featuredVideoButtonsOuter}>
          <Button
            disableElevation
            disableFocusRipple
            disableRipple
            disableTouchRipple
            className={classes.playButton}
            endIcon={<FontAwesomeIcon icon={faPlay} size="xl" />}
            onClick={() => history.push("/watch?v=669da3becf87ed371d75906f")}
          >
            Play
          </Button>
          <Button
            disableElevation
            disableFocusRipple
            disableRipple
            disableTouchRipple
            className={classes.viewChannelButton}
            onClick={() => history.push("/channel/668cb9b5dbd1847d787b0ff4")}
          >
            View Channel
          </Button>
        </div>
      </div>
      <video className={classes.heroBanner} poster={featuredVideoHero}>
        <source src="movie.mp4" type="video/mp4" />
      </video>
      <div className={classes.overlay}></div>
    </div>
  );
};

export default FeaturedVideo;
