import React, { useEffect } from "react";
import { makeStyles, Container, Typography } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux";
import { Box } from '@material-ui/core';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';




const useStyles = makeStyles((theme) => ({

    xummSignInBody: {
        display: 'none',
        opacity: 0,
        color: 'white !important',
        maxHeight: '0px',

        "&.show": {
            display: 'block',
            transition: 'opacity 1s ease-in-out',
            opacity: 1,
            maxHeight: 'fit-content',
        }
    },
    signInModalParagraph: {
        transition: 'all 0.2s ease-in-out 0s',
        fontWeight: 400,
        lineHeight: '140%',
        color: 'rgb(142, 142, 147)',
        fontSize: '0.875rem',
        padding: '0.2rem',
        textAlign: 'center',
    },
    qrCodeWrapper: {
        margin: "25px 0",
        display: "flex",
        justifyContent: "center",
        background: '#404040',
        padding: '40px',
        borderRadius: '20px',
    },
    metamaskSpacer: {
        paddingTop: '110px',
    },
    ethAccount: {
        opacity: 0.8,
        fontSize: '14px',
        wordBreak: 'break-all'
    },
    connectedWalletLabel: {
        fontWeight: 600,

    }

}));



const ConnectMetamask = ({ show, qrExposed, qrCode, updateMsg }) => {
    const dispatch = useDispatch();

    const classes = useStyles();
    useEffect(() => {

    }, []);

    if (updateMsg === "Metamask plugin not detected") {
        return (
            <div className={`${classes.xummSignInBody} ${show ? "show" : ""}`}>
                <div className={classes.signInModalParagraph} style={{ paddingBottom: '20px' }}>
                    <span style={{ fontWeight: "600", fontSize: "16px" }}>METAMASK</span>
                </div>
                <>
                    <Alert severity="error">{updateMsg}</Alert>
                    <br />
                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                        <Link href='https://metamask.io/download/' target='_blank' style={{ color: 'white' }}>Download Metamask</Link>
                    </span>
                </>

            </div>
        )
    }

    if (updateMsg === "Loading") {
        return (
            <div className={`${classes.xummSignInBody} ${show ? "show" : ""}`}>
                <div className={classes.signInModalParagraph} style={{ paddingBottom: '20px' }}>
                    <span style={{ fontWeight: "600", fontSize: "16px" }}>METAMASK</span>
                </div>
                <div className={classes.metamaskSpacer}>

                    <div className={classes.signInModalParagraph}>
                        <span style={{ fontWeight: "600", fontSize: "16px", color: 'white' }}>Waiting for signature
                            <span style={{ paddingTop: '10px', paddingLeft: '10px' }}><CircularProgress style={{ width: '16px', height: '16px' }} disableShrink /></span>
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={`${classes.xummSignInBody} ${show ? "show" : ""}`}>
            <div className={classes.signInModalParagraph} style={{ paddingBottom: '20px' }}>
                <span style={{ fontWeight: "600", fontSize: "16px" }}>METAMASK</span>
            </div>
            <hr style={{ backgroundColor: 'white' }} />
            <Typography variant="h6" className={classes.connectedWalletLabel}>Connected wallet
            </Typography>
            <span className={classes.ethAccount}>{updateMsg}</span>
        </div>
    );
};

export default ConnectMetamask;
