import { api } from "../../utils/api";

const setModal = (isOpen) => {
  return {
    type: "SET_MODAL",
    payload: { isOpen },
  };
};

const setLoading = (isLoading) => {
  return {
    type: "SET_LOADING",
    payload: { isLoading },
  };
};

const setVideoFile = (filename) => {
  return {
    type: "SET_VIDEO_FILE",
    payload: { filename },
  };
};

const setIPFSHash = (hash) => {
  return {
    type: "SET_IPFS_HASH",
    payload: { hash },
  };
};

const setVideoId = (videoId) => {
  return {
    type: "SET_VIDEO_ID",
    payload: { videoId },
  };
};

const setThumbnails = (thumbnails) => {
  return {
    type: "SET_THUMBNAILS",
    payload: { thumbnails },
  };
};

const setThumbnail = (thumbnailFilename) => {
  return {
    type: "SET_THUMBNAIL",
    payload: { thumbnailFilename },
  };
};

const setThumbnailPath = (thumbnailPath) => {
  return {
    type: "SET_THUMBNAIL_PATH",
    payload: { thumbnailPath },
  };
};

const setDetails = (details) => {
  return {
    type: "SET_DETAILS",
    payload: { details },
  };
};

const setVisibility = (visibility) => {
  return {
    type: "SET_VISIBILITY",
    payload: { visibility },
  };
};

const setMonetisation = (monetisation) => {
  return {
    type: "SET_MONETISATION",
    payload: { monetisation },
  };
};

const setUploadProfileImagePath = (uploadProfileImagePath) => {
  return {
    type: "SET_UPLOAD_PROFILE_IMAGE_PATH",
    payload: { uploadProfileImagePath },
  };
};

const resetUpload = () => {
  return {
    type: "RESET_UPLOAD",
  };
};

const uploadVideo = (file, socketid, ipfsStore) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      let formData = new FormData();
      const config = {
        header: { "content-type": "multipart/form-data" },
      };
      formData.append("file", file);
      formData.append("socketid", socketid);
      formData.append("ipfsStore", ipfsStore);
      const { data: video } = await api.post("/api/videos", formData, config);
      const filename = video.filename;
      dispatch(setVideoFile(filename));
      dispatch(setIPFSHash(video.hash));
      dispatch(setVideoId(video.videoId));
    } catch (err) {
      dispatch(setVideoFile(null));
      dispatch(setIPFSHash(null));
      dispatch(setVideoId(null));

      dispatch(setThumbnails(null));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

const uploadThumbnail = (file) => {
  return async (dispatch) => {
    //dispatch(setLoading(true));
    try {
      let formData = new FormData();
      const config = {
        header: { "content-type": "multipart/form-data" },
      };
      formData.append("file", file);
      const { data } = await api.post(
        "/api/videos/thumbnails",
        formData,
        config
      );
      dispatch(setThumbnails(data.filename));
      dispatch(setThumbnailPath(data.url));
    } catch (err) {
      dispatch(setVideoFile(null));
      dispatch(setThumbnails(null));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

const uploadProfileImage = (file) => {
  return async (dispatch) => {
    //dispatch(setLoading(true));
    try {
      let formData = new FormData();
      const config = {
        header: { "content-type": "multipart/form-data" },
      };
      formData.append("file", file);
      formData.append("fileType", "profile"); // add title to formData
      const { data } = await api.post("/api/channels/image", formData, config);
      dispatch(setUploadProfileImagePath(data.url));
    } catch (err) {
    } finally {
    }
  };
};

const setVisibilitySubmit = (visibility) => {
  return async (dispatch) => {
    dispatch(setVisibility(parseInt(visibility)));
    dispatch(uploadVideoInfo());
  };
};

const uploadVideoInfo = () => {
  return async (dispatch, getState) => {
    const {
      thumbnailFilename,
      details,
      visibility,
      filename,
      hash,
      videoId,
      thumbnailFilePath,
      thumbnails,
      monetisation,
    } = getState().upload;

    const { xrpAddress } = getState().channel;

    try {
      //console.log("thumbnails: " + thumbnails);
      await api.post("/api/videos/upload", {
        filename,
        xrpAddress,
        videoId,
        thumbnailFilename: thumbnails,
        visibility,
        thumbnailFilePath,
        hash,
        ...details,
        ...monetisation,
      });
      dispatch(resetUpload());
    } catch (err) {
      console.log(err);
    }
  };
};

export {
  setModal,
  resetUpload,
  uploadVideo,
  setThumbnail,
  setThumbnails,
  setDetails,
  setMonetisation,
  setVisibility,
  uploadVideoInfo,
  setVisibilitySubmit,
  setThumbnailPath,
  uploadThumbnail,
  uploadProfileImage,
  setUploadProfileImagePath,
  setIPFSHash,
  setVideoId,
};
