import React, { useState, useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-http-source-selector/dist/videojs-http-source-selector.css"; // Import the plugin CSS
import httpSourceSelector from "videojs-http-source-selector"; // Import the plugin
import Skeleton from "@mui/material/Skeleton";
import { makeStyles, Typography, Divider } from "@material-ui/core";
import NumAbbr from "number-abbreviate";
import moment from "moment";
import clsx from "clsx";
import { grey } from "@material-ui/core/colors";
import LikeDislikes from "../LikeDislikes";
import { useSelector, useDispatch } from "react-redux";
import { getVideoComments } from "../../redux/actions/comments";
import { api } from "../../utils/api";
import io from "socket.io-client";
import { STREAM_SOCKET_URL, API } from "../../config/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { VideoPaymentModal } from "../Modals/videoPaymentModal";
import { getVideoWeb3Settings } from "../../action/videoWeb3SettingsAction";
import Lozenge from "@atlaskit/lozenge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/pro-regular-svg-icons";
//import "videojs-http-streaming";
//import useConsoleErrorLogger from "../../error/useConsoleErrorLogger"; // Adjust the path as necessary

// Register the plugin with Video.js
videojs.registerPlugin("httpSourceSelector", httpSourceSelector);

const useStyles = makeStyles((theme) => ({
  player: {
    aspectRatio: 16 / 9,
    height: "inherit !important",
    width: "100%",
  },
  text: {
    fontWeight: 400,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  subTitle: {
    color: grey[700],
  },
  pointer: {
    cursor: "pointer",
  },
  primaryInfo: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  secondaryInfo: {
    display: "flex",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  secondaryInfo_1: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  secondaryInfo_2: {
    display: "flex",
    width: "100%",
    marginBottom: "10px",
  },
  channel: {
    fontWeight: 500,
  },
  secondaryInfo_3: {
    paddingLeft: theme.spacing(1),
    lineHeight: "80%",
  },
  likeSubscribe: {
    display: "flex",
    marginLeft: "auto",
  },
}));

const isSocketConnected = (socket) => {
  return socket && socket.connected;
};

function showPaymentNotification() {
  toast.info("Payment period starting soon.", {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 8000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "dark",
    style: {
      backgroundColor: "#1B1B1B", // Replace with your desired background color
    },
  });
}

function showPaymentSuccess() {
  toast.info("Authorisation Success!", {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "dark",
    style: {
      backgroundColor: "#1B1B1B", // Replace with your desired background color
    },
  });
}

function showPaymentFailure() {
  toast.info("Authorisation Failed!", {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "dark",
    style: {
      backgroundColor: "#1B1B1B", // Replace with your desired background color
    },
  });
}

export default function VideoContent({ videoId }) {
  const [video, setVideo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [numberOfSubscribers, setSubscribers] = useState(0);
  const [videoStop, setVideoStop] = useState(false);
  const [isVideoPaymentModalOpen, setIsVideoPaymentModalOpen] = useState(false);
  const [videoPriceObj, setVideoPriceObj] = useState({});
  const [videoWeb3Settings, setVideoWeb3Settings] = useState({});
  const comments = useSelector(({ comments }) => comments);
  const loggedInChannelId = useSelector(({ channel }) => channel.id);

  const dispatch = useDispatch();
  const playerRef = useRef(null);
  const videoRef = useRef(null);
  let currentVideo = null;
  const paymentPermissionRef = useRef(false);
  const selectedPaymentTypeRef = useRef("");
  let videoFreePeriod = -1;
  let videoPrice = {};
  let paymentNotification = false;
  let payPeriodChecked = false;
  let player = null;
  const socketRef = useRef(null); // Ref to hold the socket instance

  const [error, setError] = useState(null);

  const videoPeriodStartRef = useRef(0.0);
  const videoPeriodEndRef = useRef(0.0);

  const handleCloseVideoPaymentModal = () => setIsVideoPaymentModalOpen(false);
  const handleOpenVideoPaymentModel = () => setIsVideoPaymentModalOpen(true);

  function convertSecondsToMinutes(seconds) {
    let minutes = Math.floor(seconds / 60);
    let remainingSeconds = seconds % 60;
    let formattedSeconds = remainingSeconds.toString().padStart(2, "0");
    return `${minutes}:${formattedSeconds}`;
  }

  const handleVideoPaymentSelection = async (
    message,
    selectedPaymentMethod
  ) => {
    selectedPaymentTypeRef.current = selectedPaymentMethod;

    let paymentProcessorSuccess = await paymentProcessor();
    if (paymentProcessorSuccess === true) {
      paymentPermissionRef.current = true;
      handleCloseVideoPaymentModal();
      videoRef.current.play();
      showPaymentSuccess();
    } else {
      showPaymentFailure();
      paymentPermissionRef.current = false;
      handleOpenVideoPaymentModel();
    }
  };

  const paymentProcessor = async (automaticCheck) => {
    try {
      if (video?.video?.uploader === loggedInChannelId) {
        return true;
      }

      videoPeriodEndRef.current = playerRef.current.currentTime() + 15;

      const response = await axios.post(
        `${API}/payment/video`,
        {
          videoId: videoId,
          videoPeriodStart: videoPeriodStartRef.current,
          videoPeriodEnd: videoPeriodEndRef.current,
          checkNFTPass: selectedPaymentTypeRef.current === "NFT",
          nftOnly: selectedPaymentTypeRef.current === "NFT",
        },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      videoPeriodStartRef.current = videoPeriodEndRef.current;

      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (automaticCheck === true) {
        showPaymentFailure();
        if (videoRef.current) {
          videoRef.current.pause();
        }
        paymentPermissionRef.current = false;
        handleOpenVideoPaymentModel();
      }
      return false;
    }
  };

  const fetchVideoWeb3Settings = async () => {
    let web3Settings = await getVideoWeb3Settings(videoId);
    setVideoWeb3Settings(web3Settings);
  };

  const handleVideoPaymentCancel = async () => {
    handleCloseVideoPaymentModal();
    if (videoRef.current) {
      videoRef.current.pause();
    }
    setVideoStop(true);
  };

  useEffect(() => {
    const fetchVideoContent = async () => {
      try {
        const response = await api.get(`/api/videos/${videoId}`);
        setVideo(response.data);
        setIsLoading(false);
        currentVideo = response.data;
        await api.patch(`/api/videos/${videoId}`, { updateViews: true });
        const {
          data: { subscribers },
        } = await api.post(`/api/subscriptions/count`, {
          channel: response.data.video.channelId,
        });
        setSubscribers(subscribers || 0);
      } catch (err) {
        console.error("Error fetching video content:", err);
      }
    };

    dispatch(getVideoComments(videoId));
    fetchVideoContent();
    fetchVideoWeb3Settings();
    return () => {};
  }, [videoId]);

  const classes = useStyles();

  useEffect(() => {
    if (video && video.video && videoRef.current) {
      let videoNode = videoRef.current;
      player = videojs(videoNode, {
        controls: true,
        autoplay: false,
        preload: "auto",
        sources: [
          {
            src: video.video.videoHLSLink,
            type: "application/x-mpegURL",
          },
        ],
      });
      playerRef.current = player;
    }

    const fetchVideoPlayerToken = async () => {
      try {
        const response = await axios.get(`${API}/videos/videoPlayerToken`, {
          withCredentials: true,
        });
        return response.data.videoPlayerToken;
      } catch (error) {
        console.error("Error fetching video player token:", error);
        return null;
      }
    };

    const initializeWebSocket = async () => {
      const videoPlayerToken = await fetchVideoPlayerToken();
      // if (!videoPlayerToken) {
      //   console.error("Failed to fetch video player token");
      //   return;
      // }

      const socket = io(STREAM_SOCKET_URL, {
        auth: {
          videoPlayerToken: `${videoPlayerToken}`,
        },
        path: "/videoPlayback",
      });

      socketRef.current = socket; // Store the socket instance in the ref

      socket.on("message", (data) => {
        //console.log("Received data:", data);
      });

      const updatePlaybackInfo = () => {
        if (isSocketConnected(socket) && player && videoRef.current) {
          const currentTime = player.currentTime();
          socket.emit("videoPlaybackInfo", { videoId, currentTime });
        }
      };

      const fetchVideoPrice = async () => {
        try {
          const response = await api.get(`/api/videos/price/${videoId}`);
          if (response.data.videoPrice != null) {
            videoPrice = response.data.videoPrice;
            setVideoPriceObj(videoPrice);
            videoFreePeriod = response.data.videoPrice.freePeriodSeconds;
          }
        } catch (err) {
          console.error("Error fetching video price:", err);
        }
      };

      const checkPayPeriod = async () => {
        if (player) {
          if (
            player &&
            payPeriodChecked === false &&
            videoPrice.price > 0 &&
            videoPrice.paymentType !== "Free" &&
            player.currentTime() + 15 >= videoFreePeriod
          ) {
            if ((await verifyVideoPayment(player.currentTime())) === false) {
              handleOpenVideoPaymentModel();
              player.pause();
              payPeriodChecked = true;
            }
          }
        }
      };

      const verifyVideoPayment = async (videoPeriodStart) => {
        try {
          let videoPeriodEnd = videoPeriodStart + 15;

          const response = await axios.post(
            `${API}/payment/video/verify`,
            {
              videoId: videoId,
              videoPeriodStart: videoPeriodStart,
              videoPeriodEnd: videoPeriodEnd,
              checkNFTPass: true,
              nftOnly: false,
            },
            {
              withCredentials: true,
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          return response.status === 200 ? true : false;
        } catch (error) {
          return false;
        }
      };

      const videoPayment = async () => {
        if (videoPrice.price > 0 && videoPrice.paymentType !== "Free") {
          if (
            playerRef.current.currentTime() >= videoFreePeriod &&
            paymentPermissionRef.current === true &&
            selectedPaymentTypeRef.current !== ""
          ) {
            paymentProcessor(true);
          } else if (
            playerRef.current.currentTime() >= videoFreePeriod &&
            (paymentPermissionRef.current === false ||
              selectedPaymentTypeRef.current === "") &&
            payPeriodChecked === false
          ) {
            //Check if user has paid if they have then dont show modal

            if (
              (await verifyVideoPayment(playerRef.current.currentTime())) ===
              false
            ) {
              handleOpenVideoPaymentModel();
              if (videoRef.current) {
                videoRef.current.pause();
              }
            }
          }
        }
      };

      const playbackInfoInterval = setInterval(updatePlaybackInfo, 5000);
      let videoPaymentInterval = setInterval(videoPayment, 5000);
      let checkPayPeriodInterval = setInterval(checkPayPeriod, 3000);

      checkPayPeriod();
      fetchVideoPrice();

      return () => {
        clearInterval(videoPaymentInterval);
        clearInterval(playbackInfoInterval);
        clearInterval(checkPayPeriodInterval);

        socket.disconnect();

        if (player) {
          player.dispose();
        }
      };
    };

    initializeWebSocket();
  }, [video]);

  return (
    <div>
      <video
        ref={videoRef}
        className={`${classes.player} video-js vjs-default-skin`}
        controls
      ></video>
      {isLoading ? (
        <div></div>
      ) : (
        <>
          <ToastContainer />
          <VideoPaymentModal
            isOpen={isVideoPaymentModalOpen}
            onClose={handleCloseVideoPaymentModal}
            onPaymentSelection={handleVideoPaymentSelection}
            onCancel={handleVideoPaymentCancel}
            videoId={videoId}
            videoWeb3Settings={videoWeb3Settings}
            videoPrice={videoPriceObj}
          />
        </>
      )}

      {video && video.video ? (
        <>
          <div className={classes.primaryInfo}>
            <Typography variant="h6" className={classes.text}>
              {video.video.title}
              {JSON.stringify(videoPriceObj) !== "{}" &&
                videoPriceObj.paymentType !== "free" && (
                  <span style={{ paddingLeft: "10px" }}>
                    <Lozenge appearance="moved">
                      <FontAwesomeIcon icon={faStar} size="sm" />
                      {`Premium From ${convertSecondsToMinutes(
                        videoPriceObj.freePeriodSeconds
                      )}`}
                    </Lozenge>
                  </span>
                )}
            </Typography>
            <LikeDislikes
              type="video"
              id={video.video.id}
              videoId={video.video.id}
            />
            <Typography
              variant="body2"
              className={clsx(classes.text, classes.subTitle)}
            >
              {new NumAbbr().abbreviate(video.video.views, 2)} views •{" "}
              {moment(video.video.createdAt).format("MMM Do YYYY")} •{" "}
              {comments.length} comments
            </Typography>
            <Divider />
          </div>
          <div>
            <Typography>{video.video.description}</Typography>
          </div>
        </>
      ) : (
        <div>
          <br />
          <Skeleton
            variant="text"
            sx={{ fontSize: "3rem", backgroundColor: "#222222", width: "50%" }}
          />
          <Skeleton
            variant="text"
            sx={{ fontSize: "1.2rem", backgroundColor: "#222222" }}
          />
          <Skeleton
            variant="text"
            sx={{ fontSize: "1.2rem", backgroundColor: "#222222" }}
          />
          <br />
        </div>
      )}
    </div>
  );
}
