import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Container, Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    backgroundColor: "#000000",
  },
  section: {
    marginBottom: theme.spacing(2),
    backgroundColor: "#000000",
  },
  title: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    fontWeight: "bold",
    marginTop: theme.spacing(2),
    backgroundColor: "#000000",
  },
  content: {
    marginBottom: theme.spacing(1),
    backgroundColor: "#000000",
  },
}));

const TermsOfService = () => {
  const classes = useStyles();

  useEffect(() => {
    document.title = "Terms of Service";
  }, []);

  return (
    <Container className={classes.root}>
      <Paper elevation={3} style={{ backgroundColor: "#000000" }}>
        <Box p={3}>
          <Typography variant="h4" className={classes.title}>
            Terms and Conditions
          </Typography>

          <div className={classes.section}>
            <Typography variant="h6" className={classes.subtitle}>
              1. Acceptance and Term
            </Typography>
            <Typography className={classes.content}>
              1.1 You accept this Agreement by the earlier of:
              <br />
              (a) signing and returning this Agreement to us, including by email
              or any electronic executions platform acceptable to us;
              <br />
              (b) confirming by email or any other platform that you accept this
              Agreement; or
              <br />
              (c) commencing the supply of any part of the Services.
            </Typography>
            <Typography className={classes.content}>
              1.2 This Agreement will operate for the Term.
            </Typography>
          </div>

          <div className={classes.section}>
            <Typography variant="h6" className={classes.subtitle}>
              2. Engagement
            </Typography>
            <Typography className={classes.content}>
              2.1 During the Term, you agree to perform the Services for the
              Price:
              <br />
              (a) in accordance with this Agreement and all applicable laws;
              <br />
              (b) with due care, skill and diligence;
              <br />
              (c) in a proper and professional manner, and in accordance with
              best industry practice; and
              <br />
              (d) in accordance with our reasonable instructions or
              requirements.
            </Typography>
            <Typography className={classes.content}>
              2.2 We may request that you perform additional services and if you
              agree to perform such additional services, the Parties will agree
              the price and scope of the services which will form part of the
              Price and Services respectively.
            </Typography>
          </div>

          <div className={classes.section}>
            <Typography variant="h6" className={classes.subtitle}>
              3. Your Responsibilities
            </Typography>
            <Typography className={classes.content}>
              3.1 You agree to:
              <br />
              (a) perform the Services within the timeframes as notified by us
              to you;
              <br />
              (b) not commit any act or omission, directly or indirectly, which
              may bring us (or our Personnel or clients) into breach of any law
              or result in us being the subject of any Liability;
              <br />
              (c) not do or omit to do anything which brings us into contempt,
              disrepute or otherwise damages or diminishes our reputation or our
              brand including the Platform;
              <br />
              (d) comply with the provisions of this Agreement and all
              applicable laws (including the Australian Association of
              Advertisers’ Code of Ethics);
              <br />
              (e) comply with any of our reasonable policies and procedures as
              notified by us to you;
              <br />
              (f) promptly provide us with all information in connection with
              the Services and this Agreement that we may reasonably request;
              and
              <br />
              (g) not provide any Content which is defamatory, violent or
              illegal.
            </Typography>
          </div>

          <div className={classes.section}>
            <Typography variant="h6" className={classes.subtitle}>
              4. Warranties and Representations
            </Typography>
            <Typography className={classes.content}>
              4.1 You represent, warrant and agree that:
              <br />
              (a) each part of the Services will be suitable and fit for
              purpose;
              <br />
              (b) your digital asset wallet has and is not being investigated by
              any regulatory authority;
              <br />
              (c) your digital asset wallet or address is not related to any
              governments, persons or jurisdictions under a US embargo enforced
              by the Office of Foreign Assets Control or under an Australian
              embargo enforced or regulated by an Australian government
              regulator or agency, or any persons who are named on any list of
              sanctioned individuals or entities;
              <br />
              (d) you are authorised to use the digital asset wallet address
              provided by you;
              <br />
              (e) the Content will not infringe any person’s rights and you have
              obtained all rights, consents and licences necessary for you to
              license us the Existing Content and New Content under this
              Agreement;
              <br />
              (f) that any opinions and views stated by you in the Content are
              genuinely held by you;
              <br />
              (g) without limiting any of our other rights, you agree to
              promptly re-perform and re-supply any part of the Services that is
              not performed or provided to us in accordance with this Agreement,
              or, to refund us any amounts paid by us for it;
              <br />
              (h) we may create non-fungible tokens in relation to the usage of
              the Content to reward users of the Platform and that to the
              maximum extent permitted by law;
              <br />
              (i) you are our independent contractor, are not our employee, and
              accordingly, are not entitled to any employee benefits;
              <br />
              (j) you agree to effect and maintain (at a minimum) all insurances
              required by law in order for you to provide us with the Services;
              and
              <br />
              (k) if applicable, you are registered for GST or taxation purposes
              and you have a valid ABN or business registration number which has
              been advised to us.
            </Typography>
          </div>

          <div className={classes.section}>
            <Typography variant="h6" className={classes.subtitle}>
              5. Price and Payment
            </Typography>
            <Typography className={classes.content}>
              5.1 Subject to you providing the Services in accordance with this
              Agreement and providing us with an invoice, we agree to pay you
              the Price (or the relevant part thereof), in accordance with the
              Payment Terms.
              <br />
              5.2 Any payment made by us in respect of any invoice does not
              necessarily mean we approve of the Services provided.
            </Typography>
          </div>

          <div className={classes.section}>
            <Typography variant="h6" className={classes.subtitle}>
              6. Intellectual Property
            </Typography>
            <Typography className={classes.content}>
              6.1 You retain ownership rights in your Content. However, we do
              require you to grant certain rights to Videomaster. You grant us a
              non-exclusive, worldwide, sub-licensable right and transferable
              license to use that Content (including to reproduce, distribute,
              create derivative works including non-fungible tokens, display and
              perform it) the Content for any purpose contemplated under this
              agreement.
            </Typography>
            <Typography className={classes.content}>
              6.2 As between the Parties and subject to the terms of this
              Agreement, the Parties retain all Intellectual Property Rights in
              their respective Intellectual Property developed prior to or
              independently of this Agreement and nothing in this Agreement
              constitutes a transfer or assignment of such rights.
            </Typography>
            <Typography className={classes.content}>
              6.3 In the performance of the Services, or the use of any
              Intellectual Property Rights in connection with this Agreement,
              you must not commit any Intellectual Property Breach.
            </Typography>
            <Typography className={classes.content}>
              6.4 Despite anything to the contrary, to the maximum extent
              permitted by law, you are liable for, and agree to indemnify us
              and hold us harmless in respect of, any Liability that we may
              suffer, incur or otherwise become liable for, arising from or in
              connection with you infringing the rights of any third party
              (including Intellectual Property Rights).
            </Typography>
            <Typography className={classes.content}>
              6.5 This clause 6 will survive termination or expiry of this
              Agreement.
            </Typography>
          </div>

          <div className={classes.section}>
            <Typography variant="h6" className={classes.subtitle}>
              7. Confidentiality and Privacy
            </Typography>
            <Typography className={classes.content}>
              7.1 Subject to clause 7.2, the Parties must keep confidential, and
              not use or permit any unauthorised use of, all Confidential
              Information.
            </Typography>
            <Typography className={classes.content}>
              7.2 Clause 7.1 does not apply where the disclosure is required by
              law or the disclosure is to a professional adviser in order to
              obtain advice in relation to matters arising in connection with
              this Agreement and provided that a Party ensures the adviser
              complies with the terms of clause 7.1.
            </Typography>
            <Typography className={classes.content}>
              7.3 The Parties agree to comply with any privacy laws that may
              apply to the relevant Party or the performance of the Services.
            </Typography>
            <Typography className={classes.content}>
              7.4 This clause 7 will survive the termination or expiry of this
              Agreement.
            </Typography>
          </div>

          <div className={classes.section}>
            <Typography variant="h6" className={classes.subtitle}>
              8. Term and Termination
            </Typography>
            <Typography className={classes.content}>
              8.1 Either Party may terminate this Agreement at any time by
              giving 10 days’ notice in writing to the other Party.
              <br />
              8.2 We may terminate this Agreement if you:
              <br />
              (a) do or say anything which, in our reasonable opinion, could or
              does bring our brand including the Platform into disrepute; or
              <br />
              (b) breach a material term of this Agreement, and that breach has
              not been remedied within 5 business days of us notifying you of
              the breach.
            </Typography>
            <Typography className={classes.content}>
              8.3 You may terminate this Agreement if we breach a material term
              of this Agreement, and that breach has not been remedied within 5
              business days of you notifying us of the breach.
            </Typography>
            <Typography className={classes.content}>
              8.4 Upon expiry or termination of this Agreement, you agree to:
              <br />
              (a) immediately stop providing the Services and return to us all
              of our property (including any of our Confidential Information and
              any of our Intellectual Property) in your possession; and
              <br />
              (b) where this Agreement is terminated by us under clause 8.2, you
              also agree to pay us our additional costs arising from, or in
              connection with, such termination.
            </Typography>
            <Typography className={classes.content}>
              8.5 Subject to clause 5.2, upon expiry or termination of this
              Agreement, we will pay to you that portion of the Price payable to
              you for any Services properly provided by you in accordance with
              this Agreement, up until the date of expiry or termination.
            </Typography>
            <Typography className={classes.content}>
              8.6 Termination of this Agreement will not affect any rights or
              liabilities that a Party has accrued under it.
            </Typography>
            <Typography className={classes.content}>
              8.7 This clause 8 will survive the termination or expiry of this
              Agreement.
            </Typography>
          </div>

          <div className={classes.section}>
            <Typography variant="h6" className={classes.subtitle}>
              9. General
            </Typography>
            <Typography className={classes.content}>
              9.1 Disputes and governing law: This Agreement is governed by the
              laws of New South Wales, Australia. If a dispute arises, the
              Parties will agree to meet in good faith to seek to resolve the
              issue, before proceeding to any dispute resolution process. This
              will not prevent a Party from seeking urgent equitable relief.
            </Typography>
            <Typography className={classes.content}>
              9.2 GST: If and when applicable, GST payable on the Price will be
              set out in your invoice. Subject to you complying with this
              Agreement, we agree to pay the GST amount at the same time as we
              pay the Price.
            </Typography>
            <Typography className={classes.content}>
              9.3 Severance: If a provision of this Agreement is held to be
              void, invalid, illegal or unenforceable, that provision is to be
              read down as narrowly as necessary to allow it to be valid or
              enforceable, failing which, that provision (or that part of that
              provision) will be severed from this Agreement without affecting
              the validity or enforceability of the remainder of that provision
              or the other provisions in this Agreement.
            </Typography>
            <Typography className={classes.content}>
              9.4 Subcontracting: We have chosen to engage you because of your
              specialist skills and so you agree to not subcontract the
              provision of any part of the Services.
            </Typography>
          </div>

          <div className={classes.section}>
            <Typography variant="h6" className={classes.subtitle}>
              10. Interpretation and Definitions
            </Typography>
            <Typography className={classes.content}>
              10.1 In this Agreement, unless the context otherwise requires,
              capitalised terms have the meaning given to them in the Schedule,
              within these terms and conditions and:
              <br />
              Agreement means this Content Creator Agreement and all schedules
              (including the Schedule), annexures and attachments included, or
              referred to, in this Content Creator Agreement.
              <br />
              Commission means the fee paid to you for user consumption of the
              New Content in accordance with a time-based rate or pay-per-view.
              <br />
              Confidential Information includes information which:
              <br />
              (a) is disclosed to the Receiving Party in connection with this
              Agreement at any time;
              <br />
              (b) is prepared or produced under or in connection with this
              Agreement at any time;
              <br />
              (c) relates to the Disclosing Party’s business, assets or affairs;
              or
              <br />
              (d) relates to the subject matter of, the terms of and/or any
              transactions contemplated by this Agreement, whether or not such
              information or documentation is reduced to a tangible form or
              marked in writing as “confidential”, and howsoever you receive
              that information.
              <br />
              Content means Exclusive Content and the New Content.
              <br />
              Disclosing Party means the Party disclosing Confidential
              Information to the Receiving Party.
              <br />
              GST has the meaning which it is given in the A New Tax System
              (Goods and Services Tax) Act 1999 (Cth).
              <br />
              Intellectual Property means any copyright, registered or
              unregistered designs, patents or trade mark rights, domain names,
              know-how, inventions, processes, trade secrets or Confidential
              Information, circuit layouts, software, computer programs,
              databases or source codes (including any application, or right to
              apply, for registration of, and any improvements, enhancements or
              modifications of, the foregoing).
              <br />
              Intellectual Property Breach means any breach by you of any of our
              Intellectual Property Rights or of any third party rights
              (including any Intellectual Property Rights of third parties),
              including, but not limited, to you:
              <br />
              (a) copying, altering, enhancing, adapting or modifying any of our
              Intellectual Property;
              <br />
              (b) creating derivative works from our Intellectual Property;
              <br />
              (c) providing or disclosing our Intellectual Property to, or
              allowing our Intellectual Property to be used by, any third party;
              <br />
              (d) assigning or transferring any of our Intellectual Property
              Rights or granting sublicenses of any of our Intellectual Property
              Rights, except as expressly permitted in this Agreement;
              <br />
              (e) reverse engineering or decompiling any of our Intellectual
              Property Rights, except where permitted by law; or
              <br />
              (f) using or exploiting our Intellectual Property for purposes
              other than as expressly stated in this Agreement (including,
              without limitation, using our Intellectual Property for commercial
              purposes or on-selling our Intellectual Property to third
              parties).
              <br />
              Intellectual Property Rights means, for the duration of the rights
              in any part of the world, any industrial or intellectual property
              rights, whether registrable or not, including in respect of
              Intellectual Property.
              <br />
              Liability means any expense, cost, liability, loss, damage, claim,
              notice, entitlement, investigation, demand, proceeding or judgment
              (whether under statute, contract, equity, tort (including
              negligence), indemnity or otherwise), howsoever arising, whether
              direct or indirect and/or whether present, unascertained, future
              or contingent and whether involving a third party, a Party or
              otherwise.
              <br />
              Receiving Party means the Party receiving Confidential Information
              from the Disclosing Party.
              <br />
              Schedule means the schedule to which this Agreement is attached.
              <br />
              Services means all activities, work or services to be performed by
              you under this Agreement, as further particularised in the
              Schedule, and includes any other activities, work, services or
              deliverables not particularised in the Schedule or this Agreement
              that are otherwise reasonable, necessary or incidental to the
              proper performance of the Services.
              <br />
              Term has the meaning given to it in the Schedule.
            </Typography>
          </div>
        </Box>
      </Paper>
    </Container>
  );
};

export default TermsOfService;
