import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { CloudUpload as UploadIcon } from "@material-ui/icons";
import urlJoin from "url-join";
import { useHistory } from "react-router-dom";

import { setModal, resetUpload } from "../../../redux/actions/upload";
import { BACKEND_URL } from "../../../config";
import UploadModal from "../../Upload/UploadModal";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: theme.palette.iconBg,
    },
    "&:focus": {
      outline: theme.palette.iconBg,
    },
  },
}));

const NavVideoMenuBtn = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isModalOpen = useSelector(({ upload }) => upload.isOpen);
  const isAuth = useSelector(({ channel }) => channel.isAuth);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorBtnRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleUploadClick = () => {
    history.push("/create/video");

    // handleToggle();
    // if (isAuth) {
    //   dispatch(setModal(true));
    // } else {
    //   window.location.assign(urlJoin(BACKEND_URL, "/api/auth/google"));
    // }
  };

  const handleModalClose = () => {
    dispatch(resetUpload());
  };

  return (
    <>
      <IconButton
        ref={anchorBtnRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        className={classes.iconButton}
        onClick={handleUploadClick}
      >
        <UploadIcon />
      </IconButton>
      {isModalOpen && (
        <UploadModal isOpen={isModalOpen} handleClose={handleModalClose} />
      )}
    </>
  );
};

export default NavVideoMenuBtn;
