import React, { useEffect, useState } from "react";
import NumAbbr from "number-abbreviate";
import { grey } from "@material-ui/core/colors";
import Skeleton from "@mui/material/Skeleton";
import {
  Avatar,
  makeStyles,
  Box,
  Typography,
  Tab,
  Tabs,
  Grid,
} from "@material-ui/core";
import SubscribeBtn from "../SubscribeBtn";
import VideoGrid from "../Video/VideoGrid";
import TabPanel from "../TabPanel";
import clsx from "clsx";
import { api } from "../../utils/api";
import { Assignment as Clipboard } from "@material-ui/icons";
import ReadMore from "../misc/ReadMore";
import { styled } from "@mui/material/styles";
import Link from "@mui/material/Link";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    flex: 1,
  },
  header: {
    backgroundColor: theme.palette.background.default,
    paddingBottom: theme.spacing(0),
  },
  subscriptionContent: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    width: "100%",
    height: "100%",
  },
  avatar: {
    width: "150px",
    height: "150px",
    margin: "20px",
    border: `5px solid ${theme.palette.background.default}`,
  },
  info_1: {
    display: "flex",
    flexWrap: "wrap",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textAlign: "center",
    width: "50%",
    margin: "10px",
  },
  info_2: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 2,
    marginTop: "30px",
    maxWidth: "66%",
  },
  info_3: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    padding: theme.spacing(1),
  },
  subscribeBtn: {
    marginTop: "10px",
  },
  clipBoard: {
    cursor: "pointer",
  },
  channelInfo: {
    margin: "10px 0",
  },
  channelHeaderInner: {
    display: "flex",
    justifyContent: "center",
  },
  channelHeaderGrid: {
    width: "50% !important",
    maxWidth: "50% !important",
  },
  avatarOuter: {
    flexGrow: 1,
  },
  banner: {
    objectFit: "cover",
    minHeight: "125px",
    height: "calc((100vw - 240px)/6.2 - 1px)",
    width: "100%",
  },
  channelInfoHeader: {
    flexShrink: 1,
    minWidth: 0,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    position: "relative",
  },
  usernameCol: {
    textDecoration: "none",
    display: "inline",
    flexShrink: "1",
    paddingLeft: 0,
  },
  subscribeCol: {
    paddingLeft: "20px",
  },
  bioCol: {},
  spacer1: {
    display: "block",
    marginBottom: "15px",
  },
  dividerOuter: {
    display: "flex",
    justifyContent: "center",
  },
  dividerInner: {
    backgroundColor: "grey",
    width: "100%",
    maxWidth: "1400px",
    margin: "0px",
  },
}));

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    height: "2px",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 100,
    width: "100%",
    backgroundColor: "#FBB900",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "rgba(255, 255, 255, 0.7)",
    "&.Mui-selected": {
      color: "#fff",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

const ChannelPage = ({ match }) => {
  const { id: channelId } = match.params;
  const [channel, setChannel] = useState(null);
  const [videos, setVideos] = useState([]);
  const [numberOfSubscribers, setSubscribers] = useState(0);
  //const [value, setValue] = useState(1);
  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //const [walletId, setWalletId] = useState('rmWjskejwioejtlwksjgkdnsgJMDMEMDjsgk');

  const handleSubscriptionChange = (change) => {
    setSubscribers((prevCount) => prevCount + change);
  };

  useEffect(() => {
    const fetchChannel = async () => {
      try {
        const { data } = await api.get(`/api/channels/${channelId}`);

        //Check if bannerImage or profile has value if not assign them a default value

        setChannel({
          id: data.id,
          xrpAddress: data.xrpAddress,
          profileImage: data.profileImage,
          bannerImage: data.bannerImage,
          username: data.username,
          bio: data.bio,
        });
        const {
          data: { subscribers },
        } = await api.post(`/api/subscriptions/count`, {
          channel: channelId,
        });
        setSubscribers(subscribers || 0);
        const {
          data: { videos },
        } = await api.get(`/api/videos/channel/${channelId}?limit=-1`);
        console.log("videos.videos: ", videos.videos);
        setVideos(videos.videos);
      } catch (err) {
        console.log(err);
      }
    };
    fetchChannel();
  }, [channelId]);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {channel ? (
          <>
            <div className={classes.channelHeaderInner}>
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                className={classes.channelHeaderGrid}
              >
                <div className={classes.avatarOuter}>
                  <Avatar
                    alt={channel.username}
                    src={channel.profileImage}
                    className={classes.avatar}
                  />
                </div>
                {/* <Typography className={classes.channelInfo} variant="h5" className={classes.channel}> */}
                <div className={classes.info_2}>
                  <div className={classes.channelInfoHeader}>
                    <div className={classes.usernameCol}>
                      <Typography
                        className={classes.channelInfo}
                        variant="h5"
                        style={{ marginTop: "40px !important" }}
                      >
                        {channel.username}
                      </Typography>
                    </div>
                    <div className={classes.subscribeCol}>
                      <SubscribeBtn
                        size="lg"
                        channelId={channelId}
                        className={classes.subscribeBtn}
                        onSubscriptionChange={handleSubscriptionChange}
                      />
                    </div>
                  </div>
                  <div className={classes.spacer1}></div>
                  {numberOfSubscribers === 1 ? (
                    // <Typography className={classes.channelInfo} variant="subtitle" className={clsx(classes.subTitle)}>
                    <Typography variant="subtitle">
                      <b>1</b> subscriber
                    </Typography>
                  ) : (
                    //<Typography className={classes.channelInfo} variant="subtitle" className={clsx(classes.subTitle)}>
                    <Typography variant="subtitle">
                      <b>{new NumAbbr().abbreviate(numberOfSubscribers, 2)} </b>
                      subscribers
                    </Typography>
                  )}
                  {/* <Typography
                    className={classes.channelInfo}
                    variant="subtitle"
                  >
                    {channel.xrpAddress}
                    {" -"} 
                    <Clipboard
                      className={classes.clipBoard}
                      onClick={() =>
                        navigator.clipboard.writeText(channel.xrpAddress)
                      }
                    />
                  </Typography> */}
                  <div className={classes.spacer1}></div>
                  {/* <div className={classes.bioCol}>
                    <ReadMore
                      text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse sit amet velit ac odio consectetur eleifend. In sollicitudin dui et nulla maximus dignissim. Nam ac laoreet augue."
                      maxLength={100}
                    />
                  </div> */}

                  {/* <Typography variant="subtitle">
                    {channel.bio} channel bio
                  </Typography> */}
                </div>
              </Grid>
            </div>

            <div className={classes.spacer1}></div>

            <StyledTabs
              value={value}
              onChange={handleChange}
              aria-label="styled tabs example"
              className="vm-navigation"
              centered={true}
            >
              <StyledTab label="Videos" className="vm-navigation" />
              {/* <StyledTab label="Frames" className="vm-navigation" /> */}
              {/* <StyledTab label="About" className="vm-navigation" /> */}
            </StyledTabs>
            <div className={classes.dividerOuter}>
              <hr className={classes.dividerInner} />
            </div>
          </>
        ) : (
          " "
        )}

        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ maxWidth: "1400px", width: "100%" }}>
            {value === 0 && (
              // content to show when first tab is selected
              <Box className={classes.subscriptionContent}>
                {videos.length ? (
                  <VideoGrid type="vertical_1" videos={videos} />
                ) : (
                  "No videos found"
                )}
              </Box>
            )}
            {/* {value === 1 && (
              // content to show when second tab is selected
              <Box className={classes.subscriptionContent}>
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <Skeleton
                      sx={{ bgcolor: "grey.900" }}
                      variant="rectangular"
                      height={400}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Skeleton
                      sx={{ bgcolor: "grey.900" }}
                      variant="rectangular"
                      height={400}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Skeleton
                      sx={{ bgcolor: "grey.900" }}
                      variant="rectangular"
                      height={400}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Skeleton
                      sx={{ bgcolor: "grey.900" }}
                      variant="rectangular"
                      height={400}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Skeleton
                      sx={{ bgcolor: "grey.900" }}
                      variant="rectangular"
                      height={400}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <Skeleton
                      sx={{ bgcolor: "grey.900" }}
                      variant="rectangular"
                      height={400}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Skeleton
                      sx={{ bgcolor: "grey.900" }}
                      variant="rectangular"
                      height={400}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Skeleton
                      sx={{ bgcolor: "grey.900" }}
                      variant="rectangular"
                      height={400}
                    />
                  </Grid>
                </Grid>
              </Box>
            )} */}
            {/* {value === 1 && (
              // content to show when third tab is selected
              <Box className={classes.subscriptionContent}>
                <Typography
                  className={classes.channelInfo}
                  style={{ fontWeight: 600, opacity: 0.6 }}
                  variant="h6"
                >
                  Description
                </Typography>
                <Typography variant="subtitle">Sample description.</Typography>
                <div style={{ marginTop: 40, marginBottom: 40 }}></div>
                <Typography
                  className={classes.channelInfo}
                  style={{ fontWeight: 600, opacity: 0.6 }}
                  variant="h6"
                >
                  Stats
                </Typography>
                <Typography variant="subtitle">
                  <b>Joined</b> 20 Apr 2023
                </Typography>
                <div style={{ marginTop: 10, marginBottom: 10 }}></div>
                <Typography variant="subtitle">
                  <b>Views</b> 4,000,323
                </Typography>
                <div style={{ marginTop: 40, marginBottom: 40 }}></div>
                <Typography
                  className={classes.channelInfo}
                  style={{ fontWeight: 600, opacity: 0.6 }}
                  variant="h6"
                >
                  Links
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Link href="#" underline="none">
                      <b>Store</b>
                    </Link>
                  </Grid>
                  <Grid item xs={3}>
                    <Link href="#" underline="none">
                      <b>Instagram</b>
                    </Link>
                  </Grid>
                  <Grid item xs={3}>
                    <Link href="#" underline="none">
                      <b>Twitter</b>
                    </Link>
                  </Grid>
                  <Grid item xs={3}>
                    <Link href="#" underline="none">
                      <b>Facebook</b>
                    </Link>
                  </Grid>
                  <Grid item xs={3}>
                    <Link href="#" underline="none">
                      <b>Snapchat</b>
                    </Link>
                  </Grid>
                  <Grid item xs={3}>
                    <Link href="#" underline="none">
                      <b>TikTok</b>
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChannelPage;
