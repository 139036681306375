import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Toolbar, useMediaQuery, IconButton } from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import { useSelector } from "react-redux";

import { NavLink, useLocation } from "react-router-dom";
import { setDrawer, toggleDrawer } from "../../../redux/actions/layout";
import videoMasterLightLogo from "../../../assets/image/VideoMasterLogoLight.png";
import videoMasterDarkLogo from "../../../assets/image/VideoMasterLogoDark.png";
import { set } from "lodash";

const useStyles = makeStyles((theme) => ({
  nftmasterLogo: {
    marginTop: "8px",
    height: "30px",
    padding: theme.spacing(0, 1),
    [theme.breakpoints.up("md")]: {
      height: "30px",
    },
  },
  toolbar: {
    paddingRight: "0px",
  },
  iconButton: {
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: theme.palette.iconBg,
    },
    "&:focus": {
      outline: theme.palette.iconBg,
    },
  },
}));

const StartNav = ({ mobile, isDarkTheme }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const isMinScreenLg = useMediaQuery(theme.breakpoints.up("lg"));
  const location = useLocation();
  const isDrawerOpen = useSelector(({ layout }) => layout.isDrawerOpen);
  //let toggled = false;

  // switch (location.pathname) {
  //   case "/":
  //     if (!toggled) {
  //       console.log(toggled);
  //       dispatch(setDrawer(true));
  //       toggled = true;
  //         console.log(toggled);
  //     }
  //     break;
  // }

  useEffect(() => {
    //Automatically opens the draw if the screen is large enough
    //if (!mobile) dispatch(setDrawer(isMinScreenLg));
    //console.log('');
    // switch (){
    //   case "";
    // }
  });

  return (
    <Toolbar
      classes={{
        gutters: classes.toolbar,
      }}
    >
      <IconButton
        aria-label="open drawer"
        edge="start"
        className={classes.iconButton}
        onClick={() => dispatch(toggleDrawer())}
      >
        <MenuIcon />
      </IconButton>
      <NavLink to="/">
        <img
          //src={isDarkTheme ? videoMasterDarkLogo : videoMasterLightLogo}
          src={videoMasterDarkLogo}
          className={classes.nftmasterLogo}
          alt="videomaster logo"
        />
      </NavLink>
    </Toolbar>
  );
};

export default StartNav;
