import React, { useEffect, useState } from "react";
import { grey } from "@material-ui/core/colors";
import {
  Avatar,
  makeStyles,
  Box,
  Typography,
  Tab,
  Tabs,
} from "@material-ui/core";
import axios from "axios";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  container: {
    width: "100%",
    maxWidth: "800px",
    padding: 20,
  },
  characterBtn: {
    margin: "10px",
    width: "100%",
    padding: "20px",
    backgroundColor: "#121212",
    display: "block",
    transition: "all 0.2s ease-in-out",
    borderRadius: "10px",
    color: "#fff",
    border: "1px solid #262626",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#111",
      color: "grey",
      textDecoration: "none",
    },
  },
  characterBtnTxt: {
    fontFamily: "Basier Square",
    fontSize: "1.2rem",
    fontWeight: 600,
  },
  characterImg: {
    width: "80px",
    height: "80px",
    borderRadius: "10px",
    margin: "10px",
    objectFit: "cover",
  },
}));

const CharacterSelectionPage = () => {
  const classes = useStyles();
  const [characters, setCharacters] = useState([]);

  useEffect(() => {
    fetchCharacters();
  }, []);

  const fetchCharacters = async () => {
    try {
      const response = await axios.get("/api/character-gpt");
      setCharacters(response.data);
    } catch (error) {
      console.error("Error fetching characters:", error);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography
          variant="h5"
          style={{
            fontWeight: "600",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          Select AI Character
        </Typography>
        <div style={{ marginTop: "20px" }}>
          {characters.map((character) => (
            <Link
              to={`/c/${character._id}`}
              className={classes.characterBtn}
              key={character._id}
              onClick={() => {
                // Handle character selection logic here
                console.log("Selected character:", character);
              }}
            >
              <img
                className={classes.characterImg}
                src={character.characterImage}
                alt={character.characterName}
              />
              <span className={classes.characterBtnTxt}>
                {character.characterName}
              </span>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CharacterSelectionPage;
