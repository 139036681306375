import React, { useEffect, useState } from "react";
import { makeStyles, Button } from "@material-ui/core";

import "react-day-picker/dist/style.css";

// import TextField from "../Inputs/TextField";

const useStyles = makeStyles((theme) => ({
  signInBody: {
    display: "block",
    opacity: 0,
    color: "white !important",
    maxHeight: "0px",

    "&.show": {
      transition: "opacity 1s ease-in-out",
      opacity: 1,
      maxHeight: "fit-content",
    },
  },
  signInModalParagraph: {
    transition: "all 0.2s ease-in-out 0s",
    fontWeight: 400,
    lineHeight: "140%",
    color: "rgb(142, 142, 147)",
    fontSize: "0.875rem",
    padding: "0.2rem",
    textAlign: "center",
  },
  button: {
    alignSelf: "center",
    width: "100%",
    backgroundColor: "rgb(0, 119, 247, 0.2)",
    color: "rgb(0, 119, 247)",
    "&:hover": {
      color: "white",
      backgroundColor: "rgb(0, 119, 247) !important",
    },
  },
  textField: {
    width: "100%",
    "& .MuiInputBase-formControl": {
      backgroundColor: "#1c1c1c !important",
      borderRadius: "12px",
      "&:hover fieldset": {
        borderColor: "#1c1c1c !important",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#1c1c1c !important",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: 12,
    },
  },
}));

const ConnectEmailSelection = ({ show, onSelect }) => {
  const classes = useStyles();

  const handleSignIn = () => {
    onSelect("SignIn");
  };

  const handleSignUp = () => {
    onSelect("SignUp");
  };

  return (
    <div className={`${classes.signInBody} ${show ? "show" : ""}`}>
      <div
        className={classes.signInModalParagraph}
        style={{ paddingBottom: "20px" }}
      >
        <span style={{ fontWeight: "600", fontSize: "16px" }}>EMAIL</span>
      </div>
      <br />
      <Button
        type="submit"
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={handleSignIn}
      >
        Sign In
      </Button>
      <br />
      <br />
      <Button
        type="submit"
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={handleSignUp}
      >
        Sign Up
      </Button>
    </div>
  );
};

export default ConnectEmailSelection;
