import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Typography,
  TextField,
  Avatar,
  Box,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import {
  faArrowRightArrowLeft as faArrowRightArrowLeftDuotone,
  faGears as faGearsDuotone,
  faLinkHorizontal as faLinkHorizontalDuotone,
  faArrowRightFromArc as faArrowRightFromArcDuotone,
  faBell as faBellDuotone,
  faBook as faBookDuotone,
  faUserSecret as faUserSecretDuotone,
} from "@fortawesome/pro-duotone-svg-icons";
import { faMoneyBillWave } from "@fortawesome/pro-regular-svg-icons";
import { logoutChannel } from "../../../redux/actions/channel";
import NavItem from "../NavItem";

const useStyles = makeStyles((theme) => ({
  containerType1: {
    width: 300,
    //backgroundColor: "black",
    // margin: "8px",
    // borderRadius: "20px",
    // padding: 10,
  },
  containerType2: {
    width: "100%",
    height: "50px",
    backgroundColor: "red",
  },
  settingTitle: {
    padding: "20px",
    display: "flex",
    fontWeight: 600,
    fontSize: "1.8rem",
  },
}));

const SettingsMenu = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isAuth = useSelector(({ channel }) => channel.isAuth);

  const handleSignOut = async () => {
    await dispatch(logoutChannel());
    window.location.replace("/");
  };

  useEffect(() => {});

  return (
    <>
      {isAuth && (
        <div className={classes.containerType1}>
          <div className={classes.settingTitle}>Settings</div>
          <NavItem
            to={"/settings/account"}
            title={"Account"}
            icon={faGearsDuotone}
            activeIcon={faGearsDuotone}
          />
          <NavItem
            to={"/settings/transactions"}
            title={"Transactions"}
            icon={faArrowRightArrowLeftDuotone}
            activeIcon={faArrowRightArrowLeftDuotone}
          />
          {/* <NavItem
            to={"/settings/notifications"}
            title={"Notifications"}
            icon={faBellDuotone}
            activeIcon={faBellDuotone}
          /> */}
          <NavItem
            to={"/settings/linked-accounts"}
            title={"Linked Accounts"}
            icon={faLinkHorizontalDuotone}
            activeIcon={faLinkHorizontalDuotone}
          />
          <NavItem
            to={"/settings/creator-payout"}
            title={"Creator Payout"}
            icon={faMoneyBillWave}
            activeIcon={faMoneyBillWave}
          />
          <NavItem
            to={"/terms-of-service"}
            title={"Terms of Service"}
            icon={faBookDuotone}
            activeIcon={faBookDuotone}
          />
          <NavItem
            to={"/privacy-policy"}
            title={"Privacy Policy"}
            icon={faUserSecretDuotone}
            activeIcon={faUserSecretDuotone}
          />
          <NavItem
            to={"/settings/signout"}
            title={"Sign out"}
            icon={faArrowRightFromArcDuotone}
            activeIcon={faArrowRightFromArcDuotone}
            onClick={handleSignOut} // Use the async function to handle sign out
          />
        </div>
      )}
    </>
  );
};

export { SettingsMenu };
