import React, { useEffect, useState } from "react";
import { grey } from "@material-ui/core/colors";
import {
  Avatar,
  makeStyles,
  Typography,
  Tab,
  Tabs,
  Button,
} from "@material-ui/core";
import Modal from "@mui/material/Modal";
import Chip from "@mui/material/Chip";
import IOSSwitch from "../../Inputs/IOSSwitch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRight as faArrowUpRightSolid,
  faUpRightAndDownLeftFromCenter as faUpRightAndDownLeftFromCenterSolid,
} from "@fortawesome/pro-solid-svg-icons";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import FilledInput from "@mui/material/FilledInput";
import { loadStripe } from "@stripe/stripe-js";

import { PaymentModal } from "../../Modals/paymentModal";
import axios from "axios";
import { useLocation } from "react-router-dom";

import Transactions, { createTheme } from "react-data-table-component";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

import Box from "@mui/material/Box";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API } from "../../../config/config";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  container: {
    width: "100%",
    maxWidth: "800px",
    padding: 20,
    backgroundColor: "black",
    border: "1px solid #262626",
  },
  subContainer: {
    width: "100%",
    padding: "10px 0px 10px 0px",
  },
  subHeading: {
    fontSize: "14px",
    textTransform: "uppercase",
    opacity: 0.7,
    padding: "8px 20px 10px 20px",
  },
  viewTransaction: {
    border: "0px solid",
    backgroundColor: "rgb(194,5,6, 0.3)",
    color: "red",
    padding: "10px",
    borderRadius: "10px",
    marginRight: "10px",
    fontWeight: "600",
  },
  button: {
    alignSelf: "center",
    fontSize: "12px",
    marginLeft: "5px",
    marginRight: "5px",
    backgroundColor: "rgb(0, 119, 247, 0.2)",
    color: "rgb(0, 119, 247)",
    "&:hover": {
      color: "white",
      backgroundColor: "rgb(0, 119, 247) !important",
    },
  },
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "#121212",
  border: "1px solid #262626",
  boxShadow: 24,
  p: 4,
  borderRadius: "15px",
};

const customStyles = {
  tableHead: {
    style: {
      fontWeight: "700",
    },
  },
  rows: {
    style: {},
  },
  headCells: {
    style: {
      fontWeight: "700",
      fontSize: "17px",
    },
  },
  cells: {
    style: {
      fontWeight: "600",
      fontSize: "14px",
    },
  },
};

createTheme(
  "dark",
  {
    text: {
      primary: "white",
      fontWeight: "700",
      secondary: "#FBB900",
    },
    background: {
      default: "#111111",
    },

    context: {
      background: "#FBB900",
      text: "#000000",
      fontWeight: "600",
    },
    divider: {
      default: "#262626",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  },
  "dark"
);

const CircleItem = ({ children, title }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }}
  >
    <div
      style={{
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        display: "flex",
        padding: "5px",
        justifyContent: "center",
        backgroundColor: "#282828",
      }}
    >
      {children}
    </div>
    {title && <div>{title}</div>}
  </div>
);

const AccountTransactions = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpenFundPaymentChannel = () => setOpen(true);
  const handleCloseFundPaymentChannel = () => setOpen(false);
  const [stripePromise, setStripePromise] = useState(null);
  const [balance, setBalance] = useState("");
  const [balanceTarget, setBalanceTarget] = useState("");
  const [balancePercentage, setBalancePercentage] = useState(100);
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const [requests, setRequests] = useState(0);

  const getBalance = async () => {
    try {
      const response = await fetch(`${API}/channels/balance`, {
        method: "GET",
        credentials: "include", // <-- Include cookies in the request
        headers: {
          "Content-Type": "application/json",
        },
      });

      // Access the response data
      const { channelId, usdBalance } = await response.json();
      const dollarAmount = Number(usdBalance).toFixed(2);

      setBalance(dollarAmount.toString());

      if (dollarAmount > 100) {
        setBalanceTarget(dollarAmount.toString());
        setBalancePercentage(100);
      } else {
        setBalanceTarget("100");
        setBalancePercentage(dollarAmount);
      }
    } catch (error) {
      // Handle error
      console.error(error);
    }
  };

  const handlePaymentSuccess = async () => {
    // get balance
    await getBalance();
  };

  const handlePaymentFailure = async () => {
    // get balance
    console.log("Payment Failure");
  };

  function PaymentSuccessModal() {
    toast.info("Payment Success!", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "dark",
      style: {
        backgroundColor: "#1B1B1B", // Replace with your desired background color
      },
    });
  }

  function PaymentFailureModal() {
    toast.error("Payment Failure!", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "dark",
      style: {
        backgroundColor: "#1B1B1B", // Replace with your desired background color
      },
    });
  }

  const handleAddFundsUsingCard = async () => {
    const productId = [1];
    const successUrl = "https://example.com/success";
    const cancelUrl = "https://example.com/cancel";

    try {
      const response = await fetch(
        `${API}/payment/stripe/create-checkout-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            productId,
            successUrl,
            cancelUrl,
          }),
        }
      );

      if (response.ok) {
        const session = await response.json();
        //const { sessionId } = await response.json();
        // Load and initialize the Stripe instance
        const stripe = await loadStripe(
          process.env.REACT_APP_STRIPE_PUBLIC_KEY
        );
        if (stripe) {
          // Redirect the user to the checkout page
          const sessionId = session.id;
          stripe.redirectToCheckout({ sessionId });
        } else {
          console.error("Stripe initialization failed");
        }
      } else {
        // Handle server errors
        console.error("Failed to create checkout session");
      }
    } catch (error) {
      // Handle client-side errors
      console.error("Error creating checkout session:", error);
    }
  };

  useEffect(() => {
    const loadStripeInstance = async () => {
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
      setStripePromise(stripe);
    };

    const queryParams = new URLSearchParams(location.search);
    const transactionResult = queryParams.get("transactionResult");

    if (requests === 0) {
      if (transactionResult === "true") {
        PaymentSuccessModal();
        setRequests(1);
      } else if (transactionResult === "false") {
        PaymentFailureModal();
        setRequests(1);
      }
    }

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API}/channels/payments?page=${currentPage}`,
          {
            method: "GET",
            credentials: "include", // <-- Include cookies in the request
          }
        );
        const responseData = await response.json();
        setTotalPages(responseData.totalPages);
        setTotalCount(responseData.totalCount);
        const transactionsData = responseData.payments.map((payment) => {
          let title;
          if (payment.paymentToChannel?.username) {
            title = payment.paymentToChannel.username;
          } else if (payment.paymentType === "TopUp") {
            title = "Account Top Up";
          } else {
            title = "Unknown";
          }

          return {
            id: payment._id,
            img: payment.paymentToChannel?.profileImage || "default-image-url",
            title: title,
            amount: payment.usdAmount.toFixed(2) + " USD",
            year: new Date(payment.createdAt).getFullYear().toString(),
          };
        });

        console.log("transactionsData: ", transactionsData);
        setTransactions(transactionsData);
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    fetchData();
    loadStripeInstance();
    getBalance();
  }, [balance, currentPage, requests]);

  const classes = useStyles();
  const columns = [
    {
      name: "",
      grow: 1,
      cell: (row) => (
        <CircleItem>
          <FontAwesomeIcon icon={faArrowUpRightSolid} size="2x" />
        </CircleItem>
      ),
    },
    {
      name: "To",
      grow: 2,
      selector: (row) => row.title,
    },
    {
      name: "Amount",
      grow: 1,
      selector: (row) => row.amount,
    },
    // {
    //   name: "Actions",
    //   grow: 1,
    //   cell: (row) => (
    //     <>
    //       <Button
    //         className={classes.button}
    //         variant="contained"
    //         color="primary"
    //       >
    //         <FontAwesomeIcon
    //           icon={faUpRightAndDownLeftFromCenterSolid}
    //           size="lg"
    //         />
    //       </Button>
    //     </>
    //   ),
    // },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography
          variant="h5"
          style={{
            fontWeight: "600",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          Fund Account
        </Typography>
        <div className={classes.subHeading}>
          Fund account to watch premium videos and purchase merch
        </div>
        <br />
        <div style={{ display: "flex", justifyContent: "center", padding: 5 }}>
          <span style={{ opacity: 0.7 }}>{balance}</span>&nbsp;/&nbsp;
          {balanceTarget} (USD)
        </div>
        <div style={{ display: "flex", padding: 10 }}>
          <Box sx={{ flexGrow: 1 }}>
            <BorderLinearProgress
              variant="determinate"
              value={balancePercentage}
            />
          </Box>
        </div>
        <div style={{ display: "flex", justifyContent: "center", padding: 10 }}>
          <Button
            onClick={handleOpenFundPaymentChannel}
            className={classes.button}
            variant="contained"
            color="primary"
          >
            Add Funds
          </Button>
          {/* <Button
            onClick={handleAddFundsUsingCard}
            className={classes.button}
            variant="contained"
            color="primary"
          >
            Add Funds using Card
          </Button> */}
        </div>

        <br />
        <Typography
          variant="h5"
          style={{
            fontWeight: "600",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          Transactions
        </Typography>

        <div className={classes.subHeading}>View your purchases</div>
        <hr style={{ backgroundColor: "white" }} />
        <Transactions
          direction="auto"
          fixedHeaderScrollHeight="300px"
          pagination
          paginationServer
          responsive
          subHeaderAlign="right"
          subHeaderWrap
          columns={columns}
          data={transactions}
          paginationTotalRows={totalCount} // Assuming 10 rows per page
          onChangePage={handlePageChange}
          theme="dark"
          customStyles={customStyles}
        />
      </div>
      <PaymentModal
        balance={balance}
        isOpen={open}
        onClose={handleCloseFundPaymentChannel}
        onSuccess={handlePaymentSuccess}
        onFailure={handlePaymentFailure}
      />
    </div>
  );
};

export default AccountTransactions;
