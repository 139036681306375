import React, { useEffect, useState } from "react";
import { grey } from "@material-ui/core/colors";
import {
  Avatar,
  makeStyles,
  Box,
  Typography,
  Tab,
  Tabs,
} from "@material-ui/core";
import UploadModal from "../../Upload/UploadModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartMixed } from "@fortawesome/pro-duotone-svg-icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "60px",
    width: "100%",
  },
}));

const Analytics = () => {
  const classes = useStyles();

  useEffect(() => {});

  return (
    <div className={classes.root}>
      <div style={{ maxWidth: "700px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "80px",
            padding: "10px",
          }}
        >
          <FontAwesomeIcon icon={faChartMixed} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <h2>Analytics Coming Soon!</h2>
        </div>
        <br />
        <p>
          We're hard at work creating an advanced Analytics page that will
          empower you with insightful data and comprehensive reports. Soon,
          you'll be able to track your performance, analyze trends, and make
          informed decisions like never before.
        </p>
      </div>
    </div>
  );
};

export default Analytics;
