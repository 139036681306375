import React, { useEffect, useState } from "react";
import { List, ListItemText, ListItem, makeStyles } from "@material-ui/core";
import NavItem from "../NavItem";
import axios from "axios";
import { BACKEND_URL } from "../../../config";
import { useSelector } from "react-redux";

const api = axios.create({
  withCredentials: true,
  baseURL: BACKEND_URL,
});

const useStyles = makeStyles((theme) => ({
  title: {
    padding: theme.spacing(0, 1),
    fontSize: "1em",
    fontWeight: 500,
    color: theme.palette.background.primary,
  },
}));

const SubscriptionsList = () => {
  const classes = useStyles();
  const userId = useSelector(({ channel }) => channel.xrpAddress);
  const [subscriptions, setSubscriptions] = useState();

  const getSubscriptions = async (ids) => {
    try {
      const subs = ids.map((id) => {
        return api.get(`/api/channels/${id}`);
      });
      Promise.all(subs).then((result) => setSubscriptions([...result]));
    } catch (err) {
      console.warn(err);
    }
  };

  const getSubscriptionIDs = async () => {
    try {
      const subscriptionList = await api.get(`/api/subscriptions/list`, userId);
      const ids = subscriptionList.data.subscriptions;
      await getSubscriptions(ids);
    } catch (err) {
      console.warn(err);
    }
  };

  useEffect(() => {
    getSubscriptionIDs();
  }, []);

  return (
    <List>
      <ListItem>
        <ListItemText primary="SUBSCRIPTIONS" className={classes.title} />
      </ListItem>
      {subscriptions &&
        subscriptions.map((item, index) => {
          return (
            <NavItem
              key={index}
              to={`/channel/${item.data.id}`}
              title={item.data.name}
              icon={() => (
                <img width="30" height="30" src={item.data.imageLink} />
              )}
            />
          );
        })}
    </List>
  );
};

export default SubscriptionsList;
