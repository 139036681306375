import React, { useEffect, useState } from "react";
import { grey } from "@material-ui/core/colors";
import { Avatar, makeStyles, Box, Typography, Tab, Tabs } from "@material-ui/core";
import Chip from '@mui/material/Chip';
import IOSSwitch from '../../Inputs/IOSSwitch';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    container: {
        width: '100%',
        maxWidth: '800px',
        padding: 20,
        backgroundColor: 'black',
        border: '1px solid #262626',
    },
    subContainer: {
        width: '100%',
        padding: '10px 0px 10px 0px',
    },
    subHeading: {
        fontSize: "14px",
        textTransform: "uppercase",
        opacity: 0.7,
        padding: "8px 20px 10px 20px",
    },
    notificationOuter: {
        width: '100%',
        height: '50px',
        display: 'flex',
        padding: '20px',
    },
    notificationLabel: {
        fontSize: '1.0rem',
        fontWeight: 600,
    },
    notificationInput: {
        marginLeft: 'auto',
    },
    signInImg: {
        width: '100%',
        height: '100%',
        maxWidth: '3rem',
        maxHeight: '3rem',
        borderRadius: '50%',
        objectFit: 'cover',
        minWidth: '2rem',
        minHeight: '2rem',
    },
}));

const Notifications = () => {
    const classes = useStyles();

    useEffect(() => {

    });

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <Typography variant="h5" style={{ fontWeight: '600', paddingLeft: '20px', paddingRight: '20px' }}>
                    Notifications
                </Typography>

                <div className={classes.subHeading}>
                    PUSH NOTIFICATIONS
                </div>
                <hr style={{ backgroundColor: 'white' }} />

                <div className={classes.subContainer}>
                    <div className={classes.notificationOuter}>
                        <div className={classes.notificationLabel}>Likes</div>
                        <div className={classes.notificationInput}><IOSSwitch /></div>
                    </div>
                </div>
                <div className={classes.subContainer}>
                    <div className={classes.notificationOuter}>
                        <div className={classes.notificationLabel}>Comments</div>
                        <div className={classes.notificationInput}><IOSSwitch /></div>
                    </div>
                </div>
                <div className={classes.subContainer}>
                    <div className={classes.notificationOuter}>
                        <div className={classes.notificationLabel}>New Followers</div>
                        <div className={classes.notificationInput}><IOSSwitch /></div>
                    </div>
                </div>
                <div className={classes.subContainer}>
                    <div className={classes.notificationOuter}>
                        <div className={classes.notificationLabel}>Exclusive Releases</div>
                        <div className={classes.notificationInput}><IOSSwitch /></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Notifications;
