import React, { useEffect, useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";

import phantomImg from "../../../assets/image/phantom.png";
import coreumImg from "../../../assets/image/Coreum.png";
import metamaskImg from "../../../assets/image/metamask.png";
import xummImg from "../../../assets/image/xumm.jpeg";
import { API } from "../../../config/config";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  container: {
    width: "100%",
    maxWidth: "800px",
    padding: 20,
    backgroundColor: "black",
    border: "1px solid #262626",
  },
  subContainer: {
    width: "100%",
    padding: "10px 0px 10px 0px",
  },
  subHeading: {
    fontSize: "14px",
    textTransform: "uppercase",
    opacity: 0.7,
    paddingTop: "20px",
    paddingBottom: "10px",
  },
  accountOuter: {
    width: "100%",
    height: "90px",
    display: "flex",
    padding: "20px",
  },
  accountLabel: {
    paddingLeft: "20px",
    paddingRight: "20px",
    fontSize: "1.3rem",
    fontWeight: 600,
  },
  chipLabel: {
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  signInImg: {
    width: "100%",
    height: "100%",
    maxWidth: "3rem",
    maxHeight: "3rem",
    borderRadius: "50%",
    objectFit: "cover",
    minWidth: "2rem",
    minHeight: "2rem",
  },
  accountDetails: {
    opacity: 0.8,
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  accountConnectionOuter: {
    marginLeft: "auto",
    marginTop: "auto",
    marginBottom: "auto",
  },
  publishBtn: {
    border: "0px solid",
    backgroundColor: "rgb(0,128,0, 0.3)",
    color: "lightgreen",
    padding: "10px",
    borderRadius: "10px",
    marginRight: "10px",
    fontWeight: "600",
  },
  editBtn: {
    border: "0px solid",
    backgroundColor: "rgb(251,185,0, 0.2)",
    color: "#FBB900",
    padding: "10px",
    borderRadius: "10px",
    marginRight: "10px",
    fontWeight: "600",
  },
  deleteBtn: {
    border: "0px solid",
    backgroundColor: "rgb(194,5,6, 0.3)",
    color: "red",
    padding: "10px",
    borderRadius: "10px",
    marginRight: "10px",
    fontWeight: "600",
  },
}));

const LinkedAccounts = () => {
  const classes = useStyles();
  const [linkedAccounts, setLinkedAccounts] = useState([]);

  const handleLeapSignIn = async () => {
    if (window.leap) {
      var chain = "Coreum";
      var chainId = "coreum-mainnet-1";
      const key = await window.leap.getKey(chainId);

      if (key != undefined && key.bech32Address != undefined) {
        var publicKey = key.bech32Address;
        console.log("Coreum wallet found");
        const nonce = await fetch(
          `${API}/auth/createLoginNonce/${chain}/${publicKey}`
        )
          .then((res) => res.json())
          .then((data) => data.nonce.nonce);

        try {
          let signResult = await window.leap.signArbitrary(
            chainId,
            key.bech32Address,
            `Welcome to videomaster. By signing this transaction you will be accepting our terms and conditions and logging in. Nonce: ${nonce}`
          );
          const signature = signResult.signature.replace(/\//g, "**");

          const safeSignResultPubKeyValue = signResult.pub_key.value.replace(
            /\//g,
            "**"
          );
          const response = await fetch(
            `${API}/auth/link-wallet/${chain}/${publicKey}_${safeSignResultPubKeyValue}/${nonce}/${signature}`,
            {
              method: "GET",
              credentials: "include",
            }
          );

          if (response.ok) {
            // Show a success toast message
            toast.success("Wallet linked successfully!", {
              position: toast.POSITION.TOP_RIGHT,
            });
            getLinkedAccounts();
          } else {
            const errorData = await response.json();
          }
        } catch (error) {
          // Show an error toast message
          toast.error(`Error: ${error.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

        //Post signature
      } else {
        console.log("No coreum wallet found");
      }
    } else {
      console.log("Leap Motion SDK not found");
    }
  };

  const getLinkedAccounts = async () => {
    try {
      let response = await fetch(`${API}/channels/linked/wallets`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        let accounts = await response.json();
        setLinkedAccounts(accounts);
      } else {
        console.error("Failed to fetch linked accounts");
      }
    } catch (error) {
      console.error("Error fetching linked accounts:", error);
    }
  };

  useEffect(() => {
    getLinkedAccounts();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <ToastContainer autoClose={3000} />

        <Typography variant="h5" style={{ fontWeight: "600" }}>
          Linked Accounts
        </Typography>

        <div className={classes.subHeading}>Connected</div>

        <div className={classes.subContainer}>
          {linkedAccounts.ethAddress !== undefined &&
            linkedAccounts.ethAddress !== "" && (
              <div className={classes.accountOuter}>
                <img className={classes.signInImg} src={metamaskImg} />
                <div>
                  <div className={classes.accountLabel}>Ethereum</div>
                  <div className={classes.accountDetails}>
                    {linkedAccounts.ethAddress}
                  </div>
                </div>
                {/* <div className={classes.accountConnectionOuter}>
                <button className={classes.deleteBtn}>Disconnect</button>
              </div> */}
              </div>
            )}
          {linkedAccounts.xrpAddress !== undefined &&
            linkedAccounts.xrpAddress !== "" && (
              <div className={classes.accountOuter}>
                <img className={classes.signInImg} src={xummImg} />
                <div>
                  <div className={classes.accountLabel}>XRP</div>
                  <div className={classes.accountDetails}>
                    {linkedAccounts.xrpAddress}
                  </div>
                </div>
              </div>
            )}
          {linkedAccounts.solanaAddress !== undefined &&
            linkedAccounts.solanaAddress !== "" && (
              <div className={classes.accountOuter}>
                <img className={classes.signInImg} src={phantomImg} />
                <div>
                  <div className={classes.accountLabel}>Solana</div>
                  <div className={classes.accountDetails}>
                    {linkedAccounts.solanaAddress}
                  </div>
                </div>
              </div>
            )}
          {linkedAccounts.coreumAddress !== undefined &&
            linkedAccounts.coreumAddress !== "" && (
              <div className={classes.accountOuter}>
                <img className={classes.signInImg} src={coreumImg} />
                <div>
                  <div className={classes.accountLabel}>Coreum</div>
                  <div className={classes.accountDetails}>
                    {linkedAccounts.coreumAddress}
                  </div>
                </div>
              </div>
            )}
        </div>
        <hr style={{ backgroundColor: "white" }} />
        <div className={classes.subContainer}>
          {linkedAccounts.coreumAddress === undefined && (
            <div className={classes.accountOuter}>
              <img className={classes.signInImg} src={coreumImg} />
              <div>
                <div className={classes.accountLabel}>Coreum</div>
                <div className={classes.accountDetails}>
                  Link your coreum wallet using Leap!
                </div>
              </div>
              <div className={classes.accountConnectionOuter}>
                <button
                  className={classes.publishBtn}
                  onClick={handleLeapSignIn}
                >
                  Connect
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LinkedAccounts;
