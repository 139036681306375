import React, { useEffect, useState } from "react";
import { grey } from "@material-ui/core/colors";
import {
  Avatar,
  makeStyles,
  Box,
  Typography,
  Tab,
  Tabs,
  Container,
  Button,
} from "@material-ui/core";
import UploadModal from "../../Upload/UploadModal";
import {
  checkCreatorUploadPermission,
  requestCreatorUploadPermission,
} from "../../../action/channelAction";
import {
  faVideo,
  faArrowRightToArc,
  faClock,
} from "@fortawesome/pro-regular-svg-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  container: {
    width: "100%",
    maxWidth: "800px",
    padding: 20,
    backgroundColor: "black",
    border: "1px solid #262626",
  },
  requestButton: {
    margin: "0",
    //breakpoint at 900px
    [theme.breakpoints.up("md")]: {
      margin: "0 0 0 20px",
    },
    padding: "7px 20px",
    fontSize: "14px",
    fontWeight: 600,
    color: theme.palette.button.alternate.text,
    backgroundColor: theme.palette.button.alternate.background,
    borderRadius: "25px",
    textTransform: "capitalize",
    border: "none",
    boxShadow: "0px",
    "&:hover": {
      boxShadow: "none",
      border: "none",
      backgroundColor: theme.palette.button.alternate.backgroundHover,
    },
    "&:active": {
      border: "none",
    },
  },
  text: {
    paddingBottom: theme.spacing(2),
  },
  subContainer: {
    width: "100%",
    padding: "10px 0px 10px 0px",
  },
  subHeading: {
    fontSize: "14px",
    textTransform: "uppercase",
    opacity: 0.7,
    padding: "8px 20px 10px 20px",
  },
  viewTransaction: {
    border: "0px solid",
    backgroundColor: "rgb(194,5,6, 0.3)",
    color: "red",
    padding: "10px",
    borderRadius: "10px",
    marginRight: "10px",
    fontWeight: "600",
  },
  button: {
    alignSelf: "center",
    fontSize: "12px",
    marginLeft: "5px",
    marginRight: "5px",
    backgroundColor: "rgb(0, 119, 247, 0.2)",
    color: "rgb(0, 119, 247)",
    "&:hover": {
      color: "white",
      backgroundColor: "rgb(0, 119, 247) !important",
    },
  },
  root_1: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  root_2: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
}));

const CreateVideo = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [permissionStatus, setPermissionStatus] = useState("");

  const handleRequestPermission = async () => {
    try {
      let response = await requestCreatorUploadPermission();

      toast.success("Permission request submitted successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      // Optionally, you can refetch the permission status to update the UI
      const status = await checkCreatorUploadPermission();
      setPermissionStatus(status);
    } catch (error) {
      console.error("Failed to request creator payout permission:", error);
      toast.error("Failed to request permission");
    }
  };

  useEffect(() => {
    const fetchPermissionStatus = async () => {
      setIsLoading(true);
      try {
        const status = await checkCreatorUploadPermission();
        setPermissionStatus(status);
      } catch (error) {
        console.error(
          "Failed to fetch creator payout permission status:",
          error
        );
        // Optionally handle the error, e.g., by setting an error state
      } finally {
        setIsLoading(false);
      }
    };
    fetchPermissionStatus();
  }, []);

  useEffect(() => {});

  if (isLoading) {
    return <div>Loading...</div>;
  }

  let content;
  switch (permissionStatus) {
    case "Approved":
      content = (
        <div className={classes.root}>
          <UploadModal isOpen={true} />
        </div>
      );
      break;
    case "Not approved":
      content = (
        <Container className={classes.root_1}>
          <FontAwesomeIcon
            icon={faVideo}
            size="4x"
            className={classes.SignInIcon}
          />
          <Typography variant="h4" align="center" className={classes.text}>
            {"Apply for creator permission"}
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            className={classes.text}
          >
            {
              "Once approved you will be able to upload your content to Videomaster."
            }
          </Typography>
          <Button
            disableElevation
            disableFocusRipple
            disableRipple
            disableTouchRipple
            className={classes.requestButton}
            endIcon={<FontAwesomeIcon icon={faArrowRightToArc} size="xl" />}
            onClick={handleRequestPermission} // Add this line
          >
            Request Payout Permission
          </Button>
        </Container>
      );
      break;
    case "Requested":
    default:
      content = (
        <Container className={classes.root_1}>
          <FontAwesomeIcon
            icon={faClock}
            size="4x"
            className={classes.SignInIcon}
          />
          <Typography variant="h4" align="center" className={classes.text}>
            {"Creator permission pending"}
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            className={classes.text}
          >
            {
              "You have already applied for creator permission please wait until approved. Once you have applied for creator permission and have been approved you will be able to upload your content to Videomaster."
            }
          </Typography>
        </Container>
      );
      break;
  }

  return content;

  // return (
  //   <div className={classes.root}>
  //     <UploadModal isOpen={true} />
  //   </div>
  // );
};

export default CreateVideo;
