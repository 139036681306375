import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import Tooltip from "@mui/material/Tooltip";
import { faArrowLeftLongToLine } from "@fortawesome/pro-duotone-svg-icons";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  optionsSpan: {
    color: "#fff !important",
    background: "#404040",
    borderRadius: "100%",
    width: "30px",
    height: "30px",
    display: "flex",
    cursor: "pointer",
    float: "right",
    transition: "all 0.2s ease-in-out 0s",
    position: "absolute",
    right: "10px",

    "&:hover": {
      width: "60px",
      paddingLeft: "30px",
      borderRadius: "30px",
    },
  },
  closeBtn: {
    color: "#fff !important",
    background: "#404040",
    borderRadius: "100%",
    padding: "8px 10px 4px 11px",
    minWidth: "30px",
    height: "30px",
    display: "flex",
    cursor: "pointer",
    margin: "0px",
    "&:hover": {
      backgroundColor: "#303030 !important",
    },
  },
  option1: {
    display: "flex",
    opacity: 0,
    marginLeft: "-30px",

    "&.show": {
      transition: "all 0.4s ease-in-out 0s",
      opacity: 1,
    },
  },
}));
export default function CloseAndOptionsBtn({ onOption1 = () => {}, onClose }) {
  //OPTION 1: BACK
  //OPTION 2: CLOSE
  const [optionsSpanHover, setOptionsSpanHover] = useState(false);

  const classes = useStyles();
  return (
    <div
      className={classes.optionsSpan}
      onMouseEnter={() => setOptionsSpanHover(true)}
      onMouseLeave={() => setOptionsSpanHover(false)}
    >
      <Tooltip
        className={`${classes.option1} ${optionsSpanHover ? "show" : ""}`}
        title="back"
        placement="top"
      >
        <a className={classes.closeBtn} onClick={onOption1}>
          <FontAwesomeIcon icon={faArrowLeftLongToLine} size="sm" />
        </a>
      </Tooltip>
      <Tooltip title="close" placement="top">
        <a className={classes.closeBtn} onClick={onClose}>
          <FontAwesomeIcon icon={faXmark} size="sm" />
        </a>
      </Tooltip>
    </div>
  );
}
