import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, ListItemText } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPodcast,
  faMicrophoneStand,
} from "@fortawesome/pro-duotone-svg-icons";

import NavItem from "./NavItem";
import categoryIcons from "../categoryIcons";

const useStyles = makeStyles((theme) => ({
  title: {
    padding: theme.spacing(0, 1),
    fontSize: "1em",
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  bestOfIcon: {
    width: "24px",
    height: "24px",
    borderRadius: "50%",
  },
  root_h: {
    display: "flex",
    overflowX: "auto",
    justifyContent: "center",
  },
  spacing_h: { margin: "1em" },
  img_h: {
    width: "100%",
    height: "100%",
    borderRadius: "100%",
  },
}));

const SideCategoryMenu = () => {
  const classes = useStyles();

  return (
    <List style={{ padding: "8px" }}>
      <ListItem>
        <ListItemText primary="EXPLORE" classes={{ primary: classes.title }} />
      </ListItem>
      {categoryIcons.map((item, index) => {
        return (
          <NavItem
            key={index}
            to={`/trending?category=${item.title}`}
            title={item.title}
            icon={item.icon}
            activeIcon={item.icon}
          />
        );
      })}
    </List>
  );
};

const HorizontalCategoryMenu = () => {
  const classes = useStyles();
  return (
    <div className={classes.root_h}>
      {categoryIcons.map((item, index) => {
        return (
          <div className={classes.spacing_h}>
            <NavItem
              key={index}
              to={`/trending?category=${index}`}
              title={item.title}
              type="secondary"
              icon={item.icon}
            />
          </div>
        );
      })}
    </div>
  );
};

export { SideCategoryMenu, HorizontalCategoryMenu };
