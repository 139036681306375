import React, { useEffect, useState } from "react";
import { Avatar, makeStyles, Typography, Box, Button } from "@material-ui/core";
import TextField from "../../../components/Inputs/TextField";
import ImagePicker from "../../ImagePicker";
import {
  getProfileImage,
  updateChannelUsername,
  updateChannelName,
} from "../../../action/channelAction";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { api } from "../../../utils/api";
import { BACKEND_URL } from "../../../config/config";
import { useDispatch } from "react-redux";
import { reloadAccountInfo } from "../../../redux/actions/channel";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  container: {
    width: "100%",
    maxWidth: "800px",
    padding: 20,
    backgroundColor: "black",
    border: "1px solid #262626",
  },
  subHeading: {
    fontSize: "14px",
    textTransform: "uppercase",
    opacity: 0.7,
    padding: "8px 20px 10px 20px",
  },
  settingsAvatar: {
    height: "120px",
    width: "120px",
    margin: "40px",
  },
  settingSecondSection: {
    margin: "40px",
  },
  uploadBtn: {
    border: "0px solid",
    backgroundColor: "rgb(0,128,0, 0.3)",
    color: "lightgreen",
    padding: "10px",
    borderRadius: "10px",
    marginRight: "10px",
    fontWeight: "600",
  },
  description: {
    opacity: 0.8,
    paddingTop: "10px",
  },
  saveSection: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "20px",
  },
  inputLabel: {
    padding: "5px",
  },
  textField: {
    flexGrow: 1,
  },
}));

const AccountSettings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    username: "",
    name: "",
  });

  const [profileImage, setProfileImage] = useState("./images/userDefault.png");

  const fetchProfileImage = async () => {
    try {
      const response = await getProfileImage();
      setProfileImage(response.profileImage);
    } catch (err) {
      console.log(err);
    }
  };

  const updateUsername = async (e) => {
    e.preventDefault();
    try {
      const response = await updateChannelUsername(formData.username);
      toast.success("Username updated successfully!");
      const { data } = await api.get(`${BACKEND_URL}/api/channels/owner`);
      await dispatch(reloadAccountInfo());
      console.log("response.username: ", response);
    } catch (err) {
      toast.error("Failed to update username.");
      console.log(err);
    }
  };

  const updateName = async (e) => {
    e.preventDefault();
    try {
      const response = await updateChannelName(formData.name);
      toast.success("Name updated successfully!");
      await dispatch(reloadAccountInfo());

      console.log("response.name: ", response);
    } catch (err) {
      toast.error("Failed to update name.");
      console.log(err);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    //console.log(`Changing ${name} to ${value}`);
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    fetchProfileImage();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography
          variant="h5"
          style={{
            fontWeight: "600",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          Account
        </Typography>
        <div className={classes.subHeading}>Customise your profile</div>
        <hr style={{ backgroundColor: "white" }} />
        <div style={{ display: "flex" }}>
          <Avatar className={classes.settingsAvatar} src={profileImage} />
          <div className={classes.settingSecondSection}>
            <Typography variant="h6">Profile image</Typography>
            <div className={classes.description}>
              min: 200px, 200px | recommended: 500px, 500px
            </div>
            <br />
            <ImagePicker
              className={classes.uploadBtn}
              type="Profile Image"
              onSuccess={fetchProfileImage}
            />
          </div>
        </div>

        <form onSubmit={updateUsername}>
          <Typography className={classes.inputLabel} variant="h6">
            Username
          </Typography>
          <div style={{ display: "flex" }}>
            <TextField
              required
              id="username"
              name="username"
              variant="outlined"
              onChange={handleChange}
              value={formData.username}
              className={classes.textField}
            />
            <div style={{ paddingLeft: "20px" }}>
              <Button type="submit" className={classes.uploadBtn}>
                Save
              </Button>
            </div>
          </div>
          <br />
        </form>

        <form onSubmit={updateName}>
          <Typography className={classes.inputLabel} variant="h6">
            Name
          </Typography>
          <div style={{ display: "flex" }}>
            <TextField
              required
              id="name"
              name="name"
              variant="outlined"
              onChange={handleChange}
              value={formData.name}
              className={classes.textField}
            />
            <div style={{ paddingLeft: "20px" }}>
              <Button type="submit" className={classes.uploadBtn}>
                Save
              </Button>
            </div>
          </div>
          <br />
        </form>
      </div>
    </div>
  );
};

export default AccountSettings;
