import React, { useState, useEffect, useRef, Fragment } from "react";
import { useSelector } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import VidDropzone from "./VidDropzone";
import { io } from "socket.io-client";

import TextField from "@atlaskit/textfield"; // Assuming default export is used
import { TextFieldColors } from "@atlaskit/textfield";
import Form, { Field, FormFooter, HelperMessage } from "@atlaskit/form";
import Button from "@atlaskit/button/standard-button";
import { WEBSOCKET_URL, UPLOAD_SOCKET_URL, API } from "../../config/config";

import UploadStepper from "./UploadStepper";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: "black",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: "40px",
    backgroundColor: "#1d1d1d",
  },
}))(MuiDialogContent);
export default function CustomizedDialogs({ isOpen, handleClose }) {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [socketId, setSocketId] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);

  const fetchSocketId = async () => {
    try {
      const response = await fetch(`${API}/videos/uploadStatus/socketId`);
      if (response.ok) {
        const { socketid } = await response.json();
        setSocketId(socketid);
        console.log(socketid);
        const socket = io(UPLOAD_SOCKET_URL, {
          path: "/videoUploadStatus",
        });
        //const socket = io(WEBSOCKET_URL, { path: "/videoUploadStatus" });

        socket.on("connect", () => {
          socket.emit("registerSocketId", socketid.toString()); // Send socketId as a message string
        });

        socket.on("uploadProgress", (data) => {
          console.log("uploadProgress", data);
          setUploadProgress(data.percentage);
        });
      } else {
        console.error("Failed to fetch socket ID");
      }
    } catch (err) {
      console.error("Error fetching socket ID:", err);
    }
  };

  const handleVideoSelect = (videoFile) => {
    setSelectedVideo(videoFile);
  };

  useEffect(() => {
    fetchSocketId();
  }, []);

  return (
    <div>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Upload video
      </DialogTitle>

      <DialogContent dividers>
        {selectedVideo ? (
          <UploadStepper
            selectedVideo={selectedVideo}
            setSelectedVideo={setSelectedVideo}
            uploadProgress={uploadProgress}
          />
        ) : (
          <VidDropzone onVideoSelect={handleVideoSelect} socketid={socketId} />
        )}
      </DialogContent>
    </div>
  );
}
